import styled from "styled-components";


const CloseButton = styled.div`
    position: absolute;
    right: 21px;
    top: 19px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    
    &:before, &:after {
        position: absolute;
        content: ' ';
        height: 17px;
        right: 4px;
        top: -2px;
        width: 2px;
        background-color: #677b8b;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

export default CloseButton;
