import styled, { css } from 'styled-components';

const ButtonStyled = styled.button`
  ${(p) => css`
    width: 120px;
    height: 40px;
    border: 0px solid white;
    border-radius: 26px;
    font-family: NunitoSans;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    ${p.theme === 'white'
      ? css`
          background-color: white;
          color: #283890;
        `
      : css`
          box-shadow: 0 3px 10px 0 rgba(40, 56, 144, 0.21);
          background-color: #283890;
          &:not(:disabled):hover {
            background-color: #2e40a0;
          }

          &:active {
            background-color: #344ab3;
            outline: 0;
          }

          &:focus {
            outline: 0;
          }

          &:disabled {
            background: rgba(40, 40, 58, 0.5);
            box-shadow: none;
            cursor: default;
          }
        `}
  `}
`;

export default ButtonStyled;
