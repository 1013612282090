import React from 'react';
import styled from 'styled-components';

import BaseModal from "../../../components/BaseModal";
import Input from "../../../components/Input";
import PasswordInput from "../../../components/PasswordInput";
import Button from "../../../components/Buttons/Button";
import doFetch from "../../../api/doFetch";


const ModalHeaderWrapper = styled.div`
    display: flex;
    color: #283890;
`;

const SubmitAreaStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 32px;
`;

const EditManagerModal = ({ isOpen, toggleHandler, user, mutateUser }) => {
    const handleSubmitUser = (event) => {
        event.preventDefault();
        const { username, email, password } = event.target.elements;
        const data = {
            displayName: username.value,
            email: email.value,
        }

        if (password.value !== '') {
            data.password = password.value;
        }

        doFetch(`/users/${user.uuid}`, { method: 'PUT', body: JSON.stringify(data) })
            .then((user) => {
                mutateUser(user, false);
                toggleHandler();
            }).catch(error => alert(error));
    }

    return (
        <BaseModal
            isOpen={isOpen}
            toggleHandler={toggleHandler}
            header={<ModalHeaderWrapper>EDIT MANAGER INFORMATION</ModalHeaderWrapper>}
        >
            <form onSubmit={handleSubmitUser}>
                <Input label={'Username'} name={'username'} defaultValue={user?.displayName} />
                <Input label={'Email'} type="email" name={'email'} defaultValue={user?.email}/>
                <PasswordInput label={'Password'} />
                <SubmitAreaStyled>
                    <Button type="submit">Update</Button>
                </SubmitAreaStyled>
            </form>
        </BaseModal>
    );
}

export default EditManagerModal;
