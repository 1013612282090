import { Subject } from 'rxjs';

export const ConditionFilterData = {
  items: [
    { id: 1, name: 'All' },
    { id: 2, name: 'New' },
    { id: 3, name: 'Used' }
  ]
};

export const CountSetData = [
  { name: 'No photos', values: { from: 0, to: 0 } },
  { name: '1 to 5', values: { from: 1, to: 5 } },
  { name: '5 to 10', values: { from: 5, to: 10 } },
  { name: '10 to 15', values: { from: 10, to: 15 } },
  { name: '15 to 20', values: { from: 15, to: 20 } },
  { name: '20 to 25', values: { from: 20, to: 25 } },
  { name: '25 to 30', values: { from: 25, to: 30 } },

  { name: '< 3', values: { to: 3 } },
  { name: '< 5', values: { to: 5 } },
  { name: '< 8', values: { to: 8 } },
  { name: '< 10', values: { to: 10 } },
  { name: '< 12', values: { to: 12 } },
  { name: '< 15', values: { to: 15 } },
  { name: '< 20', values: { to: 20 } },

  { name: '1+', values: { from: 1 } },
  { name: '3+', values: { from: 3 } },
  { name: '5+', values: { from: 5 } },
  { name: '8+', values: { from: 8 } },
  { name: '10+', values: { from: 10 } },
  { name: '15+', values: { from: 15 } },
  { name: 'Any photos', values: {} }
];

const subject = new Subject();

const initialState = {
  makes: [],
  models: [],
  photoCount: CountSetData[20],
  years: { from: null, to: null },
  condition: ConditionFilterData.items[0]
};

let state = initialState;

const filterStore = {
  init: () => {
    state = { ...state };
    subject.next(state);
  },
  subscribe: (setState) => subject.subscribe(setState),
  sendMessage: (data) => {
    state = {
      ...state,
      ...data
    };
    subject.next(state);
  },
  resetFilters: () => {
    subject.next(initialState);
  },
  initialState
};

export default filterStore;
