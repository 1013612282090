import React, { useEffect, useState, useMemo } from 'react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css';
import styled from 'styled-components';

import ContentBoardStyled from '../../../../components/ContentBoardStyled';
import StyledBoardHeader from '../../../../components/StyledBoardHeader';
import ActiveBoardHeader from '../../../../components/ActiveBoardHeader';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import CloseButton from '../../../../components/Buttons/CloseButton';
import Scrollable from '../../../../components/Scrollable';
import { formattedDateTime } from '../../../../utils/date';
import { checkedWhiteIcon, vehicleNoPhoto } from '../../../../img';
import MoveModal from '../../../Photos/components/MoveModal';

const PhotosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
`;

const FinishButton = styled(CloseButton)`
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  margin-right: 20px;
`;

const SelectIcon = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 31px;
  top: 5px;
  left: 5px;
  background: #a6abae;
  cursor: default;
  opacity: 0;
  border: solid 1px transparent;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: no-repeat center/55% url(${checkedWhiteIcon});
  }

  ${(props) =>
    props.selected &&
    `
    background: #283890 !important;
    border: solid 1px #283890 !important;

    &::after {
      opacity: 1 !important;;
    }
  `}
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 128px;
  height: 120px;
  margin: 8px;

  &:hover ${SelectIcon} {
    opacity: 1;
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: default;

    ${SelectIcon} {
      display: none;
    }
  `}

  ${(props) =>
    props.deleteMode &&
    `
    cursor: default;

    &:hover ${SelectIcon} { 
      background: #a6abae;

      &::after {
        opacity: 1;
      }
    }

    ${SelectIcon} {
      opacity: 1;
      background: transparent;
      border: solid 1px #a6abae;

      &::after {
        opacity: 0;
      }

      &:hover {
        background: #94989a;
        border: solid 1px #94989a;
      }
    }
  `}
`;

const BoxImage = styled.img`
  display: block;
  width: 128px;
  height: 96px;
  min-height: 96px;
  object-fit: contain;
  cursor: move;
  cursor: grab;
  background-color: rgba(216, 216, 216, 0.34);
`;

const BoxImageService = styled.div`
  display: block;
  width: 128px;
  height: 96px;
  min-height: 96px;
  object-fit: contain;
  background-image: url(${vehicleNoPhoto});
`;

const BoxInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-weight: bold;
  color: #677b8b;
  width: 100%;
  margin: auto;
`;

const SortableItem = sortableElement(
  ({ data, deleteMode, selected, onSelectClick, onPhotoClick }) => (
    <BoxWrapper
      disabled={!data.editable}
      deleteMode={deleteMode}
      onClick={() =>
        deleteMode ? onSelectClick(data.item) : data.item.url ? onPhotoClick(data.index) : null
      }
    >
      {data.item.url ? (
        <SelectIcon
          selected={selected}
          onClick={(e) => {
            e.stopPropagation();
            onSelectClick(data.item);
          }}
        />
      ) : null}
      {data.item.url ? <BoxImage src={data.item.url} /> : <BoxImageService />}
      <BoxInfo>
        <span>{data.item.url ? data.index + 1 : 'Photo upload error'}</span>
        <span>{data.item.url ? formattedDateTime(data.item.uploadedOn) : null}</span>
      </BoxInfo>
    </BoxWrapper>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <PhotosWrapper>{children}</PhotosWrapper>;
});

const VehiclePhotos = ({
  items,
  vehicle,
  editable,
  onSortEnd,
  openPhotoUploadModal,
  handleDeletePhotos
}) => {
  const [moveModalOpened, setMoveModalOpened] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [isOpenConfirm, setIsOpenConfirmModal] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryPhotoIndex, setGalleryPhotoIndex] = useState(0);

  const toggleModal = () => setIsOpenConfirmModal(!isOpenConfirm);
  const assignPhoto = () => setMoveModalOpened(true);

  const onSelectClick = (item) =>
    setSelectedPhotos(
      selectedPhotos.includes(item)
        ? selectedPhotos.filter((ph) => ph.id !== item.id)
        : selectedPhotos.concat([item])
    );

  const onPhotoClick = (photoIndex) => {
    setGalleryPhotoIndex(photoIndex);
    setIsGalleryOpen(true);
  };

  const isItemSelected = (item) => selectedPhotos.map((ph) => ph.id).includes(item.id);

  useEffect(() => {
    setDeleteMode(selectedPhotos.length > 0);
  }, [selectedPhotos]);

  useEffect(() => setSelectedPhotos([]), [items]);

  const filter = useMemo(() => {
    const filter = {};
    if (!vehicle) return filter;

    if (vehicle.color) filter.exteriorColor = vehicle.color;
    if (vehicle.interiorColorCode) filter.interiorColor = vehicle.interiorColorCode;
    if (vehicle.make) filter.makes = [vehicle.make];
    if (vehicle.model) filter.models = [vehicle.model];
    if (vehicle.modelNumber) filter.modelNumber = vehicle.modelNumber;
    if (vehicle.msrp) filter.msrp = vehicle.msrp;
    if (vehicle.year) filter.year = vehicle.year;

    return filter;
  }, [vehicle]);

  return (
    <ContentBoardStyled>
      <StyledBoardHeader
        {...{
          ...(editable
            ? { button: { name: 'Add photo', handler: openPhotoUploadModal } }
            : { rightText: 'You can edit photos on SpinCar App' })
        }}
      >
        VEHICLE PHOTOS
      </StyledBoardHeader>
      {deleteMode && (
        <ActiveBoardHeader
          buttons={[
            { name: 'Assign photo', handler: assignPhoto },
            { name: 'Delete photos', handler: toggleModal }
          ]}
        >
          <FinishButton onClick={() => setSelectedPhotos([])} />
          <span>{selectedPhotos.length} Photo selected</span>
        </ActiveBoardHeader>
      )}
      <Scrollable>
        <SortableContainer distance={1} onSortEnd={onSortEnd} axis='xy' lockToContainerEdges={true}>
          {items.map((item, index) => (
            <SortableItem
              index={index}
              key={(item.id, index)}
              data={{ item, index, editable }}
              onSelectClick={onSelectClick}
              onPhotoClick={onPhotoClick}
              disabled={!editable || deleteMode}
              deleteMode={deleteMode}
              selected={isItemSelected(item)}
              pressDelay={200}
            />
          ))}
        </SortableContainer>
      </Scrollable>
      <ReactBnbGallery
        opacity={0.8}
        show={isGalleryOpen}
        showThumbnails={false}
        photos={items.map((item) => item?.url || '')}
        activePhotoIndex={galleryPhotoIndex}
        onClose={() => setIsGalleryOpen(false)}
      />
      <ConfirmationModal
        title={'DELETE PHOTOS'}
        text={'Please confirm if you want permanently delete these photos.'}
        isOpen={isOpenConfirm}
        toggleHandler={toggleModal}
        confirmAction={() => handleDeletePhotos(selectedPhotos)}
      />
      {Boolean(moveModalOpened) && (
        <MoveModal
          isOpen={Boolean(moveModalOpened)}
          images={selectedPhotos.map((p) => p.id)}
          filter={filter}
          toggleHandler={() => setMoveModalOpened(!moveModalOpened)}
          ignore={[vehicle?.vin || '']}
        />
      )}
    </ContentBoardStyled>
  );
};

export default VehiclePhotos;
