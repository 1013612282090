import React, { useMemo } from 'react';
import { ChipItem, ChipsWrapper, CloseButton } from '../../../components/Inputs/styles';

export default function ({ filter, patchFilter, ...props }) {
  const chips = useMemo(() => {
    const chips = [];

    if (filter.vin) return [{ value: filter.vin, type: 'vin', canDelete: true }];

    const filterTypes = Object.keys(filter);
    for (const type of filterTypes) {
      if (Array.isArray(filter[type])) {
        if (filter[type].length)
          chips.push(...filter[type].map((value) => ({ value, type, canDelete: true })));
      } else {
        if (typeof filter[type] === 'object') {
          if (filter[type]?.name)
            if (filter[type].name !== 'Any')
              chips.push({ value: filter[type].name, type, canDelete: true });
        } else if (filter[type]) {
          chips.push({ value: filter[type], type, canDelete: true });
        }
      }
    }

    if (!chips.length) return [{ value: 'All' }];

    return chips;
  }, [filter]);

  const onDelete = ({ type, value }) => {
    if (!type || !value) return null;
    if (Array.isArray(filter[type])) {
      patchFilter({ [type]: filter[type].filter((c) => c !== value) });
    } else if (typeof filter[type] === 'object') {
      patchFilter({ [type]: { name: 'Any', value: {} } });
    } else {
      patchFilter({ [type]: '' });
    }
  };

  return (
    <ChipsWrapper {...props}>
      {chips.map((data, index) => (
        <ChipItem
          key={index}
          label={data.value}
          onDelete={Boolean(patchFilter) && data.canDelete ? () => onDelete(data) : undefined}
          deleteIcon={<CloseButton />}
        />
      ))}
    </ChipsWrapper>
  );
}
