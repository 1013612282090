import useSWR from 'swr';
import app from '../base';

const useNotes = () => {
  const { data, error, mutate } = useSWR([`/reports/notes`, undefined, app.auth().currentUser.uid]);

  return {
    notes: data,
    isLoadingUsers: !error && !data,
    isError: error,
    mutateNotes: mutate
  };
};

export default useNotes;
