import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useParams, useRouteMatch } from 'react-router-dom';

import { useInfo } from '../../context';
import { ControlBarContext } from '../ControlBarContext';
import DealerReportsList from './components/DealerReportsList';
import useDealer from '../../api/useDealer';
import { EmailsStyled, NoEmailStyled } from '../../components/EmailStyled';
import MediumButtonStyled from '../../components/Buttons/MediumButtonStyled';
import EditEmailsModal from './components/EditEmailsModal';
import DealerPriceBoard from './components/DealerPriceBoard';
import DealerInfoBoard from './components/DealerInfoBoard';
import DealerDetailsBoard from './components/DealerDetailsBoard';
import ItemLinkStyled from '../../components/ItemLinkStyled';

const DealerReportWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const HRVertical = styled.div`
  height: 32px;
  width: 1px;
  margin: 0 3px 0 16px !important;
  background: rgba(39, 55, 144, 0.1);
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 482px;
  margin: 25px 25px 0 0;
`;

const ControlBarEmailBlockStyled = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 13px;
  }
`;

const DealerReport = ({ getBreadCrumbs }) => {
  const { setBreadCrumbs, setRightSide, setRightSideComponent } = useContext(ControlBarContext);
  const { hmsId } = useParams();
  const { dealer, mutateDealer } = useDealer(hmsId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dealerUpdateInfo = useInfo();
  const upgrade = useRef(false);

  const match = useRouteMatch();

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [setIsModalOpen, isModalOpen]
  );

  useEffect(() => {
    if (dealerUpdateInfo) {
      if ((dealerUpdateInfo?.hmsIds || []).includes(String(hmsId))) {
        upgrade.current = true;
      } else if (upgrade.current) {
        upgrade.current = false;
        mutateDealer();
      }
    }
    // eslint-disable-next-line
  }, [dealerUpdateInfo?.hmsIds]);

  useEffect(() => {
    const dealerEmails = dealer?.email?.length ? dealer?.email.split(', ') : [];

    setRightSide({
      Component: (
        <ControlBarEmailBlockStyled>
          {dealerEmails.length === 0 ? (
            <NoEmailStyled>no email address</NoEmailStyled>
          ) : (
            <EmailsStyled>{dealerEmails[0]}</EmailsStyled>
          )}
          <MediumButtonStyled css={{ padding: '8px 8px' }} onClick={toggleModal}>
            {dealerEmails.length > 1 ? `${dealerEmails.length - 1} more` : 'Edit'}
          </MediumButtonStyled>
          <HRVertical />
          <ItemLinkStyled to={`/dealers/${hmsId}/vehicles`}>
            <MediumButtonStyled css={{ padding: '8px 12px' }}>Vehicle list</MediumButtonStyled>
          </ItemLinkStyled>
        </ControlBarEmailBlockStyled>
      ),
      url: match.url
    });
  }, [setRightSideComponent, dealer, match.url, hmsId, setRightSide, toggleModal]);

  useEffect(() => {
    dealer &&
      setBreadCrumbs(
        getBreadCrumbs({
          dealerName: dealer.name,
          hmsId: dealer.hmsId,
          lastUpdateDate: dealer.lastUpdateDate
        })
      );
  }, [getBreadCrumbs, dealer, setBreadCrumbs]);

  return (
    <DealerReportWrapper>
      <EditEmailsModal
        isOpen={isModalOpen}
        dealer={dealer}
        toggleHandler={toggleModal}
        updateDealer={mutateDealer}
      />
      <DealerReportsList />
      <RightSide>
        <DealerInfoBoard dealer={dealer} />
        <DealerPriceBoard dealer={dealer} updateDealer={mutateDealer} />
        <DealerDetailsBoard dealer={dealer} updateDealer={mutateDealer} />
      </RightSide>
    </DealerReportWrapper>
  );
};

export default DealerReport;
