import { useSWRInfinite } from 'swr';
import app from '../base';

const useVehicles = (search, limit = 20) => {
  const { data, error, mutate, size, setSize } = useSWRInfinite(
    (index) => `/vehicles?limit=${limit}&page=${index + 1}&search=${search}`,
    undefined,
    app.auth().currentUser.uid
  );

  return {
    data,
    isLoading: !error && !data,
    isLoadingMore: (!error && !data) || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    isError: error,
    mutateVehicles: mutate,
    size,
    setSize
  };
};

export default useVehicles;
