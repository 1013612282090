import useSWR from 'swr';

import app from '../base';

const useReportsForUser = (uuid, conditions) => {
  const dayQuery = conditions?.reportDay ? `reportDay=${conditions.reportDay}` : '';
  const notesQuery = conditions?.notes?.length
    ? conditions?.notes.map((note) => `&notes=${note}`).join('')
    : '';

  const { data, error, mutate } = useSWR(
    uuid
      ? [
          `/reports/users/${uuid}?${dayQuery}&${notesQuery}`,
          /* options */ undefined,
          app.auth().currentUser.uid
        ]
      : null
  );

  return {
    reports: data,
    isLoadingUser: !error && !data,
    isError: error,
    mutateReport: mutate
  };
};

export default useReportsForUser;
