import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ControlBarContext } from '../ControlBarContext';
import ReportsFilter from './components/ReportsFilter';
import ReportsList from './components/ReportsList';

const ReportsWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 246px;
    width: 246px;
    margin: 25px 25px 0 0;
`;

const ReportsPage = ({ getBreadCrumbs }) => {
    const { setBreadCrumbs } = useContext(ControlBarContext);

    useEffect(() => setBreadCrumbs(getBreadCrumbs()), [setBreadCrumbs, getBreadCrumbs]);

    return (
        <ReportsWrapper>
            <ReportsList />
            <RightSide>
                <ReportsFilter />
            </RightSide>
        </ReportsWrapper>
    );
};

export default ReportsPage;
