import React, { useContext, useEffect, useState, Fragment } from 'react';

import ContentBoardStyled from '../../components/ContentBoardStyled';
import ItemLinkStyled from '../../components/ItemLinkStyled';
import NoItemsInList from '../../components/NoItemsInList';
import { ControlBarContext } from '../ControlBarContext';
import SearchHeader from '../../components/SearchHeader';
import Scrollable from '../../components/Scrollable';
import TitleLine from '../../components/TitleLine';
import VehicleListItem from './ui/VehicleListItem';
import useVehicles from '../../api/useVehicles';
import Loader from '../../components/Loader';

const VehicleListPage = ({ getBreadCrumbs }) => {
  const [searchInput, setSearchInput] = useState('');
  const { setBreadCrumbs } = useContext(ControlBarContext);
  const { data, isLoading, isLoadingMore, size, setSize } = useVehicles(searchInput);

  const shownItems =
    data && data[0]?.items ? [].concat(...data?.map((data) => data?.items?.flat()).flat()) : [];

  const inputHandler = (value) => setSearchInput(value);

  const handleLoadMore = () => {
    if (!isLoading) setSize(size + 1);
  };

  const isLabelShown = (dealership) =>
    !shownItems
      .map((i) => i.dealership)
      .filter((i) => i.hmsId === dealership.hmsId)
      .indexOf(dealership);

  useEffect(() => setBreadCrumbs(getBreadCrumbs()), [setBreadCrumbs, getBreadCrumbs]);

  return (
    <ContentBoardStyled>
      <SearchHeader
        userInput={searchInput}
        placeholder='Search by VIN, make, model, stock'
        onInputHandler={(event) => inputHandler(event.target.value)}
        onClearHandler={() => inputHandler('')}
      />
      <Scrollable isLoading={isLoading || isLoadingMore} bottomReached={handleLoadMore}>
        {!!shownItems?.length ? (
          shownItems?.map(({ vehicles, dealership }, dealershipIndex) => (
            <Fragment key={dealershipIndex}>
              <TitleLine title={dealership?.name} hidden={!isLabelShown(dealership)} />
              {vehicles &&
                vehicles.length &&
                vehicles.map((vehicle, vehicleIndex) => {
                  return (
                    <ItemLinkStyled
                      to={`/dealers/${dealership.hmsId}/vehicles/${vehicle.vin}`}
                      key={vehicleIndex}
                    >
                      <VehicleListItem data={vehicle} />
                    </ItemLinkStyled>
                  );
                })}
            </Fragment>
          ))
        ) : (
          <NoItemsInList>
            {!isLoading ? (searchInput ? 'No vehicles found' : 'No vehicles to show') : ''}
          </NoItemsInList>
        )}
      </Scrollable>
      <Loader isLoading={isLoading || isLoadingMore} firstLoad={!shownItems?.length} />
    </ContentBoardStyled>
  );
};

export default VehicleListPage;
