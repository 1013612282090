import { extendMoment } from 'moment-range';
import origMoment from 'moment';

const moment = extendMoment(origMoment);

const today = moment();
export const startRange = moment.range(
  today.clone(),
  today.clone()
);

const initialState = {
  dates: startRange,
  datesFilter: false,
  search: '',
  sortKey: 'Name',
  sortDirection: 'ASC'
};

const filterStore = {
  initialState
};

export default filterStore;
