import React, { createContext, useContext, useEffect, useState, useMemo, useRef } from 'react';
import doFetch from '../../api/doFetch';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

const context = createContext();
export const defaultValue = {
  vin: '',
  makes: [],
  models: [],
  year: null,
  modelNumber: '',
  exteriorColor: '',
  interiorColor: '',
  photoAmount: { name: 'Any', values: {} },
  msrp: '',
  invoiceAmount: { name: 'Any', values: {} }
};

export const filteTorQuery = (filter) => {
  if (filter.vinFragment) return `&vin=${filter.vinFragment}`;
  if (filter.vin) return `&vin=${filter.vin}`;

  return Object.keys(filter).reduce((query, key) => {
    switch (key) {
      case 'makes':
        query += filter.makes.map((make) => `&make=${make}`).join('');
        break;
      case 'models':
        query += filter.models.map((model) => `&model=${model}`).join('');
        break;
      case 'year':
        query += `&year=${filter.year}`;
        break;
      case 'modelNumber':
        query += `&model_number=${filter.modelNumber}`;
        break;
      case 'exteriorColor':
        query += `&exterior_color=${filter.exteriorColor}`;
        break;
      case 'interiorColor':
        query += `&interior_color=${filter.interiorColor}`;
        break;
      case 'msrp':
        query += `&msrp=${filter.msrp}`;
        break;
      case 'photoAmount':
        if (filter.photoAmount?.values?.from)
          query += `&photo_from=${filter.photoAmount.values.from}`;
        if (filter.photoAmount?.values?.to) query += `&photo_to=${filter.photoAmount.values.to}`;
        break;
      case 'invoiceAmount':
        if (filter.invoiceAmount?.values?.from)
          query += `&invoice_from=${filter.invoiceAmount.values.from}`;
        if (filter.invoiceAmount?.values?.to)
          query += `&invoice_to=${filter.invoiceAmount.values.to}`;
        break;
      default:
        break;
    }
    return query;
  }, '');
};

export const ProviderPhotos = ({ children }) => {
  const [pageInfo, setPageInfo] = useState({ curretPage: 0, totalPages: null, requestId: uuid() });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [photos, setPhotos] = useState(null);

  const [filter, setFilter] = useState(defaultValue);
  const [models, setModels] = useState([]);
  const prevFilter = useRef(defaultValue);
  const [makes, setMakes] = useState([]);
  const lastRequestId = useRef();
  const controller = useRef();

  useEffect(() => {
    setPageInfo({ curretPage: 1 });
    Promise.all([doFetch('/vehicles/makes')]).then(([makes]) => {
      setMakes(makes);
    });
  }, []);

  useEffect(() => {
    if (selected?.length) {
      if (!photos) {
        setSelected([]);
      } else {
        const filtered = selected.filter((id) => photos.some((photo) => photo.id === id));
        if (filtered?.length !== selected?.length) {
          setSelected(filtered);
        }
      }
    }
    // eslint-disable-next-line
  }, [photos]);

  useEffect(() => {
    if (isEqual(filter, prevFilter.current)) return;

    setIsLoading(true);
    setPageInfo((v) => ({ ...v, curretPage: 1, requestId: uuid() }));

    prevFilter.current = filter;
  }, [filter]);

  useEffect(() => {
    lastRequestId.current = pageInfo?.requestId;
    if (controller.current) controller.current.abort();

    if (!pageInfo.curretPage) return;
    if (isEqual(filter, defaultValue)) {
      setIsLoading(false);
      setPhotos(null);
      return;
    }

    const query = `page=${pageInfo.curretPage}&limit=200${filteTorQuery(filter)}`;

    controller.current = new AbortController();

    doFetch(`/vehicles/photos?${query}`, { signal: controller.signal }).then((result) => {
      if (lastRequestId.current !== pageInfo?.requestId) return;
      setPageInfo((v) => ({ ...v, ...result.meta }));
      setIsLoading(false);
      if (!photos || pageInfo.curretPage === 1) {
        setPhotos(result.items);
      } else {
        setIsLoadingMore(false);
        setPhotos((p) => [...p, ...result.items]);
      }
    });
    // eslint-disable-next-line
  }, [pageInfo?.requestId]);

  useEffect(() => {
    if (!filter?.makes?.length) {
      setModels([]);
    } else {
      Promise.all(filter.makes.map((make) => doFetch(`/vehicles/makes/${make}/models`))).then(
        (models) => {
          setModels(
            models.reduce((result, list) => {
              result.push(...list);
              return result;
            }, [])
          );
        }
      );
    }
  }, [filter.makes]);

  const contextValue = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      isLoadingMore,
      setIsLoadingMore,
      photos,
      setPhotos,
      filter,
      setFilter,
      makes,
      models,
      pageInfo,
      setPageInfo,
      selected,
      setSelected
    }),
    [isLoading, isLoadingMore, photos, filter, makes, models, pageInfo, selected]
  );

  return (
    <>{contextValue && <context.Provider value={contextValue}>{children}</context.Provider>}</>
  );
};

export const usePhotos = () => {
  const { photos, isLoading, isLoadingMore } = useContext(context);
  return { photos, isLoading, isLoadingMore };
};

export const useLoadMore = () => {
  const { setIsLoadingMore, setPageInfo } = useContext(context);

  return async () => {
    setIsLoadingMore(true);
    setPageInfo((v) => ({ curretPage: v.curretPage + 1, requestId: uuid() }));
  };
};

export const useFilter = () => {
  return useContext(context).filter;
};

export const usePatchFilter = () => {
  const { setFilter } = useContext(context);

  return async (patch) => {
    setFilter((filter) => ({ ...filter, ...patch }));
  };
};

export const useResetFilter = () => {
  const { setFilter } = useContext(context);

  return async () => {
    setFilter(defaultValue);
  };
};

export const useMakes = () => {
  return useContext(context).makes;
};

export const useModels = () => {
  return useContext(context).models;
};

export const useSelected = () => {
  const { selected, setSelected } = useContext(context);

  return [selected, setSelected];
};

export const useDownloadFiles = () => {
  const { selected } = useContext(context);

  return async () => {
    doFetch(`/vehicles/photos/download`, {
      method: 'POST',
      body: JSON.stringify({ ids: selected }),
      responseType: 'blob'
    })
      .then((response) => response.blob())
      .then((response) => {
        const file = new Blob([response], { type: 'application/octet-stream' });
        const fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.download = `files.zip`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        alert(error);
      });
  };
};
