import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';

import { BoardHeaderStyled, BoardHeaderWrapper, HeaderTab, HeaderTabs, Label } from './ui/styles';
import { StyledModalListItem } from '../../components/StyledListItem';
import ContentBoardStyled from '../../components/ContentBoardStyled';
import ItemLinkStyled from '../../components/ItemLinkStyled';
import useReportsForUser from '../../api/useReportsForUser';
import NoItemsInList from '../../components/NoItemsInList';
import { ControlBarContext } from '../ControlBarContext';
import DateInput from '../Activity/components/DateInput';
import DateStyled from '../../components/DateStyled';
import Scrollable from '../../components/Scrollable';
import { formattedDate } from '../../utils/date';
import useUser from '../../api/useUser';

const BillingUserReports = ({ getBreadCrumbs }) => {
  const { setBreadCrumbs } = useContext(ControlBarContext);
  const { userId } = useParams();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = useCallback((_, tab) => setTabValue(tab), [setTabValue]);
  const [selectedDate, setSelectedDate] = useState(moment());

  const { user } = useUser(userId);
  const { reports } = useReportsForUser(userId, {
    reportDay: tabValue ? formattedDate(selectedDate, 'YYYY-MM-DD') : null,
    notes: ['Full Shoot']
  });

  useEffect(() => {
    user && user.displayName && setBreadCrumbs(getBreadCrumbs({ userName: user.displayName }));
  }, [setBreadCrumbs, user, getBreadCrumbs]);

  return (
    <ContentBoardStyled>
      <BoardHeaderWrapper>
        <BoardHeaderStyled>USER REPORTS</BoardHeaderStyled>
        <HeaderTabs value={tabValue} onChange={handleTabChange}>
          <HeaderTab label={'All time'} />
          <HeaderTab
            label={
              <DateInput
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                labelElement={<Label>{formattedDate(selectedDate)}</Label>}
              />
            }
          />
        </HeaderTabs>
      </BoardHeaderWrapper>

      <Scrollable maxHeight={`calc(100vh - 150px - 50px)`}>
        {reports && reports.items.length ? (
          reports &&
          reports.items.map((report) => (
            <Fragment key={report.day}>
              <DateStyled>{formattedDate(report.day)}</DateStyled>
              {reports &&
                report.dealerships.map((dealer, index) => (
                  <ItemLinkStyled
                    to={`/billing/user/${userId}/reports/${dealer.hmsId}/${report.day}`}
                    key={dealer.hmsId + report.day + index}
                  >
                    <StyledModalListItem name={dealer.name} />
                  </ItemLinkStyled>
                ))}
            </Fragment>
          ))
        ) : (
          <NoItemsInList>No reports from this user</NoItemsInList>
        )}
      </Scrollable>
    </ContentBoardStyled>
  );
};

export default BillingUserReports;
