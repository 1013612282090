import useSWR from 'swr';
import app from "../base";


const useUser = (id) => {
    const { data, error, mutate } = useSWR(
        id
            ? [`/users/${id}`, /* options */ undefined, app.auth().currentUser.uid]
            : null
    );

    return {
        user: data,
        isLoadingUser: !error && !data,
        isError: error,
        mutateUser: mutate,
    }
};

export default useUser;
