import React from 'react';
import { withStyles, Tab, Tabs, Chip } from '@material-ui/core';
import DateRangePicker from 'react-daterange-picker';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';

import COLORS from '../../COLORS';
import { сameraIcon, searchIcon, checkedIcon } from '../../img';

import 'react-daterange-picker/dist/css/react-calendar.css';

export const DealershipBox = styled.div`
  margin-bottom: 8px;
`;

export const DealershipVehicle = styled.div``;

export const DealershipVehicleReport = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  color: #28283a;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(40, 56, 144, 0.1);
    padding: 8px 0;

    &:nth-child(1) {
      justify-content: flex-start;
      width: 20%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4) {
      justify-content: flex-end;
      width: 15%;
      padding-right: 20px;
    }
  }
`;

export const DealershipVehicleLine = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #28283a;
  border-bottom: solid 1px rgba(40, 56, 144, 0.1);
  margin-left: 18px;
  padding: 13px 0 10px;

  div {
    &:nth-child(1) {
      width: 36%;
    }

    // photos
    &:nth-child(2) {
      display: flex;
      align-items: center;
      width: 5%;

      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 8px;
        margin-right: 8px;
        background-image: url(${сameraIcon});
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    // stock
    &:nth-child(3) {
      text-align: center;
      width: 14%;
    }

    // vin
    &:nth-child(4) {
      width: 22%;
    }

    // condition
    &:nth-child(5) {
      text-align: center;
      width: 10%;
    }

    // year
    &:nth-child(6) {
      text-align: right;
      padding-right: 20px;
      width: 13%;
    }
  }
`;

export const ExportButton = styled.div`
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 13px 20px;
  border-radius: 45px;
  box-shadow: 0 3px 10px 0 rgba(40, 56, 144, 0.21);
  background-color: #283890;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  user-select: none;

  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      `
        background-color: #19245e;
        box-shadow: 0 5px 10px 0 rgba(40, 56, 144, 0.5);
        `}
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
            background: #aaaaaa;
            cursor: default;
    `}
`;

export const FiltersWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 32px;
  overflow: auto;
`;

export const SIWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-size: 12px;
  color: ${COLORS.label};
  font-weight: bold;
  width: 100%;


`;

export const SIBox = styled.div`
  position: relative;
  width: 100%;
  height: 26px;
  margin-top: 8px;
  padding-left: 7px;
  padding-right: 40px;
  border-radius: 5px;
  border: solid 1px #f0f4fa;
  background-color: #fafaff;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 2;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  color: #9595a8;

  ${({ notEmpty }) =>
    notEmpty &&
    `
    color: #28283a;
    &::after {
        content: '';
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 7px;
        height: 7px;
        border-bottom: 1px solid #677b8b;
        border-right: 1px solid #677b8b;
        transform: rotate(45deg);
        right: 9px;
        top: 7px;
    }`}

  ${({ count }) =>
    count &&
    `
    &::before {
        content: '${count}';
        background-color: #283890;
        width: 12px;
        height: 12px;
        color: #fff;
        border-radius: 50%;
        position: absolute;
        font-size: 8px;
        text-align: center;
        line-height: 1.5;
        font-weight: 600;
        right: 24px;
        top: 6px;
    }
    }`}
`;

export const Search = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 15px;
  outline: none;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(40, 56, 144, 0.1);
    left: 0;
    bottom: 0;
  }
`;

export const SearchIcon = styled.div`
  background-image: url(${searchIcon});
  background-size: cover;
  width: 14px;
  height: 12px;
`;

export const SearchInput = styled.input`
  margin-left: 5px;
  width: calc(100% - 55px);
  border: 0;
  outline: 0;
  font-family: NunitoSans;
  font-size: 12px;
  font-weight: 600;
  color: #28283a;
  outline: none;
`;

export const SearchClearButton = styled.div`
  position: absolute;
  right: 15px;
  top: 12px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  outline: none;

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 12px;
    right: 4px;
    top: -2px;
    width: 2px;
    background-color: #677b8b;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const List = styled.div`
  overflow: auto;
  height: ${({ single }) => (single ? `100%` : `calc(100% - 44px)`)};
`;

export const SIMenuWrap = withStyles({
  paper: ({ single }) => ({
    borderRadius: '7px',
    height: single ? 'auto' : '322px',
    width: '217px',
    boxShadow: '0 14px 17px 0 rgba(16, 32, 45, 0.33)',
    overflow: 'hidden',
  }),
  list: ({ single }) => ({
    height: '100%',
    padding: single ? '0' : '8px 0'
  })
})((props) => <Menu TransitionComponent={Fade} {...props} />);

export const SIMenuItem = withStyles({
  root: {
    minHeight: '37px',
    borderBottom: '1px solid rgba(40, 56, 144, 0.1)',
    fontSize: '10px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    whiteSpace: 'normal',
    lineHeight: '1.4',
    paddingRight: '40px'
  },
  selected: {
    position: 'relative',
    background: 'white',

    '&:after': {
      display: 'block',
      position: 'absolute',
      content: '""',
      width: '18px',
      height: '18px',
      backgroundImage: `url(${checkedIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      right: '6px'
    }
  }
})((props) => <MenuItem {...props} />);

export const CIWrap = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
`;

export const CIBoxWrap = styled.div`
  display: flex;
`;

export const CIBox = styled.div`
  flex: 1;
  height: 26px;
  margin-top: 8px;
  padding: 7px;
  border-radius: 5px;
  border: solid 1px #f0f4fa;
  background-color: #fafaff;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  color: #28283a;

  &:first-child {
    margin-right: 6px;
  }

  ${({ highlited }) =>
    highlited &&
    `
        border: solid 1px #283890;
    `}
`;

export const CIPopover = withStyles({
  paper: {
    borderRadius: '7px',
    width: '217px',
    boxShadow: '0 14px 17px 0 rgba(16, 32, 45, 0.33)',
    overflow: 'hidden'
  }
})((props) => <Popover TransitionComponent={Fade} {...props} />);

export const CalendarWrap = styled(DateRangePicker)`
  width: 100%;
  margin: 0;

  .DateRangePicker {
    font-family: NunitoSans;

    &__CalendarHighlight {
      &--single {
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(40, 56, 144, 0.2);
        border-radius: 20px;
      }
    }

    &__CalendarSelection {
      background-color: #283890;
      border: 0;
      top: 4px;
      bottom: 4px;

      &--single {
        border-radius: 20px;
        left: 4px;
        right: 4px;
      }

      &--start {
        border-bottom-left-radius: 20px;
        border-right-width: 0;
        border-top-left-radius: 20px;
        left: 0;
      }

      &--end {
        border-bottom-right-radius: 20px;
        border-left-width: 0;
        border-top-right-radius: 20px;
        right: 0;
      }
    }

    &__Date {
      border: 0;
      font-size: 10px;
      width: auto;
      height: 30px;
      padding: 0;

      &--today {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          background: #283890;
          width: 12px;
          height: 2px;
          left: calc(50% - 6px);
          bottom: 7px;
        }

        &--is-selected {
          &:after {
            background: white;
          }
        }
      }

      &--weekend {
        background: none;
      }

      &--is-highlighted {
        &.DateRangePicker__Date--is-selected {
          color: white;
        }
      }
    }

    &__WeekdayHeading {
      border: 0;
      font-size: 10px;
      padding: 6px 0;

      & abbr[title] {
        color: #677b8b;
      }
    }

    &__Month {
      width: 100%;
      margin: 0;

      &Header {
        height: 30px;
        font-size: 10px;
        letter-spacing: -0.09px;
        color: #677b8b;

        &Label {
          &--month {
            margin-right: 5px;
          }

          &--year {
            margin-left: 5px;
          }
        }
      }
    }

    &__PaginationArrow {
      border-radius: 50%;
      height: 17px;
      width: 17px;
      top: 10px;
      background-color: rgba(40, 56, 144, 0.1);

      &--previous {
        left: 12px;
      }

      &--next {
        right: 12px;
      }

      &:hover {
        background-color: rgba(40, 56, 144, 0.2);
      }

      &Icon {
        border-bottom: 3px solid transparent;
        border-top: 3px solid transparent;
        top: 5px;

        &--previous {
          border-left: 4px solid transparent;
          border-right: 4px solid #283890;
          left: 2px;
        }

        &--next {
          border-left: 4px solid #283890;
          border-right: 4px solid transparent;
          left: 7px;
        }
      }
    }
  }
`;

export const FilterResetButton = styled.div`
  position: absolute;
  right: 14px;
  top: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17.5px;
  background-color: rgba(39, 55, 144, 0.09);
  font-family: NunitoSans;
  padding: 4px 14px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: -0.09px;
  text-align: center;
  color: #283890;
  cursor: pointer;

  &:hover {
    background-color: rgba(40, 56, 144, 0.14);
  }

  &:active {
    background-color: rgba(40, 56, 144, 0.25);
  }
`;

export const FilterTabs = withStyles({
  root: {
    position: 'relative',
    padding: '0 10px',
    borderBottom: '1px solid rgba(40, 56, 144, 0.1)'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',

    '& > span': {
      width: 'calc(100% - 25px)',
      backgroundColor: '#635ee7'
    }
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const FilterTab = withStyles({
  root: {
    fontFamily: 'NunitoSans',
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'rgba(40, 40, 58, 0.5)',
    minWidth: 'unset',
    opacity: 1
  },
  selected: {
    color: '#283890'
  }
})((props) => <Tab disableRipple {...props} />);

export const ChipsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px 2px;
  min-height: 38px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(40, 56, 144, 0.1);
    bottom: 0;
    left: 0;
  }
`;

export const ChipItem = withStyles({
  root: {
    fontFamily: 'NunitoSans',
    height: '15px',
    color: '#28283a',
    fontSize: '8px',
    marginRight: '8px',
    marginBottom: '8px',
    borderRadius: '11px',
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: 'rgba(216, 216, 225, 0.5) !important',
    overflow: 'hidden',

    '& > span': {
      padding: '0 7px',
      margin: '0',
      userSelect: 'none'
    }
  }
})((props) => <Chip {...props} />);

export const CloseButton = styled.div`
  position: relative;
  width: 5px;
  height: 5px;
  cursor: pointer;
  margin: 0 0 0 -8px !important;

  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 8px;
    top: 7px;
    width: 1px;
    background-color: #28283a;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
