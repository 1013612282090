import React from 'react';
import { withStyles, Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';

export const HeaderTabs = withStyles({
  root: {
    position: 'relative',
    color: 'rgba(40, 40, 58, 0.5)'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',

    '& > span': {
      width: 'calc(100% - 25px)',
      backgroundColor: '#635ee7'
    }
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const BoardHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(40, 56, 144, 0.1);
    bottom: 0;
    left: 0;
  }
`;

export const BoardHeaderStyled = styled.div`
  position: relative;
  font-family: NunitoSans;
  font-size: 10px;
  font-weight: bold;
  color: #283890;
`;

export const Label = styled.div`
  position: relative;
  padding-bottom: 15px;
  font-weight: bold;
  margin-top: -1px;
  font-size: 10px;
  align-self: flex-start;
  padding-right: 20px;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    right: 5px;
    top: 3px;
  }
`;

export const HeaderTab = withStyles({
  root: {
    fontFamily: 'NunitoSans',
    fontSize: '10px',
    fontWeight: 'bold',
    minWidth: 'unset',
    opacity: 1,
    padding: '0 12px'
  },
  selected: {
    color: '#283890'
  }
})((props) => <Tab disableRipple {...props} />);
