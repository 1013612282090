import { Subject } from 'rxjs';
import { extendMoment } from 'moment-range';
import origMoment from 'moment';

const moment = extendMoment(origMoment);

export const ConditionFilterData = {
    items: [
        { id: 1, name: 'All' },
        { id: 2, name: 'New' },
        { id: 3, name: 'Used' }
    ]
};

export const NotesFilterData = {
    items: [
        { id: 1, value: 'Short Shoot' },
        { id: 2, value: 'Full Shoot' },
        { id: 3, value: 'Keys not Available' },
        { id: 4, value: 'Detail' },
        { id: 5, value: 'Demo' },
        { id: 6, value: 'Body Shop' },
        { id: 7, value: 'Wholesale' },
        { id: 8, value: 'Not Ready Dirty' },
        { id: 9, value: 'Not Found' },
        { id: 10, value: 'Not in System' },
        { id: 11, value: 'Loaner' },
        { id: 12, value: 'In Plastic' },
        { id: 13, value: 'Service' }
    ]
};

const today = moment();
export const startRange = moment.range(today.clone().subtract(7, 'days').startOf('day'), today.clone());
export const startSxmRange = moment.range(today.clone().subtract(7, 'days').startOf('day'), today.clone());

const subject = new Subject();

const initialState = {
    data: {
        locations: [],
        users: [],
        notes: []
    },
    dates: startRange,
    sxmDates: startSxmRange,
    condition: ConditionFilterData.items[0],
    siriusxm: 0
};

let state = initialState;

const filterStore = {
    init: () => {
        state = { ...state };
        subject.next(state);
    },
    subscribe: (setState) => subject.subscribe(setState),
    sendMessage: (data) => {
        state = {
            ...state,
            data: { ...state.data, ...data }
        };
        subject.next(state);
    },
    updateDates: (dates) => {
        state = { ...state, ...dates };
        subject.next(state);
    },
    updateSxmDates: (dates) => {
        if (dates) {
            state = { ...state, ...dates };
            subject.next(state);
        }
    },
    updateCondition: (condition) => {
        state = { ...state, ...condition };
        subject.next(state);
    },
    updateOption: (isSiriusxm) => {
        state = { ...state, siriusxm: isSiriusxm };
        subject.next(state);
    },
    resetFilters: (index) => {
        if (index === 1) {
            state = {
                ...state,
                sxmDates: initialState.sxmDates,
            };
        } else {
            state = {
                ...state,
                data: initialState.data,
                dates: initialState.dates,
                condition: initialState.condition
            };
        }
        subject.next(state);
    },
    initialState
};

export default filterStore;
