import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import useDealer from '../../api/useDealer';
import useVehiclesFiltered from '../../api/useVehiclesFiltered';
import { ControlBarContext } from '../ControlBarContext';
import VehiclesFilterBoard from './components/vehicles/VehiclesFilterBoard';
import ContentBoardStyled from '../../components/ContentBoardStyled';
import ItemLinkStyled from '../../components/ItemLinkStyled';
import VehicleListItem from '../Vehicles/ui/VehicleListItem';
import filterStore from './services/VehiclesFilterService';
import NoItemsInList from '../../components/NoItemsInList';
import SearchHeader from '../../components/SearchHeader';
import Scrollable from '../../components/Scrollable';
import Loader from '../../components/Loader';

const DealerReportWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 246px;
  width: 246px;
  margin: 25px 25px 0 0;
`;

const DealerVehiclesList = ({ getBreadCrumbs }) => {
  const { hmsId } = useParams();
  const { dealer } = useDealer(hmsId);
  const [searchInput, setSearchInput] = useState('');
  const { setBreadCrumbs } = useContext(ControlBarContext);
  const [filterState, setFilterState] = useState(filterStore.initialState);

  const { data, isLoading, isLoadingMore, size, setSize } = useVehiclesFiltered(
    searchInput,
    hmsId,
    filterState
  );

  const shownItems =
    data && data[0]?.items
      ? [].concat(...data?.map((data) => data?.items?.map((item) => item.vehicles).flat()).flat())
      : [];

  const inputHandler = (value) => setSearchInput(value);

  const handleLoadMore = () => {
    if (!isLoading) setSize(size + 1);
  };

  useLayoutEffect(() => {
    const subs = filterStore.subscribe((d) => setFilterState(d));
    filterStore.init();
    return () => subs.unsubscribe();
  }, []);

  useEffect(
    () => dealer && setBreadCrumbs(getBreadCrumbs({ dealer })),
    [getBreadCrumbs, setBreadCrumbs, dealer]
  );

  return (
    <DealerReportWrapper>
      <ContentBoardStyled>
        <SearchHeader
          placeholder='Search by VIN, make, model, stock'
          userInput={searchInput}
          onInputHandler={(event) => inputHandler(event.target.value)}
          onClearHandler={() => inputHandler('')}
        />
        <Scrollable isLoading={isLoading || isLoadingMore} bottomReached={handleLoadMore}>
          {!!shownItems?.length ? (
            shownItems.map((vehicle) => (
              <ItemLinkStyled to={`/dealers/${hmsId}/vehicles/${vehicle.vin}`} key={vehicle.vin}>
                <VehicleListItem data={vehicle} />
              </ItemLinkStyled>
            ))
          ) : (
            <NoItemsInList>
              {!isLoading ? (searchInput ? 'No vehicles found' : 'No vehicles to show') : ''}
            </NoItemsInList>
          )}
        </Scrollable>
        <Loader isLoading={isLoading || isLoadingMore} firstLoad={!shownItems?.length} />
      </ContentBoardStyled>
      <RightSide>
        <VehiclesFilterBoard lastUpdateTime={data && data[0] ? data[0]?.lastUpdateTime : null} />
      </RightSide>
    </DealerReportWrapper>
  );
};

export default DealerVehiclesList;
