import React from 'react';
import styled from 'styled-components';
import 'react-bnb-gallery/dist/style.css';
import { formattedDateTime } from '../../../utils/date';
import { checkedWhiteIcon, vehicleNoPhoto } from '../../../img';

const SelectIcon = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 31px;
  top: 5px;
  left: 5px;
  background: #a6abae;
  cursor: default;
  opacity: 0;
  border: solid 1px transparent;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: no-repeat center/55% url(${checkedWhiteIcon});
  }

  ${(props) =>
    props.selected &&
    `
    background: #283890 !important;
    border: solid 1px #283890 !important;

    &::after {
      opacity: 1 !important;;
    }
  `}
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 128px;
  height: 120px;
  margin: 8px;

  &:hover ${SelectIcon} {
    opacity: 1;
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: default;

    ${SelectIcon} {
      display: none;
    }
  `}

  ${(props) =>
    props.deleteMode &&
    `
    cursor: default;

    &:hover ${SelectIcon} { 
      background: #a6abae;

      &::after {
        opacity: 1;
      }
    }

    ${SelectIcon} {
      opacity: 1;
      background: transparent;
      border: solid 1px #a6abae;

      &::after {
        opacity: 0;
      }

      &:hover {
        background: #94989a;
        border: solid 1px #94989a;
      }
    }
  `}
`;

const BoxImage = styled.img`
  display: block;
  width: 128px;
  height: 96px;
  min-height: 96px;
  object-fit: contain;
  cursor: move;
  cursor: grab;
  background-color: rgba(216, 216, 216, 0.34);
`;

const BoxImageService = styled.div`
  display: block;
  width: 128px;
  height: 96px;
  min-height: 96px;
  object-fit: contain;
  background-image: url(${vehicleNoPhoto});
`;

const BoxInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-weight: bold;
  color: #677b8b;
  width: 100%;
  margin: auto;
`;

export default function ({ item, deleteMode, selected, onSelectClick, onPhotoClick }) {
  return (
    <BoxWrapper
      disabled={false}
      deleteMode={true}
      onClick={() =>
        deleteMode ? onSelectClick(item) : item.url ? onPhotoClick(item.id) : null
      }
    >
      {item.url ? (
        <SelectIcon
          selected={selected}
          onClick={(e) => {
            e.stopPropagation();
            onSelectClick(item);
          }}
        />
      ) : null}
      {item.url ? <BoxImage src={item.url} /> : <BoxImageService />}
      <BoxInfo>
        <span></span>
        <span>{item.url ? formattedDateTime(item.uploadedOn) : null}</span>
      </BoxInfo>
    </BoxWrapper>
  );
}
