import React, { createRef, useState } from 'react';
import styled from 'styled-components';

import { CIWrap, CIBox, CIPopover, CIBoxWrap, CalendarWrap } from './styles';
import { formattedDate } from '../../utils/date';
import { resetFilterIcon, resetFilterActiveIcon } from '../../img';

const ResetIcon = styled.div`
  margin: 0 8px;
  background-image: url(${resetFilterIcon});
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ResetActiveIcon = styled.div`
  margin: 0 8px;
  background-image: url(${resetFilterActiveIcon});
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CalendarInput = ({ selectedDates, setSelectedDates, resetDates, filterIsActive }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const calendarAnchor = createRef();

  const handleOpen = () => setAnchorEl(calendarAnchor.current);
  const handleClose = () => setAnchorEl(null);

  return (
    <CIWrap css={{ margin: 0, width: '300px' }}>
      <CIBoxWrap ref={calendarAnchor}>
        <CIBox css={{ 'margin-top': 0 }} onClick={handleOpen}>
          {formattedDate(selectedDates?.start)}
        </CIBox>
        <CIBox css={{ 'margin-top': 0 }} onClick={handleOpen}>
          {formattedDate(selectedDates?.end)}
        </CIBox>
        {filterIsActive ? (
          <ResetActiveIcon onClick={resetDates}></ResetActiveIcon>
        ) : (
          <ResetIcon></ResetIcon>
        )}
      </CIBoxWrap>
      <CIPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <CalendarWrap value={selectedDates} singleDateRange={true} onSelect={setSelectedDates} />
      </CIPopover>
    </CIWrap>
  );
};

export default CalendarInput;
