import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import moment from 'moment';

import MediumButtonStyled from '../../components/Buttons/MediumButtonStyled';
import ContentBoardStyled from '../../components/ContentBoardStyled';
import { formattedDate, secondToTime } from '../../utils/date';
import NoItemsInList from '../../components/NoItemsInList';
import { ControlBarContext } from '../ControlBarContext';
import Scrollable from '../../components/Scrollable';
import DateInput from './components/DateInput';
import useSummary from '../../api/useSummary';
import Loader from '../../components/Loader';
import { AuthContext } from '../../Auth';
import doFetch from '../../api/doFetch';
import {
  ActivityTitle,
  ActivityHeader,
  ActivityHeaderCol,
  ActivityHeaderInfoCol,
  ActivityTable,
  ActivityTableRow
} from './ui/styles';

const ActivityPage = ({ getBreadCrumbs }) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const { setBreadCrumbs, setRightSide } = useContext(ControlBarContext);
  const { summary, isLoading } = useSummary(formattedDate(selectedDate, 'y-MM-DD'));
  const { currentUser } = useContext(AuthContext);
  const match = useRouteMatch();

  const canSeePrice = useMemo(() => {
    return ['admin'].includes(currentUser?.role);
    // eslint-disable-next-line
  }, [currentUser?.role]);

  useEffect(
    () =>
      setRightSide({
        Component: (
          <MediumButtonStyled
            onClick={() => {
              doFetch(
                `/reports/users/summary/download?start=${formattedDate(
                  selectedDate,
                  'y-MM-DD'
                )}&end=${formattedDate(selectedDate, 'y-MM-DD')}`,
                { responseType: 'blob' }
              )
                .then((response) => response.blob())
                .then((response) => {
                  const file = new Blob([response], { type: 'application/pdf' });
                  const fileURL = URL.createObjectURL(file);
                  var a = document.createElement('a');
                  a.href = fileURL;
                  a.download = `Daily activity - ${formattedDate(selectedDate)}.pdf`;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                })
                .catch((error) => alert(error));
            }}
          >
            Download report
          </MediumButtonStyled>
        ),
        url: match.url
      }),
    [setRightSide, selectedDate, match.url]
  );

  useEffect(() => setBreadCrumbs(getBreadCrumbs()), [setBreadCrumbs, getBreadCrumbs]);

  return (
    <ContentBoardStyled style={{ padding: '18px 22px' }}>
      <ActivityHeader>
        <ActivityHeaderCol>
          <ActivityTitle>Users statistics</ActivityTitle>
          <DateInput selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </ActivityHeaderCol>
        <ActivityHeaderInfoCol>
          <div>
            <div>
              <span>Total units</span>
              <span>{summary?.stats.units || '–'}</span>
            </div>
            <div>
              <span>Total Full shoot</span>
              <span>{summary?.stats.totalFullShoot || '–'}</span>
            </div>
            {canSeePrice && (
              <div>
                <span>Total amount, $</span>
                <span>{summary?.stats?.price || '–'}</span>
              </div>
            )}
          </div>
          <div>
            <div>
              <span>Total average time</span>
              <span>{summary?.stats.avg ? secondToTime(summary?.stats.avg) : '–'}</span>
            </div>
            <div>
              <span>Total Short shoot</span>
              <span>{summary?.stats?.totalShortShoot || '–'}</span>
            </div>
            {canSeePrice && (
              <div>
                <span></span>
                <span></span>
              </div>
            )}
          </div>
        </ActivityHeaderInfoCol>
      </ActivityHeader>

      {!!summary?.items?.length ? (
        <ActivityTable>
          <ActivityTableRow head columns={canSeePrice ? 10 : 9}>
            <div>Name</div>
            <div>Units</div>
            <div>Avg. units per day</div>
            <div>Dealerships</div>
            <div>First shoot</div>
            <div>Last shoot</div>
            <div>Avg. time per shoot</div>
            <div>Full shoot </div>
            <div>Short shoot</div>
            {canSeePrice && <div>Total amount, $</div>}
          </ActivityTableRow>
          <Scrollable>
            {summary?.items.map((row, index) => (
              <ActivityTableRow key={index} columns={canSeePrice ? 10 : 9}>
                <div>{row.displayName}</div>
                <div>{row.units}</div>
                <div>{row.avgMount}</div>
                <div>{row.dealerships}</div>
                <div>{row.first}</div>
                <div>{row.last}</div>
                <div>{row.avgTime}</div>
                <div>{row.totalFullShoot}</div>
                <div>{row.totalShortShoot}</div>
                {canSeePrice && <div>{row.price}</div>}
              </ActivityTableRow>
            ))}
          </Scrollable>
        </ActivityTable>
      ) : (
        <NoItemsInList>{!isLoading ? 'No reports to show' : ''}</NoItemsInList>
      )}
      <Loader isLoading={isLoading} firstLoad={true} />
    </ContentBoardStyled>
  );
};

export default ActivityPage;
