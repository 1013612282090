import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import StyledListItem, { ManagerLabelStyled } from '../../components/StyledListItem';
import MediumButtonStyled from '../../components/Buttons/MediumButtonStyled';
import ContentBoardStyled from '../../components/ContentBoardStyled';
import ItemLinkStyled from '../../components/ItemLinkStyled';
import CreateUserModal from './components/CreateUserModal';
import NoItemsInList from '../../components/NoItemsInList';
import SearchHeader from '../../components/SearchHeader';
import { ControlBarContext } from '../ControlBarContext';
import Scrollable from '../../components/Scrollable';
import useUsers from '../../api/useUsers';

const DetailsStyled = styled.div`
  width: calc(100% - 310px);
  opacity: 0.8;
  font-family: NunitoSans;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  font-size: 10px;
  letter-spacing: normal;
  color: #28283a;
`;

const ManageUsersPage = ({ getBreadCrumbs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { setBreadCrumbs, setRightSide } = useContext(ControlBarContext);
  const { users, mutateUsers } = useUsers(searchInput);
  const match = useRouteMatch();

  const onInputHandler = (event) => setSearchInput(event.target.value);
  const onClearHandler = () => setSearchInput('');
  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [setIsModalOpen, isModalOpen]);

  useEffect(() => {
    setBreadCrumbs(getBreadCrumbs());
  }, [setBreadCrumbs, getBreadCrumbs]);

  useEffect(() => {
    setRightSide({
      Component: <MediumButtonStyled onClick={toggleModal}>Add new user</MediumButtonStyled>,
      url: match.url
    });
  }, [setRightSide, match.url, toggleModal]);

  return (
    <ContentBoardStyled>
      <CreateUserModal isOpen={isModalOpen} toggleHandler={toggleModal} updateUsers={mutateUsers} />
      <SearchHeader
        userInput={searchInput}
        onInputHandler={onInputHandler}
        placeholder='Search by name'
        onClearHandler={onClearHandler}
      />
      <Scrollable maxHeight={`calc(100vh - 200px)`}>
        {users && users?.items && users.items?.length ? (
          users.items.map(({ uuid, displayName, dealerships, role }, index) => {
            const details = dealerships.map((dealership) => dealership.name);
            const detailsString = Array.isArray(details) && details.join(', ');

            return (
              <ItemLinkStyled to={`/users/${uuid}`} key={displayName + index}>
                <StyledListItem
                  name={displayName}
                  nameComponent={
                    role === 'manager' && <ManagerLabelStyled>Manager</ManagerLabelStyled>
                  }
                >
                  <DetailsStyled>{detailsString}</DetailsStyled>
                </StyledListItem>
              </ItemLinkStyled>
            );
          })
        ) : (
          <NoItemsInList>{searchInput ? 'No users found' : 'No users to show'}</NoItemsInList>
        )}
      </Scrollable>
    </ContentBoardStyled>
  );
};

export default ManageUsersPage;
