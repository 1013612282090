import React from 'react';
import styled from 'styled-components';

import BaseModal from './BaseModal';
import Button from './Buttons/Button';

const ModalHeaderWrapper = styled.div`
  display: flex;
  color: #283890;
`;

const SubmitAreaStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const ConfirmText = styled.div`
  padding: 20px 0;
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.13px;
  color: #28283a;
`;

const SaveButton = styled(Button)``;

const DeleteButton = styled(Button)`
  width: 120px;
  height: 40px;
  border: 0px solid white;
  border-radius: 26px;
  font-family: NunitoSans;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #283890;
  background-color: #fff;
  box-shadow: none;
  outline: 0;

  &:hover {
    background-color: #ebebeb;
    color: #fff;
  }
`;

const ConfirmationModal = ({ isOpen, title, text, toggleHandler, confirmAction, confirmTitle }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    toggleHandler();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      toggleHandler={toggleHandler}
      header={<ModalHeaderWrapper>{title}</ModalHeaderWrapper>}
    >
      <ConfirmText>{text}</ConfirmText>
      <form onSubmit={handleSubmit}>
        <SubmitAreaStyled>
          <DeleteButton onClick={confirmAction}>
            {confirmTitle ? confirmTitle : 'Delete'}
          </DeleteButton>
          <SaveButton type='submit'>Cancel</SaveButton>
        </SubmitAreaStyled>
      </form>
    </BaseModal>
  );
};

export default ConfirmationModal;
