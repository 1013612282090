import React from 'react';
import styled from 'styled-components';

const LineAfter = styled.div`
  display: ${({ hidden }) => (hidden ? `none` : `flex`)};
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 12px;
  font-weight: bold;
  color: #283890;
  padding: 18px 0 6px 18px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 5px 10px 2px white;

  &:after {
    content: '';
    height: 1px;
    background: #283890;
    flex: 1;
    margin-left: 18px;
  }
`;

const TitleLine = ({ title, hidden }) => <LineAfter hidden={hidden}>{title}</LineAfter>;
export default TitleLine;
