import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';

import ContentBoardStyled from '../../../components/ContentBoardStyled';
import StyledBoardHeader from '../../../components/StyledBoardHeader';
import EditPricesModal from './EditPricesModal';
import PriceBox from './PriceBox';
import LastBillingDate from './LastBillingDate';
import { AuthContext } from '../../../Auth';

const InfoBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 340px;
  padding: 12px 20px 4px;
`;

const LABELS = {
  newCarFullShootPrice: 'Full Shoot (New)',
  usedCarFullShootPrice: 'Full Shoot (Used)',
  usedCarShortShootPrice: 'Short shoot (Used)'
};

const DealerPriceBoard = ({ dealer, updateDealer }) => {
  const [isOpenModal, setIsOpenEditUserModal] = useState(false);
  const toggleModal = () => setIsOpenEditUserModal(!isOpenModal);
  const { currentUser } = useContext(AuthContext);

  const canSeePrice = useMemo(() => {
    return ['admin'].includes(currentUser?.role);
    // eslint-disable-next-line
  }, [currentUser?.role]);

  console.log('user :: ', currentUser?.role, canSeePrice);
  return (
    <ContentBoardStyled
      css={{
        minHeight: '130px',
        marginLeft: 0,
        marginTop: 0,
        width: '482px'
      }}
    >
      <StyledBoardHeader button={canSeePrice ? { name: 'Edit', handler: toggleModal } : null}>
        PRICE PER SERVICE
      </StyledBoardHeader>
      {canSeePrice && (
        <InfoBlock>
          {dealer &&
            Object.keys(LABELS).map((key) => (
              <PriceBox label={LABELS[key]} data={dealer[key]} key={key} />
            ))}
          <EditPricesModal
            dealer={dealer}
            labels={LABELS}
            isOpen={isOpenModal}
            toggleHandler={toggleModal}
            updateDealer={updateDealer}
          />
        </InfoBlock>
      )}
      <InfoBlock>
        {dealer && <LastBillingDate label='Last billing date' date={dealer.lastBillingDate} />}
      </InfoBlock>
    </ContentBoardStyled>
  );
};

export default DealerPriceBoard;
