import React from 'react';
import styled from 'styled-components';
import SmallButtonStyled from './Buttons/SmallButtonStyled';

const BoardHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  min-height: 50px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(40, 56, 144, 0.1);
    bottom: 0;
    left: 0;
  }
`;

const BoardHeaderStyled = styled.div`
  position: relative;
  font-family: NunitoSans;
  font-size: 10px;
  font-weight: bold;
  color: #283890;
`;

const SmallRightText = styled.span`
  font-size: 12px;
  opacity: 0.5;
  color: #28283a;
`;

const ButtonGroup = styled.div`
  display: flex;
  & div + div {
    margin-left: 8px;
  }
`;

const BoardHeader = ({ button, buttons, rightText, disabledButton, children }) => (
  <BoardHeaderWrapper>
    <BoardHeaderStyled>{children}</BoardHeaderStyled>
    {Boolean(button) && (
      <SmallButtonStyled onClick={disabledButton ? null : button.handler} disabled={disabledButton}>
        {button.name}
      </SmallButtonStyled>
    )}
    {Boolean(buttons?.length) && (
      <ButtonGroup>
        {buttons.map((button, index) => (
          <SmallButtonStyled
            key={`header-btn-${index}`}
            onClick={disabledButton ? null : button.handler}
            disabled={disabledButton}
          >
            {button.name}
          </SmallButtonStyled>
        ))}
      </ButtonGroup>
    )}
    {!button && rightText && <SmallRightText>{rightText}</SmallRightText>}
  </BoardHeaderWrapper>
);

const StyledBoardHeader = styled(BoardHeader)``;

export default StyledBoardHeader;
