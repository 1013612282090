import styled from 'styled-components';

const MediumButtonStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 17.5px;
    background-color: rgba(39, 55, 144, 0.09);
    font-family: NunitoSans;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.11px;
    text-align: center;
    color: #283890;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    user-select: none;
    cursor: pointer;

    &:hover {
        ${({ disabled }) => !disabled && `background-color: rgba(40, 56, 144, 0.14);}`}
    }

    &:active {
        ${({ disabled }) => !disabled && `background-color: rgba(40, 56, 144, 0.25);}`}
    }

    ${({ disabled }) =>
        disabled &&
        `
        pointer-events: none;
        cursor: default;
        background-color: rgba(40, 40, 58, 0.1);
        color: rgba(40, 57, 44, 0.5);
    }`}
`;

export default MediumButtonStyled;
