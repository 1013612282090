import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import ContentBoardStyled from '../../../components/ContentBoardStyled';
import ConfirmationModal from '../../../components/ConfirmationModal';
import StyledBoardHeader from '../../../components/StyledBoardHeader';
import doFetch from '../../../api/doFetch';
import InfoBox from './InfoBox';

const InfoBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 340px;
    padding: 12px 20px 4px;
`;

const LABELS = {
    address: 'Address',
    city: 'City',
    state: 'State, ZIP',
    latitude: 'Latitude',
    longitude: 'Longitude'
};

const DealerInfoBoard = ({ dealer }) => {
    const [isOpenConfirm, setIsOpenConfirmModal] = useState(false);
    const history = useHistory();

    const toggleModal = () => setIsOpenConfirmModal(!isOpenConfirm);

    const handleDelete = () => {
        doFetch(`/dealerships/${dealer.hmsId}`, { method: 'DELETE' })
            .then(() => history.push('../'))
            .catch((error) => alert(error));
    };

    return (
        <ContentBoardStyled css={{ minHeight: '130px', marginLeft: 0, marginTop: 0, width: '482px' }}>
            <StyledBoardHeader button={{ name: 'Delete', handler: toggleModal }} disabledButton={!dealer?.canBeDeleted}>
                DEALERSHIP INFORMATION
            </StyledBoardHeader>
            <InfoBlock>
                {dealer &&
                    Object.keys(LABELS).map((key) => (
                        <InfoBox
                            label={LABELS[key]}
                            data={key === 'state' ? `${dealer[key]}, ${dealer.zip}` : dealer[key]}
                            key={key}
                        />
                    ))}
                <ConfirmationModal
                    title={'DELETE DEALERSHIP'}
                    text={'If the dealer has not been removed from the database, it will be returned after the update'}
                    isOpen={isOpenConfirm}
                    toggleHandler={toggleModal}
                    confirmAction={handleDelete}
                />
            </InfoBlock>
        </ContentBoardStyled>
    );
};

export default DealerInfoBoard;
