import useSWR, { mutate } from 'swr';

import app from '../base';
import doFetch from './doFetch';

const useDealers = (condition, dates, datesFilterActive, sortKey, sortDirection) => {
  const { data, error, mutate } = useSWR([
    `/dealerships?limit=99999&query=${condition}${
      datesFilterActive
        ? `&startOfBillingPeriod=${dates.start.toISOString()}&endOfBillingPeriod=${dates.end.toISOString()}`
        : ''
    }&sortKey=${sortKey}&sortDirection=${sortDirection}`,
    undefined,
    app.auth().currentUser.uid
  ]);

  return {
    dealers: data,
    isDealersLoading: !error && !data,
    isError: error,
    mutateDealers: mutate
  };
};

export const prefetchDealers = () =>
  mutate(
    [`/dealerships?limit=99999`, app.auth().currentUser.uid],
    async (dealers) => (dealers ? dealers : await doFetch(`/dealerships?limit=99999`)),
    false
  );

export default useDealers;
