import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import ContentBoardStyled from '../../../components/ContentBoardStyled';
import SearchHeader from '../../../components/SearchHeader';
import StyledListItem, { StyledModalListItem } from '../../../components/StyledListItem';
import DateStyled from '../../../components/DateStyled';
import { formattedDate } from '../../../utils/date';
import ItemLinkStyled from '../../../components/ItemLinkStyled';
import NoItemsInList from '../../../components/NoItemsInList';
import Scrollable from '../../../components/Scrollable';
import useReportsForDealer from '../../../api/useReportsForDealer';
import useReportsForDealerByVIN from '../../../api/useReportsForDealerByVIN';

const DealerReportsList = () => {
  const [userInput, setUserInput] = useState('');
  const { hmsId } = useParams();
  const { reports } = useReportsForDealer(hmsId);
  const { reportsByVin = [] } = useReportsForDealerByVIN(hmsId, userInput);

  const isSearch = userInput.length > 0;
  const isValidSearch = userInput.length > 5;
  const reportsToShow = isSearch ? reportsByVin.items : reports?.items;

  const onInputHandler = (event) => setUserInput(event.target.value);
  const onClearHandler = () => setUserInput('');

  return (
    <ContentBoardStyled css={{ [`${StyledListItem}:after`]: { width: 'calc(100% - 20px)' } }}>
      <SearchHeader
        userInput={userInput}
        placeholder='Search by VIN'
        onInputHandler={onInputHandler}
        onClearHandler={onClearHandler}
      />
      <Scrollable maxHeight={`calc(100vh - 177px)`}>
        {reportsToShow?.length ? (
          reportsToShow.map((reportsByDay) => (
            <Fragment key={reportsByDay.day}>
              <DateStyled>{formattedDate(reportsByDay.day)}</DateStyled>
              {reportsByDay.users.map((user) => (
                <ItemLinkStyled
                  to={`/dealers/reports/${hmsId}/${user.uuid}/${reportsByDay.day}`}
                  key={`${hmsId}${user.uuid}${reportsByDay.day}`}
                >
                  <StyledModalListItem name={user.displayName} />
                </ItemLinkStyled>
              ))}
            </Fragment>
          ))
        ) : (
          <NoItemsInList>
            {isSearch
              ? isValidSearch
                ? 'No reports found'
                : 'Enter more then 5 symbols of VIN for searching'
              : 'No reports to show'}
          </NoItemsInList>
        )}
      </Scrollable>
    </ContentBoardStyled>
  );
};

export default DealerReportsList;
