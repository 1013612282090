import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { ControlBarContext } from '../ControlBarContext';
import useDeletedDealers from '../../api/useDeletedDealers';
import doFetch from '../../api/doFetch';

import ContentBoardStyled from '../../components/ContentBoardStyled';
import SmallButtonStyled from '../../components/Buttons/SmallButtonStyled';
import ConfirmationModal from '../../components/ConfirmationModal';
import Scrollable from '../../components/Scrollable';
import { ItemLinkRelativeWrapper } from '../../components/ItemLinkStyled';
import StyledListItem from '../../components/StyledListItem';
import { EmailsStyled, NoEmailStyled } from '../../components/EmailStyled';
import SearchHeader from '../../components/SearchHeader';
import NoItemsInList from '../../components/NoItemsInList';

import { formattedAgoDate } from '../../utils/date';
import filterStore from './services/DealerService';

const EmailsWrapper = styled.section`
  position: absolute;
  top: 0;
  right: 30px;
  display: flex;
  align-items: center;
  width: 38%;
  height: 79px;
`;

const DetailsStyled = styled.div`
  font-family: NunitoSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: normal;
  color: #28283a;
`;

const RestoreButton = styled(SmallButtonStyled)`
  margin-left: 14px;
`;

const DealershipDeletedPage = ({ getBreadCrumbs }) => {
  const history = useHistory();
  const [dealerForModal, setDealerForModal] = useState({});
  const [isOpenConfirm, setIsOpenConfirmModal] = useState(false);
  const [filterState] = useState(filterStore.initialState);
  const [searchInput, setSearchInput] = useState(filterState.search);
  const [sortKey, setSortKey] = useState(filterState.sortKey);
  const [sortDirection, setSortDirection] = useState(filterState.sortDirection);
  const { setBreadCrumbs } = useContext(ControlBarContext);
  const { dealers } = useDeletedDealers(searchInput, sortKey, sortDirection);

  const onInputHandler = (event) => setSearchInput(event.target.value);
  const onClearHandler = () => setSearchInput('');

  useEffect(() => setBreadCrumbs(getBreadCrumbs()), [getBreadCrumbs, setBreadCrumbs]);

  const toggleModal = (dealer) => {
    setDealerForModal(dealer);
    setIsOpenConfirmModal(!isOpenConfirm);
  };
  const handleDelete = () => {
    doFetch(`/dealerships/${dealerForModal.hmsId}/restore`, { method: 'POST' })
      .then(() => history.push(`/dealers/reports/${dealerForModal.hmsId}`))
      .catch((error) => alert(error));
  };

  return (
    <ContentBoardStyled isWithLinkRelativeWrapper>
      <SearchHeader
        userInput={searchInput}
        placeholder='Search by name'
        onInputHandler={onInputHandler}
        onClearHandler={onClearHandler}
        sortKey={sortKey}
        setSortKey={setSortKey}
        sortItems={[
          { id: 'Name', name: 'Name' },
          { id: 'DateOfDeleted', name: 'Date of deleted' }
        ]}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <Scrollable maxHeight={`calc(100vh - 200px)`}>
        <ConfirmationModal
          title='PUT BACK DEALERSHIP'
          text='Please, confirm if you want to put this dealership back.'
          isOpen={isOpenConfirm}
          toggleHandler={toggleModal}
          confirmTitle='Put back'
          confirmAction={handleDelete}
        />
        {dealers && dealers?.items && dealers.items?.length ? (
          dealers.items.map((dealer) => (
            <ItemLinkRelativeWrapper key={dealer.hmsId}>
              <StyledListItem name={dealer.name} itemCss={{ width: '44%' }}>
                <DetailsStyled>
                  {dealer.deletedAt ? formattedAgoDate(dealer.deletedAt) : '-'}
                </DetailsStyled>
              </StyledListItem>
              <EmailsWrapper>
                {dealer.email ? (
                  <EmailsStyled css={{ width: 'calc(100% - 82px)' }}>{dealer.email}</EmailsStyled>
                ) : (
                  <NoEmailStyled css={{ width: 'calc(100% - 82px)' }}>
                    no email address
                  </NoEmailStyled>
                )}
                <RestoreButton onClick={() => toggleModal(dealer)}>Put back</RestoreButton>
              </EmailsWrapper>
            </ItemLinkRelativeWrapper>
          ))
        ) : (
          <NoItemsInList>
            {searchInput ? 'No dealership found' : 'No dealership to show'}
          </NoItemsInList>
        )}
      </Scrollable>
    </ContentBoardStyled>
  );
};

export default DealershipDeletedPage;
