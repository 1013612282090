import styled from 'styled-components';
import { сameraIcon, checkedIcon } from '../../../img';

export const SelectedCounter = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin: 0 0 0 24px;
    font-size: 14px;
    font-weight: 600;
    color: #28283a;
  }
  & > div {
    top: unset;
    right: unset;
  }
`;

export const ModalHeaderWrapper = styled.div`
  display: flex;
  color: #283890;
`;

export const SubmitAreaStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 12px;

  & > .match {
    color: #28283a80;
    font-weight: 400;
  }
  & > .sort {
    color: #28283a80;
    font-weight: 700;
    display: flex;
    align-items: center;
    & > div {
      margin: 0;
      & > div {
        margin: 0;
      }
    }
  }
`;

export const Vehicle = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  border-bottom: 1px solid #2838901a;
  cursor: pointer;

  & > .info {
    width: 100%;
    & > .top {
      color: #28283a;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      height: 22px;
      padding-top: 6px;

      & > .name {
        font-size: 14px;
        width: 100%;
      }
      & > .value {
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      & > .select {
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    & > .bottom {
      color: #28283a80;
      font-weight: 400;
      font-size: 10px;
      display: flex;
      padding: 4px 0 8px;
      & > .stock {
        width: 20%;
      }
      & > .vin {
        width: 50%;
      }
    }
  }
`;

export const CameraIcon = styled.div`
  width: 10px;
  height: 8.3px;
  background-image: url(${сameraIcon});
  background-size: 10px;
  margin-right: 10px;
  margin-top: -2px;
`;

export const CheckIcon = styled.div`
  width: 20px;
  height: 22px;
  background-image: url(${checkedIcon});
  background-size: 20px;
`;
