import React, { useLayoutEffect, useState } from 'react';

import { formattedDate, formattedTime } from '../../../utils/date';
import ContentBoardStyled from '../../../components/ContentBoardStyled';
import StyledBoardHeader from '../../../components/StyledBoardHeader';
import StyledListItem from '../../../components/StyledListItem';
import NoItemsInList from '../../../components/NoItemsInList';
import Scrollable from '../../../components/Scrollable';
import filterStore from '../services/ReportsService';
import useReports from '../../../api/useReports';
import Loader from '../../../components/Loader';
import ReportsChips from './ReportsChips';
import {
  DealershipBox,
  DealershipVehicle,
  DealershipVehicleLine,
  DealershipVehicleReport,
  ExportButton
} from '../../../components/Inputs/styles';
import doFetch from '../../../api/doFetch';
import TitleLine from '../../../components/TitleLine';
import { preapareQueries } from '../../../utils/urls';

const ReportsList = () => {
  const [filterState, setFilterState] = useState(filterStore.initialState);
  const { reports, isLoading, isLoadingMore, size, setSize } = useReports(filterState, 40);

  const shownItems =
    reports && reports[0]?.items
      ? [].concat(...reports?.map((reports) => reports?.items?.flat()).flat())
      : [];

  const performExport = () => {
    doFetch(preapareQueries(filterState, true), { responseType: 'blob' })
      .then((response) => response.blob())
      .then((response) => {
        const type = filterState.siriusxm ? 'csv' : 'pdf';
        const file = new Blob([response], { type: `application/${type}` });
        const fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        if (filterState?.siriusxm ) {
          a.download = `Report - ${filterState.sxmDates.start.format(
            'MM.DD.YYYY'
          )}-${filterState.sxmDates.end.format('MM.DD.YYYY')}.${type}`;
        } else {
          a.download = `Report - ${filterState.dates.start.format(
            'MM.DD.YYYY'
          )}-${filterState.dates.end.format('MM.DD.YYYY')}.${type}`;
        }
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleLoadMore = () => {
    if (!isLoading) setSize(size + 1);
  };

  useLayoutEffect(() => {
    const subs = filterStore.subscribe(setFilterState);
    filterStore.init();
    return () => subs.unsubscribe();
  }, []);

  return (
    <ContentBoardStyled css={{ [`${StyledListItem}:after`]: { width: 'calc(100% - 20px)' } }}>
      <StyledBoardHeader>SUMMARY</StyledBoardHeader>
      <ReportsChips />
      <Scrollable
        maxHeight={`calc(100vh - 250px)`}
        isLoading={isLoading || isLoadingMore}
        bottomReached={handleLoadMore}
      >
        {!!shownItems?.length ? (
          shownItems.map((item, itemIndex) => (
            <DealershipBox key={itemIndex}>
              <TitleLine title={item.dealership.name} />
              <DealershipVehicle>
                {item.vehicles &&
                  item.vehicles.map((vehicle, vehicleIndex) => (
                    <div key={vehicleIndex}>
                      <DealershipVehicleLine key={vehicleIndex}>
                        <div>
                          {vehicle.vehicle.make} {vehicle.vehicle.model}
                        </div>
                        <div>{vehicle.vehicle.imageCount}</div>
                        <div>{vehicle.vehicle.stock}</div>
                        <div>{vehicle.vehicle.vin}</div>
                        <div>{vehicle.vehicle.type}</div>
                        <div>{vehicle.vehicle.year}</div>
                      </DealershipVehicleLine>
                      {vehicle.reports &&
                        vehicle.reports.map((report, reportIndex) => (
                          <DealershipVehicleReport key={reportIndex}>
                            <div>{report.user.displayName}</div>
                            <div>{formattedDate(report.createdOn)}</div>
                            <div>{formattedTime(report.createdOn)}</div>
                            <div>{filterState.siriusxm ? 'SiriusXM Activated' : report.notes}</div>
                          </DealershipVehicleReport>
                        ))}
                    </div>
                  ))}
              </DealershipVehicle>
            </DealershipBox>
          ))
        ) : (
          <NoItemsInList>{!isLoading ? 'No reports to show' : ''}</NoItemsInList>
        )}
      </Scrollable>
      <Loader isLoading={isLoading || isLoadingMore} firstLoad={!shownItems?.length} />
      <ExportButton
        onClick={shownItems.length ? performExport : null}
        isDisabled={!shownItems.length}
      >
        Export {filterState.siriusxm ? 'CSV' : 'PDF'} report
      </ExportButton>
    </ContentBoardStyled>
  );
};

export default ReportsList;
