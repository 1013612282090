import { useSWRInfinite } from 'swr';
import app from '../base';

const useVehiclesFiltered = (search, hmsId, filterState, limit = 20) => {
  const conditionQuery =
    filterState.condition?.name !== 'All'
      ? `&condition=${filterState.condition?.name?.toUpperCase()}`
      : '';
  const makesQuery = filterState.makes.length
    ? filterState.makes.map((make) => `&make=${make}`).join('')
    : '';
  const modelsQuery = filterState.models.length
    ? filterState.models.map((model) => `&model=${model}`).join('')
    : '';
  const yearFromQuery = filterState.years.from ? `&year_from=${filterState.years.from}` : '';
  const yearToQuery = filterState.years.to ? `&year_to=${filterState.years.to}` : '';
  const photoFrom =
    typeof filterState.photoCount?.values?.from === 'number'
      ? `&photo_from=${filterState.photoCount?.values?.from}`
      : '';
  const photoTo =
    typeof filterState.photoCount?.values?.to === 'number'
      ? `&photo_to=${filterState.photoCount?.values?.to}`
      : '';
  const countQuery = `${photoFrom}${photoTo}`;

  const preparedFitersQuery = `${conditionQuery}${makesQuery}${modelsQuery}${countQuery}${yearFromQuery}${yearToQuery}`;

  const { data, error, mutate, size, setSize } = useSWRInfinite(
    (index) =>
      `/vehicles?limit=${limit}&page=${
        index + 1
      }&search=${search}&hmsId=${hmsId}${preparedFitersQuery}`,
    undefined,
    app.auth().currentUser.uid
  );

  return {
    data,
    isLoading: !error && !data,
    isLoadingMore: (!error && !data) || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    isError: error,
    mutateVehicles: mutate,
    size,
    setSize
  };
};

export default useVehiclesFiltered;
