import React from 'react';
import styled from 'styled-components';
import { Tabs, withStyles } from '@material-ui/core';

import { FilterTab } from './Inputs/styles';
import CalendarInput from './Inputs/SimpleCalendarInput';
import SearchIconStyled from './SearchIconStyled';
import CloseButton from './Buttons/CloseButton';
import SortHeader from './SortHeader';

const SearchHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 20px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(40, 56, 144, 0.1);
    left: 0;
    bottom: 0;
  }
`;

const SearchInput = styled.input`
  margin-left: 5px;
  width: calc(100% - 20px - 17px - 12px - 20px);
  border: 0;
  outline: 0;
  font-family: NunitoSans;
  font-size: 12px;
  font-weight: 600;
  color: #28283a;
`;

const SearchInputHolder = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  height: 100%;
`;

export const SearchTabs = withStyles({
  root: {
    position: 'relative',
    padding: '0 22px'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',

    '& > span': {
      width: 'calc(100% - 25px)',
      backgroundColor: '#635ee7'
    }
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export default function ({
  userInput,
  onInputHandler,
  onClearHandler,
  placeholder,
  tabs,
  tabValue,
  handleTabChange,
  calendarDates,
  resetCalendar,
  filterIsActive,
  sortItems,
  sortKey,
  sortDirection,
  setCalendarDates,
  setSortKey,
  setSortDirection,
  ...props
}) {
  return (
    <SearchHeaderWrapper {...props}>
      <SearchIconStyled />
      <SearchInputHolder css={calendarDates && sortKey ? { 'max-width': 'calc(44% - 26px)' } : {}}>
        <SearchInput value={userInput} onChange={onInputHandler} placeholder={placeholder} />
        {userInput && <CloseButton onClick={onClearHandler} />}
      </SearchInputHolder>
      {tabs && (
        <SearchTabs value={tabValue} onChange={handleTabChange}>
          {tabs.map((tab, index) => (
            <FilterTab key={index} label={tab.label} />
          ))}
        </SearchTabs>
      )}
      {calendarDates && (
        <CalendarInput
          selectedDates={calendarDates}
          setSelectedDates={setCalendarDates}
          resetDates={resetCalendar}
          filterIsActive={filterIsActive}
          css={{ width: '300px', margin: 0 }}
        />
      )}
      {sortItems && (
        <SortHeader
          items={sortItems}
          sortKey={sortKey}
          sortDirection={sortDirection}
          setSortKey={setSortKey}
          setSortDirection={setSortDirection}
        />
      )}
    </SearchHeaderWrapper>
  );
}
