import styled, { css } from 'styled-components';
import { loadBlue, loadGrey } from '../img';

export default styled.div`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  ${(p) => css`
    ${Boolean(p.disabled) && 'animation: rotation 6s infinite linear;'}
    background: no-repeat center url(${p.disabled ? loadGrey : loadBlue});
    width: 24px;
    height: 24px;
  `}
`;
