import React, { createRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const Scrollable = ({ children, maxHeight, minHeight, isLoading, bottomReached, style }) => {
  const scrollbars = createRef();

  const handleScroll = () => {
    if (
      bottomReached &&
      scrollbars.current.getScrollTop() + scrollbars.current.getClientHeight() ===
        scrollbars.current.getScrollHeight()
    ) {
      bottomReached();
    }
  };

  return (
    <Scrollbars
      ref={scrollbars}
      onScroll={handleScroll}
      style={{ ...(style || {}), position: 'relative', flex: 1, overflow: 'hidden', minHeight }}
    >
      {children}
    </Scrollbars>
  );
};

export default Scrollable;
