import * as firebase from "firebase/app";
import "firebase/auth";


const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

app.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
export const auth = app.auth();

async function logIn(username, password) {
    await app.auth().signInWithEmailAndPassword(username, password);
    await app.auth().currentUser.getIdToken();
}

export { logIn };

export default app;
