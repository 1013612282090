import React, { useState, useCallback, useLayoutEffect, useEffect } from 'react';

import useDealers from '../../../api/useDealers';
import useUsers from '../../../api/useUsers';
import useNotes from '../../../api/useNotes';

import {
  FiltersWrap,
  FilterResetButton,
  FilterTabs,
  FilterTab
} from '../../../components/Inputs/styles';
import filterStore, { ConditionFilterData } from '../services/ReportsService';
import ContentBoardStyled from '../../../components/ContentBoardStyled';
import CalendarInput from '../../../components/Inputs/CalendarInput';
import SelectInput from '../../../components/Inputs/SelectInput';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <FiltersWrap p={3}>{children}</FiltersWrap>}
    </div>
  );
}

const ReportsFilter = () => {
  const { dealers } = useDealers('');
  const { users } = useUsers('');
  const { notes } = useNotes();

  const [filterState, setFilterState] = useState(filterStore.initialState);
  const [selectedDates, setSelectedDates] = useState(filterState.dates);
  const [selectedSxmDates, setSelectedSxmDates] = useState(filterState.sxmDates);
  const [selectedUsers, setSelectedUsers] = useState(filterState.data.users);
  const [selectedNotes, setSelectedNotes] = useState(filterState.data.notes);
  const [selectedDealers, setSelectedDealers] = useState(filterState.data.locations);
  const [selectedCondition, setSelectedCondition] = useState(filterState.condition);

  const resetFilters = () => filterStore.resetFilters(filterState.siriusxm);

  const updateFiltersHandler = useCallback(() => {
    filterStore.updateCondition({ condition: selectedCondition });
    filterStore.updateDates({ dates: selectedDates });
    if (selectedSxmDates) {
      filterStore.updateSxmDates({ sxmDates: selectedSxmDates });
    }
    filterStore.sendMessage({
      users: selectedUsers,
      locations: selectedDealers,
      notes: selectedNotes
    });
  }, [
    selectedDates,
    selectedUsers,
    selectedDealers,
    selectedCondition,
    selectedNotes,
    selectedSxmDates
  ]);

  const handleTabChange = (event, newValue) => {
    filterStore.updateOption(newValue);
    // resetFilters();
  };

  useLayoutEffect(() => {
    const subs = filterStore.subscribe(setFilterState);
    filterStore.init();
    return () => subs.unsubscribe();
  }, []);

  useEffect(() => {
    updateFiltersHandler();
  }, [
    setSelectedDates,
    setSelectedUsers,
    setSelectedNotes,
    setSelectedCondition,
    setSelectedDealers,
    updateFiltersHandler
  ]);

  useEffect(() => {
    setSelectedUsers(filterState.data.users);
    setSelectedDealers(filterState.data.locations);
    setSelectedNotes(filterState.data.notes);
    setSelectedCondition(filterState.condition);
    setSelectedDates(filterState.dates);
    setSelectedSxmDates(filterState.sxmDates);
  }, [filterState]);

  return (
    <ContentBoardStyled css={{ minHeight: '130px', marginLeft: 0, marginTop: 0 }}>
      <FilterTabs value={filterState.siriusxm} onChange={handleTabChange}>
        <FilterTab label='FILTER' />
        <FilterTab label='SIRIUSXM' />
        <FilterResetButton onClick={resetFilters}>Reset</FilterResetButton>
      </FilterTabs>

      <TabPanel value={filterState.siriusxm} index={0}>
        <SelectInput
          label={'Locations'}
          data={dealers}
          keys={{ id: 'hmsId', name: 'name', type: 'locations' }}
          selectedItems={filterState.data.locations}
          setSelectedItems={setSelectedDealers}
        />
        <SelectInput
          label={'Users'}
          data={users}
          keys={{ id: 'uuid', name: 'displayName', type: 'users' }}
          selectedItems={filterState.data.users}
          setSelectedItems={setSelectedUsers}
        />
        <SelectInput
          label={'Notes'}
          data={{ items: notes?.map((value, index) => ({ hmsId: index, value })) }}
          keys={{ id: 'hmsId', name: 'value', type: 'notes' }}
          selectedItems={filterState.data.notes}
          setSelectedItems={setSelectedNotes}
        />
        <SelectInput
          single={1}
          label={'Condition'}
          data={ConditionFilterData}
          keys={{ id: 'id', name: 'name', type: 'conditions' }}
          selectedItems={filterState.condition}
          setSelectedItems={setSelectedCondition}
        />
        <CalendarInput selectedDates={selectedDates} setSelectedDates={setSelectedDates} />
      </TabPanel>
      <TabPanel value={filterState.siriusxm} index={1}>
        <CalendarInput selectedDates={selectedSxmDates} setSelectedDates={setSelectedSxmDates} />
      </TabPanel>
    </ContentBoardStyled>
  );
};

export default ReportsFilter;
