import React, { useCallback, useContext, useEffect, useState } from 'react';

import StyledListItem, { ManagerLabelStyled } from '../../components/StyledListItem';
import ContentBoardStyled from '../../components/ContentBoardStyled';
import ItemLinkStyled from '../../components/ItemLinkStyled';
import NoItemsInList from '../../components/NoItemsInList';
import { ControlBarContext } from '../ControlBarContext';
import SearchHeader from '../../components/SearchHeader';
import Scrollable from '../../components/Scrollable';
import useDealers from '../../api/useDealers';
import useUsers from '../../api/useUsers';

const BillingPage = ({ getBreadCrumbs }) => {
  const [searchInput, setSearchInput] = useState('');
  const { setBreadCrumbs } = useContext(ControlBarContext);

  const { dealers } = useDealers(searchInput);
  const { users } = useUsers(searchInput);
  const [shownItems, setShownItems] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const onClearHandler = () => setSearchInput('');
  const onInputHandler = (event) => setSearchInput(event.target.value);
  const handleTabChange = useCallback((_, tab) => setTabValue(tab), [setTabValue]);

  useEffect(() => setBreadCrumbs(getBreadCrumbs()), [getBreadCrumbs, setBreadCrumbs]);

  useEffect(() => setShownItems(tabValue ? users?.items : dealers?.items), [
    handleTabChange,
    dealers,
    tabValue,
    users
  ]);

  return (
    <ContentBoardStyled>
      <SearchHeader
        userInput={searchInput}
        placeholder='Search by name'
        onInputHandler={onInputHandler}
        onClearHandler={onClearHandler}
        tabs={[{ label: 'Dealerships' }, { label: 'Users' }]}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
      />
      <Scrollable maxHeight={`calc(100vh - 200px)`}>
        {!!shownItems?.length ? (
          shownItems?.map((item, index) => {
            return (
              <ItemLinkStyled to={tabValue ? `/billing/user/${item.uuid}` : `/billing/dealers/${item.hmsId}`} key={index}>
                <StyledListItem
                  name={tabValue ? item.displayName : item.name}
                  nameComponent={
                    tabValue
                      ? item.role === 'manager' && <ManagerLabelStyled>Manager</ManagerLabelStyled>
                      : null
                  }
                ></StyledListItem>
              </ItemLinkStyled>
            );
          })
        ) : (
          <NoItemsInList>
            {searchInput
              ? tabValue
                ? 'No users found'
                : 'No dealership found'
              : tabValue
              ? 'No users to show'
              : 'No dealership to show'}
          </NoItemsInList>
        )}
      </Scrollable>
    </ContentBoardStyled>
  );
};

export default BillingPage;
