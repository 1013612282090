import React from "react";
import styled from 'styled-components';

import logo from '../img/logo-lotflo.svg';


const SplashWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 107px;
`;

const SplashScreen = () => (
    <SplashWrapper>
        <img src={logo} alt="lotflo" />
    </SplashWrapper>
);

export default SplashScreen;
