export const LogoImg = require('./logo-lotflo.svg');
export const usersImg = require('./users.svg');
export const usersActiveImg = require('./selected_users.svg');
export const dealersImg = require('./dealers.svg');
export const dealersActiveImg = require('./selected_dealers.svg');
export const reportsIcon = require('./reports.svg');
export const reportsActiveIcon = require('./reports-active.svg');
export const analyticsIcon = require('./analytics.svg');
export const analyticsActiveIcon = require('./analytics-active.svg');
export const сameraIcon = require('./camera.svg');
export const searchIcon = require('./search.svg');
export const checkedIcon = require('./check-mark.svg');
export const checkedWhiteIcon = require('./check-mark-white.svg');
export const calendarIcon = require('./calendar-icon.svg');
export const vehicleNoPhoto = require('./vehicle-no-photo.svg');
export const vehicleListIcon = require('./vehicleListIcon.svg');
export const vehicleListIconActive = require('./vehicleListIconActive.svg');
export const homenetType = require('./homenetType.png');
export const spincarType = require('./spincarType.png');
export const editIcon = require('./edit.svg');
export const servicePhotoIcon = require('./servicePhotoIcon.svg');
export const dailyActivity = require('./dailyActivity.svg');
export const dailyActivityActive = require('./dailyActivityActive.svg');
export const billing = require('./billing.svg');
export const billingActive = require('./billingActive.svg');
export const resetFilterIcon = require('./filter-icon-reset-normal.svg');
export const resetFilterActiveIcon = require('./filter-icon-reset-pressed.svg')
export const sortUpIcon = require('./filter-icon-2-normal.svg');
export const sortUpActiveIcon = require('./filter-icon-2-pressed.svg');
export const sortDownIcon = require('./filter-icon-normal.svg');
export const sortDownActiveIcon = require('./filter-icon-pressed.svg');
export const loadBlue = require('./load-blue.svg');
export const loadGrey = require('./load-grey.svg');
export const filteringIcon = require('./filtering.svg');
export const filteringActiveIcon = require('./filtering-active.svg')
