import React from 'react';
import Modal from 'styled-react-modal';
import styled from 'styled-components';

import CloseButton from './Buttons/CloseButton';

const ModalStyled = Modal.styled`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 18px 20px 24px;
    max-width: 356px;
    min-height: 100px;
    border-radius: 20px;
    background-color: white;
    
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: rgba(40, 56, 144, 0.1);
        top: 50px;
        left: 0;
    }

    & > .full-width {
        margin-left: -20px;
        margin-right: -20px;
    }
`;

const ModalHeader = styled.div`
  height: 32px;
  width: 100%;
  font-family: NunitoSans;
  font-size: 10px;
  font-weight: bold;
`;

const BaseModal = ({ isOpen, toggleHandler, header, children }) => (
  <ModalStyled isOpen={isOpen} onEscapeKeydown={toggleHandler}>
    <CloseButton onClick={toggleHandler} />
    <ModalHeader>{header}</ModalHeader>
    {children}
  </ModalStyled>
);

export default BaseModal;
