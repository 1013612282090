import styled from "styled-components";


const DateStyled = styled.div`
    padding: 19px 0 11px 20px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    color: #283890;
`;

export default DateStyled;
