import React, { forwardRef } from 'react';
import styled from 'styled-components';

import COLORS from '../COLORS';

const LabelStyled = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-family: NunitoSans;
  font-size: 12px;
  color: ${COLORS.label};
  font-weight: bold;
`;

const InputStyled = styled.input`
  width: 316px;
  height: 34px;
  margin-top: 8px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 5px;
  border: solid 1px #f0f4fa;
  background-color: #fafaff;
  box-sizing: border-box;
  font-size: 14px;
`;

const InputWrap = styled.div`
  display: flex;
  position: relative;

  ${({ prefix }) =>
    prefix &&
    `
    input { padding-left: 21px; }
    &:before {
        position: absolute;
        display: block;
        content: '${prefix}';
        top: 16px;
        left: 13px;
        color: black;
        font-size: 14px;
        font-weight: 600;
    }
  `}
`;

const LabelContentStyled = styled.span`
  ${({ required }) =>
    required &&
    `
    &::after {
        position: absolute;
        content: '*';
        color: red;
        top: 0;
        margin-left: 4px;
    }`}
`;

const Input = forwardRef(({ label, noLabel, required, prefix, ...inputProps }, ref) => {
  return noLabel ? (
    <InputStyled {...inputProps} ref={ref} />
  ) : (
    <LabelStyled>
      <LabelContentStyled required={required}>{label}</LabelContentStyled>
      <InputWrap prefix={prefix}>
        <InputStyled {...inputProps} ref={ref} />
      </InputWrap>
    </LabelStyled>
  );
});

export default Input;
