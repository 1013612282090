import styled, { css } from 'styled-components';

export const ActivityTitle = styled.div`
  padding-top: 4px;
  font-family: NunitoSans;
  font-size: 20px;
  font-weight: bold;
  color: #283890;
`;

export const ActivityTable = styled.div`
  display: contents;
  height: 100%;
  overflow: hidden;
`;

export const ActivityTableRow = styled.div`
  ${(props) => css`
    display: grid;
    grid-template-columns: 205px ${Array.from(Array((props.columns || 10) - 1))
        .map(() => '2fr')
        .join(' ')};
    gap: 0 20px;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    min-height: 54px;

    ${Boolean(props?.head) &&
    css`
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      align-items: flex-start;
      min-height: unset;
      padding-bottom: 10px;
    `}

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:first-child {
        text-align: left;
        justify-content: start;
      }
    }
  `}
`;

export const ActivityHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ActivityHeaderCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActivityHeaderInfoCol = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    justify-content: flex-end;
    line-height: 25px;

    span {
      text-align: right;
      display: inline-block;

      &:nth-child(1) {
        font-size: 10px;
        font-weight: bold;
        color: #677b8b;
        min-width: 130px;
      }

      &:nth-child(2) {
        font-size: 14px;
        color: #28283a;
        padding-left: 10px;
        min-width: 70px;
      }
    }
  }
`;
