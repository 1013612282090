import { Fade, Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { createRef, useState } from 'react';
import styled from 'styled-components';

import { checkedIcon } from '../../img';
import COLORS from '../../COLORS';

const Wrap = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-size: 12px;
  color: ${COLORS.label};
  font-weight: bold;
  width: 100%;
`;

const BoxWrap = styled.div`
  display: flex;
`;

const InputBox = styled.div`
  flex: 1;
  height: 26px;
  margin-top: 8px;
  padding: 7px;
  border-radius: 5px;
  border: solid 1px #f0f4fa;
  background-color: #fafaff;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  color: #28283a;

  &:first-child {
    margin-right: 6px;
  }

  ${({ highlited }) =>
    highlited &&
    `
        border: solid 1px #283890;
    `}
`;

const List = styled.div`
  overflow: auto;
  height: 100%;
`;

const YMenuItem = withStyles({
  root: {
    minHeight: '37px',
    borderBottom: '1px solid rgba(40, 56, 144, 0.1)',
    fontSize: '10px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    whiteSpace: 'normal',
    lineHeight: '1.4',
    paddingRight: '10px'
  },
  selected: {
    position: 'relative',
    background: 'white',

    '&:after': {
      display: 'block',
      position: 'absolute',
      content: '""',
      width: '18px',
      height: '18px',
      backgroundImage: `url(${checkedIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      right: '7px'
    }
  }
})((props) => <MenuItem {...props} />);

export const MenuWrap = withStyles({
  paper: {
    borderRadius: '7px',
    height: '322px',
    width: '100px',
    boxShadow: '0 14px 17px 0 rgba(16, 32, 45, 0.33)',
    overflow: 'hidden',
    transformOrigin: 'center top !important'
  },
  list: {
    height: '100%',
    padding: '0'
  }
})((props) => <Menu TransitionComponent={Fade} {...props} />);

const YearInput = ({ selectedYears, setSelectedYears }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFrom, setIsFrom] = useState(null);
  const refAnchor = createRef();

  const years = [
    'Any',
    ...Array(new Date().getFullYear() - 1900 + 1)
      .fill(new Date().getFullYear())
      .map((year, index) => year - index)
  ];

  const handleMenu = (event, isFrom) => {
    setAnchorEl(event?.currentTarget || null);
    setIsFrom(isFrom);
  };
  const handleItemClick = (key, item) => {
    const tmp = { ...selectedYears, [key]: typeof item === 'number' ? item : null };
    setSelectedYears(tmp);
    handleMenu(null);
    setIsFrom(null);
  };

  return (
    <Wrap>
      <span>Year</span>
      <BoxWrap ref={refAnchor}>
        <InputBox onClick={(event) => handleMenu(event, true)}>
          {selectedYears.from || 'From'}
        </InputBox>
        <InputBox onClick={(event) => handleMenu(event, false)}>
          {selectedYears.to || 'To'}
        </InputBox>
        <MenuWrap
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenu(null)}
        >
          <List>
            {years.map((item, index) => (
              <YMenuItem
                key={index}
                selected={selectedYears[isFrom ? 'from' : 'to'] === item}
                onClick={() => handleItemClick(isFrom ? 'from' : 'to', item)}
              >
                {item}
              </YMenuItem>
            ))}
          </List>
        </MenuWrap>
      </BoxWrap>
    </Wrap>
  );
};

export default YearInput;
