import React from 'react';
import styled from 'styled-components';
import { Switch } from 'react-router';

import { ControlBarProvider } from './ControlBarContext';
import { ProvideDealersInfo } from '../context';

import PrivateRoute from '../PrivateRoute';
import Menu from '../components/Menu';
import ControlBar from '../components/ControlBar';

import ManageUsersPage from './User/ManageUsersPage';
import DealershipListPage from './Dealer/DealershipListPage';
import DealershipDeletedPage from './DeletedDealer/DealershipListPage';
import UserProfile from './User/UserProfile';
import Report from './Report';
import AnalyticsPage from './AnalyticsPage';
import DealerReport from './Dealer/DealerReport';
import ReportsPage from './Reports/ReportsPage';
import VehicleListPage from './Vehicles/VehicleListPage';
import DealerVehicle from './Dealer/DealerVehicle';
import DealerVehiclesList from './Dealer/DealerVehiclesList';
import ActivityPage from './Activity/ActivityPage';
import BillingPage from './Billing/BillingPage';
import BillingUserReports from './Billing/BillingUserReports';
import BillingDealerReports from './Billing/BillingDealerReports';
import PhotosPage from './Photos'

const AppWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const MainSideStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color: #fafaff;
`;

const ContentStyled = styled.div`
  display: flex;
  height: 100%;
`;

const MAINPAGE_ROUTES = [
  {
    route: '/users',
    Component: ManageUsersPage,
    getBreadCrumbs: () => [{ name: 'Manage users' }]
  },
  {
    route: '/users/:userId',
    Component: UserProfile,
    getBreadCrumbs: ({ userName }) => [{ name: 'Manage users', url: `/users` }, { name: userName }]
  },

  {
    route: '/users/:userId/reports/:hmsId/:reportDate',
    Component: Report,
    getBreadCrumbs: ({ userName, dealerName, userId }) => [
      { name: 'Manage users', url: `/users` },
      { name: userName, url: `/users/${userId}` },
      { name: `${dealerName}` }
    ]
  },
  {
    route: '/dealers',
    Component: DealershipListPage,
    getBreadCrumbs: () => [{ name: 'Dealership list' }]
  },
  {
    route: '/dealers-deleted',
    Component: DealershipDeletedPage,
    getBreadCrumbs: () => [{ name: 'Dealership list' }]
  },
  {
    route: '/dealers/reports/:hmsId',
    Component: DealerReport,
    getBreadCrumbs: ({ dealerName, hmsId, lastUpdateDate }) => [
      { name: 'Dealership list', url: `/dealers` },
      { name: dealerName, hmsId, lastUpdateDate }
    ]
  },
  {
    route: '/dealers/reports/:hmsId/:userId/:reportDate',
    Component: Report,
    getBreadCrumbs: ({ dealerName, userName, date, hmsId }) => [
      { name: 'Dealership list', url: `/dealers` },
      { name: dealerName, url: `/dealers/reports/${hmsId}` },
      { name: `${date} ${userName}` }
    ]
  },
  {
    route: '/reports',
    Component: ReportsPage,
    getBreadCrumbs: () => [{ name: 'Reports' }]
  },
  {
    route: '/vehicles',
    Component: VehicleListPage,
    getBreadCrumbs: () => [{ name: 'Vehicle List' }]
  },
  {
    route: '/dealers/:hmsId/vehicles',
    Component: DealerVehiclesList,
    getBreadCrumbs: ({ dealer }) => [
      { name: 'Dealership list', url: `/dealers` },
      { name: dealer.name, url: `/dealers/reports/${dealer.hmsId}` },
      { name: 'Vehicles' }
    ]
  },
  {
    route: '/dealers/:hmsId/vehicles/:vin',
    Component: DealerVehicle,
    getBreadCrumbs: ({ dealer, stock }) => [
      { name: 'Dealership list', url: `/dealers` },
      { name: dealer.name, url: `/dealers/reports/${dealer.hmsId}` },
      { name: 'Vehicles', url: `/dealers/${dealer.hmsId}/vehicles` },
      { name: stock }
    ]
  },
  {
    route: '/activity',
    Component: ActivityPage,
    getBreadCrumbs: () => [{ name: 'Daily activity' }]
  },
  {
    route: '/billing',
    Component: BillingPage,
    getBreadCrumbs: () => [{ name: 'Billing' }]
  },
  {
    route: '/billing/user/:userId',
    Component: BillingUserReports,
    getBreadCrumbs: ({ userName }) => [{ name: 'Billing', url: `/billing` }, { name: userName }]
  },
  {
    route: '/billing/user/:userId/reports/:hmsId/:reportDate',
    Component: Report,
    getBreadCrumbs: ({ userName, dealerName, userId }) => [
      { name: 'Billing', url: `/billing` },
      { name: userName, url: `/billing/user/${userId}` },
      { name: `${dealerName}` }
    ]
  },
  {
    route: '/billing/dealers/:hmsId',
    Component: BillingDealerReports,
    getBreadCrumbs: ({ dealerName }) => [{ name: 'Billing', url: `/billing` }, { name: dealerName }]
  },
  {
    route: '/billing/dealers/:hmsId/:userId/:reportDate',
    Component: Report,
    getBreadCrumbs: ({ dealerName, userName, date, hmsId }) => [
      { name: 'Billing', url: `/billing` },
      { name: dealerName, url: `/billing/dealers/${hmsId}` },
      { name: `${date} ${userName}` }
    ]
  },
  {
    route: '/analytics',
    Component: AnalyticsPage,
    getBreadCrumbs: () => [{ name: 'Analytics' }]
  }, 
  {

    route: '/photo-filtering',
    Component: PhotosPage,
    getBreadCrumbs: () => [{ name: 'Filtering (new cars only)' }]
  }
];

const MainPage = () => (
  <AppWrapper>
    <Menu />
    <MainSideStyled>
      <ControlBarProvider>
        <ProvideDealersInfo>
          <ControlBar />
          <ContentStyled>
            <Switch>
              {MAINPAGE_ROUTES.map(({ route, Component, getBreadCrumbs }) => (
                <PrivateRoute exact path={route} key={route}>
                  <Component getBreadCrumbs={getBreadCrumbs} />
                </PrivateRoute>
              ))}
            </Switch>
          </ContentStyled>
        </ProvideDealersInfo>
      </ControlBarProvider>
    </MainSideStyled>
  </AppWrapper>
);

export default MainPage;
