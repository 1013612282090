import React from 'react';
import styled from 'styled-components';

import { сameraIcon, calendarIcon, vehicleNoPhoto, servicePhotoIcon } from '../../../img';

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 20px 18px;
  line-height: 30px;
  border-bottom: 1px solid rgba(40, 56, 144, 0.1);
  font-weight: normal;

  &:hover {
    background-color: rgba(40, 56, 144, 0.05);
  }
`;

const Image = styled.img`
  margin-right: 26px;
  width: 80px;
  height: 60px;
  object-fit: contain;
`;

const Title = styled.div`
  display: flex;
  font-size: 14px;
`;

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: #28283a;
  font-weight: 400;
`;

const Info = styled.div`
  display: flex;
  font-size: 12px;
`;

const InfoItem = styled.div`
  display: flex;
  margin-right: 16px;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    display: flex;
    align-items: center;
    margin-right: 25px;

    &:before {
      content: '';
      display: block;
      margin-right: 8px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &:nth-child(1)::before {
    width: 12px;
    height: 11px;
    background-image: url(${servicePhotoIcon});
  }

  &:nth-child(2)::before {
    width: 10px;
    height: 8px;
    background-image: url(${сameraIcon});
  }

  &:nth-child(3)::before {
    width: 12px;
    height: 12px;
    background-image: url(${calendarIcon});
  }
`;

const FIELDS = [
  'imageCount',
  'uploadedImageCount',
  'daysInStock',
  'stock',
  'vin',
  'color',
  'type',
  'year'
];

const VehicleListItem = ({ data }) => (
  <Wrap>
    <Image src={data.primaryImageUrl} onError={(e) => (e.target.src = vehicleNoPhoto)} />
    <InfoWrap>
      <Title>
        {data.make} {data.model}
      </Title>
      <Info>
        {data &&
          FIELDS.filter((key) => data.hasOwnProperty(key)).map((key, index) => (
            <InfoItem key={index}>{data[key]}</InfoItem>
          ))}
      </Info>
    </InfoWrap>
  </Wrap>
);

export default VehicleListItem;
