import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ControlBarContext } from '../ControlBarContext';
import { ProviderPhotos } from './context';
import Filter from './components/Filter';
import Table from './components/Table';

const ReportsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 246px;
  width: 246px;
  margin: 25px 25px 0 0;
`;

export default function ({ getBreadCrumbs }) {
  const { setBreadCrumbs } = useContext(ControlBarContext);

  useEffect(() => setBreadCrumbs(getBreadCrumbs()), [setBreadCrumbs, getBreadCrumbs]);

  return (
    <ProviderPhotos>
      <ReportsWrapper>
        <Table />
        <RightSide>
          <Filter />
        </RightSide>
      </ReportsWrapper>
    </ProviderPhotos>
  );
}
