import useSWR from 'swr';

import app from "../base";


const useReportsForDealerByVIN = (dealerId, searchInput) => {
    const { data, error, mutate } = useSWR(
        dealerId && (searchInput.length > 5)
            ? [
                `/reports/dealerships/${dealerId}?limit=99999&vin=${searchInput}`,
                /* options */ undefined,
                app.auth().currentUser.uid
            ]
            : null
    );

    return {
        reportsByVin: data,
        isLoadingUser: !error && !data,
        isError: error,
        mutateReports: mutate,
    }
};

export default useReportsForDealerByVIN;
