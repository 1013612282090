import React from 'react';
import styled from 'styled-components';
import { formattedDateTime } from '../../../../utils/date';
import { homenetType, spincarType } from '../../../../img';

const UserDataWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-basis: 50%;
  margin-bottom: 20px;

  &:nth-child(5) {
    flex: 100%;
  }
`;

const UserInfoLabelStyled = styled.div`
  font-family: NunitoSans;
  font-size: 10px;
  font-weight: bold;
  color: #677b8b;
  margin-bottom: 10px;
`;

const UserInfoDataStyled = styled.div`
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: 600;
  color: #28283a;
`;

const TypeImage = styled.img`
  width: 72px;
`;

const VehicleInfoBox = ({ label, data, labelKey }) => {
  const getImageType = (type) => <TypeImage src={type === 'HomeNet' ? homenetType : spincarType} />;

  const handleShownData = (data) => {
    return !!data
      ? labelKey === 'imageType'
        ? getImageType(data)
        : labelKey === 'lastImageUploadedOn'
        ? formattedDateTime(data)
        : data
      : '–';
  };

  return (
    <UserDataWrapper>
      <UserInfoLabelStyled>{label}</UserInfoLabelStyled>
      <UserInfoDataStyled>{handleShownData(data)}</UserInfoDataStyled>
    </UserDataWrapper>
  );
};

export default VehicleInfoBox;
