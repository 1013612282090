import React from 'react';
import styled from 'styled-components';

const spinner_size = 20;
const spinner_color = '39, 55, 144';

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  bottom: ${({ state }) => (state.firstLoad ? 'unset' : state.isLoading ? '30px' : '-50px')};
  top: ${({ state }) =>
    state.top ? `${state.top}px` : state.firstLoad ? `calc(20vh - ${spinner_size}px)` : 'unset'};
  opacity: ${({ state }) => (state.isLoading ? 1 : 0)};
  visibility: ${({ state }) => (state.isLoading ? 'visible' : 'hidden')};
  left: calc(50% - ${spinner_size}px);
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);

  &::after {
    content: '';
    display: ${({ state }) => (state.firstLoad ? 'none' : 'block')};
    position: absolute;
    height: ${spinner_size * 10}px;
    width: 100vw;
    background: radial-gradient(100% 40%, rgba(255, 255, 255, 1) 0%, transparent 100%);
  }
`;

const Spinner = styled.div`
  position: relative;
  z-index: 1;
  border: ${spinner_size * 0.15}px solid rgb(${spinner_color});
  border-left: ${spinner_size * 0.15}px solid rgba(${spinner_color}, 0.2);

  animation: spin 0.6s infinite linear;
  border-radius: 50%;
  width: ${spinner_size}px;
  height: ${spinner_size}px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ isLoading, firstLoad, top, style = {} }) => (
  <SpinnerWrapper state={{ isLoading, firstLoad, top }} style={style}>
    <Spinner />
  </SpinnerWrapper>
);

export default Loader;
