import React, { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import styled from 'styled-components';

import { logIn } from "../../base";
import { AuthContext } from "../../Auth";
import logo from '../../img/logo-lotflo.svg';
import Button from "../../components/Buttons/Button";
import Input from "../../components/Input";
import PasswordInput from "../../components/PasswordInput";
import { hasAccess } from '../../utils/users';


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 107px;
`;

const FormStyled = styled.form`
    padding-top: 53px;
`;

const SubmitAreaStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 32px;
`;

const LoginPage = () => {
    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { email, password } = event.target.elements;
            try {
                await logIn(email.value, password.value);
            } catch (error) {
                alert(error);
            }
        },
        []
    );

    const { currentUser } = useContext(AuthContext);

    if (currentUser && hasAccess(currentUser.role)) {
        return <Redirect to="/users" />;
    }

    return (
        <Wrapper>
            <img src={logo} alt="lotflo" />
            <FormStyled onSubmit={handleLogin}>
                <Input label={'Email'} name="email" type="email" required />
                <PasswordInput label="Password" required />
                <SubmitAreaStyled>
                    <Button type="submit">Log in</Button>
                </SubmitAreaStyled>
            </FormStyled>
        </Wrapper>
    );
};

export default LoginPage;
