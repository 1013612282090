import {
  usePhotos,
  useLoadMore,
  useSelected,
  useDownloadFiles,
  useFilter,
  usePatchFilter
} from '../context';
import ContentBoardStyled from '../../../components/ContentBoardStyled';
import StyledBoardHeader from '../../../components/StyledBoardHeader';
import CloseButton from '../../../components/Buttons/CloseButton';
import StyledListItem from '../../../components/StyledListItem';
import NoItemsInList from '../../../components/NoItemsInList';
import Scrollable from '../../../components/Scrollable';
import { SelectedCounter } from './styledComponents';
import Loader from '../../../components/Loader';
import ReactBnbGallery from 'react-bnb-gallery';
import React, { useState } from 'react';
import MoveModal from './MoveModal';
import Chips from './Chips';
import Image from './Image';

export default function () {
  const [moveModalOpened, setMoveModalOpened] = useState(false);
  const { photos, isLoading, isLoadingMore } = usePhotos();
  const [selected, setSelected] = useSelected();
  const downloadFiles = useDownloadFiles();
  const handleLoadMore = useLoadMore();
  const patchFilter = usePatchFilter();
  const filter = useFilter();

  const assignPhoto = () => setMoveModalOpened(true);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryPhotoIndex, setGalleryPhotoIndex] = useState(0);

  return (
    <ContentBoardStyled css={{ [`${StyledListItem}:after`]: { width: 'calc(100% - 20px)' } }}>
      <StyledBoardHeader
        buttons={
          Boolean(selected?.length)
            ? [
                { name: 'Assign photo', handler: assignPhoto },
                { name: 'Download photos', handler: downloadFiles }
              ]
            : []
        }
        rightText={
          Boolean(photos?.length) && !selected?.length
            ? 'Select photos to download and assign'
            : null
        }
      >
        {!selected?.length ? (
          <>VEHICLE PHOTOS</>
        ) : (
          <SelectedCounter>
            <CloseButton onClick={() => setSelected([])} />
            <p>{selected.length} Photo selected</p>
          </SelectedCounter>
        )}
      </StyledBoardHeader>
      <Chips filter={filter} patchFilter={patchFilter} />
      <Scrollable
        maxHeight={`calc(100vh - 250px)`}
        isLoading={isLoading || isLoadingMore}
        bottomReached={handleLoadMore}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Boolean(photos?.length) &&
            photos.map((photo, index) => (
              <Image
                item={photo}
                key={`image--` + photo.id}
                selected={selected.includes(photo.id)}
                onSelectClick={() =>
                  setSelected((v) => {
                    if (v.includes(photo.id)) return v.filter((id) => id !== photo.id);
                    return [...v, photo.id];
                  })
                }
                onPhotoClick={() => {
                  setGalleryPhotoIndex(index);
                  setIsGalleryOpen(true);
                }}
              />
            ))}
        </div>
        {!isLoading && !photos && <NoItemsInList>Start filtering to find photos</NoItemsInList>}
        {!isLoading && photos && !photos?.length && (
          <NoItemsInList>No photos to show</NoItemsInList>
        )}
      </Scrollable>
      <ReactBnbGallery
        opacity={0.8}
        show={isGalleryOpen}
        showThumbnails={false}
        photos={photos?.length ? photos.map((item) => item?.url || '') : []}
        activePhotoIndex={galleryPhotoIndex}
        onClose={() => setIsGalleryOpen(false)}
      />
      <Loader isLoading={isLoading || isLoadingMore} firstLoad={true} />
      {Boolean(moveModalOpened) && (
        <MoveModal
          isOpen={Boolean(moveModalOpened)}
          images={selected}
          filter={filter}
          toggleHandler={() => setMoveModalOpened(!moveModalOpened)}
        />
      )}
    </ContentBoardStyled>
  );
}
