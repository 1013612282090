import moment from 'moment';

const TIME_FORMAT = 'hh:mm A';
const DEFAULT_DATE_FORMAT = 'MM/DD/y';
const DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${TIME_FORMAT}`;

const formattedDate = (date, dateFormat = DEFAULT_DATE_FORMAT) => moment(date).format(dateFormat);
const formattedDateTime = (date) => {
  moment(date).format(DATETIME_FORMAT);
}
const formattedTime = (time) => moment(time).format(TIME_FORMAT);
const secondToTime = (second) => new Date(second * 1000).toISOString().substr(14, 5);

const formattedAgoDate = (date, dateFormat = DEFAULT_DATE_FORMAT) => {
  const formattedDate = moment(date).format(dateFormat);
  const formattedYesterday = moment(new Date(new Date().valueOf() - 864e5)).format(dateFormat);
  return formattedDate === formattedYesterday ? '1 day ago' : formattedDate;
};

export { formattedDate, formattedDateTime, formattedTime, secondToTime, formattedAgoDate };
