import styled from 'styled-components';
import StyledListItem from './StyledListItem';
import ItemLinkStyled, { ItemLinkRelativeWrapper } from './ItemLinkStyled';

const ContentBoardStyled = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    margin: 25px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 13px 0 rgba(215, 226, 235, 0.22);
    background-color: #ffffff;
    max-height: calc(100vh - 150px);

    ${StyledListItem}:after {
        content: '';
        width: calc(100% - 32px);
        height: 1px;
        background: rgba(40, 56, 144, 0.1);
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    ${({ isWithLinkRelativeWrapper }) =>
        isWithLinkRelativeWrapper
            ? `
            ${ItemLinkRelativeWrapper}:last-child > ${ItemLinkStyled} > ${StyledListItem}:after {
                content: '';
                height: 0;
            }
        
          
            ${ItemLinkRelativeWrapper}:last-child ${ItemLinkStyled} ${StyledListItem}:hover {
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;       
            }        
        `
            : `
            ${ItemLinkStyled}:last-child > div:after {
                content: '';
                height: 0;
            }
        
          
            
            ${ItemLinkStyled}:last-child ${StyledListItem}:hover {
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;
            }        
        `}
`;

const ModalContentBoardStyled = styled(ContentBoardStyled)`
    ${StyledListItem}:after {
        width: calc(100% - 20px);
    }
`;

export { ModalContentBoardStyled };
export default ContentBoardStyled;
