import doFetch from './doFetch';

export default function () {
  return async () => {
    const response = await doFetch(`/dealerships/update-status`);

    return {
      ...response
    };
  };
}
