import React, { Fragment } from 'react';

import { ModalContentBoardStyled } from '../../../components/ContentBoardStyled';
import { StyledModalListItem } from '../../../components/StyledListItem';
import ItemLinkStyled from '../../../components/ItemLinkStyled';
import StyledBoardHeader from '../../../components/StyledBoardHeader';
import Scrollable from '../../../components/Scrollable';
import DateStyled from '../../../components/DateStyled';
import { formattedDate } from '../../../utils/date';
import NoItemsInList from '../../../components/NoItemsInList';
import useReportsForUser from '../../../api/useReportsForUser';

const ReportsBoard = ({ userId }) => {
  const { reports } = useReportsForUser(userId);

  return (
    <ModalContentBoardStyled css={{ margin: 0, height: '100%' }}>
      <StyledBoardHeader>USER REPORTS</StyledBoardHeader>
      <Scrollable maxHeight={`calc(100vh - 150px - 50px)`}>
        {reports && reports.items.length ? (
          reports &&
          reports.items.map((report) => (
            <Fragment key={report.day}>
              <DateStyled>{formattedDate(report.day)}</DateStyled>
              {reports &&
                report.dealerships.map((dealer, index) => (
                  <ItemLinkStyled
                    to={`/users/${userId}/reports/${dealer.hmsId}/${report.day}`}
                    key={dealer.hmsId + report.day + index}
                  >
                    <StyledModalListItem name={dealer.name} />
                  </ItemLinkStyled>
                ))}
            </Fragment>
          ))
        ) : (
          <NoItemsInList>No reports from this user</NoItemsInList>
        )}
      </Scrollable>
    </ModalContentBoardStyled>
  );
};

export default ReportsBoard;
