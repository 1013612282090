import React, { useLayoutEffect, useState } from 'react';

import { ChipItem, ChipsWrapper, CloseButton } from '../../../components/Inputs/styles';
import filterStore from '../services/ReportsService';
import { formattedDate } from '../../../utils/date';

const ReportsChips = () => {
  const [filterState, setFilterState] = useState(filterStore.initialState);

  const canBeDeleted = (data) => {
    if (data.type === 'condition') {
      return data.value !== 'All';
    }
    return data.type !== 'date';
  };

  const handleDelete = (data) => () => {
    switch (data.type) {
      case 'condition':
        filterStore.updateCondition({ condition: filterStore.initialState.condition });
        break;

      default:
        const id = data.type === 'users' ? 'uuid' : 'hmsId';
        filterStore.sendMessage({
          [data.type]: filterState?.data[data.type].filter((item) => item[id] !== data[id])
        });
        break;
    }
  };

  const preparedFilters = () => {
    if (filterState?.siriusxm ) {
      return [
        {
          type: 'date',
          value: `${formattedDate(filterState?.sxmDates.start)} – ${formattedDate(
            filterState?.sxmDates.end
          )}`
        },
      ];
    } else {
    return [
      {
        type: 'date',
        value: `${formattedDate(filterState?.dates.start)} – ${formattedDate(
          filterState?.dates.end
        )}`
      },
        { type: 'condition', value: `${filterState.condition.name}` },
        ...filterState?.data.locations?.map((item) => ({
          ...item,
          type: 'locations',
          value: item.name
        })),
        ...filterState?.data.users?.map((item) => ({
          ...item,
          type: 'users',
          value: item.displayName
        })),
        ...filterState?.data.notes?.map((item) => ({
          ...item,
          type: 'notes',
          value: item.value
        }))
    ];
    }
  };

  useLayoutEffect(() => {
    const subs = filterStore.subscribe(setFilterState);
    filterStore.init();
    return () => subs.unsubscribe();
  }, []);

  return (
    <ChipsWrapper>
      {preparedFilters()?.map((data, index) => (
        <ChipItem
          key={index}
          label={data.value}
          onDelete={canBeDeleted(data) ? handleDelete(data) : undefined}
          deleteIcon={<CloseButton />}
        />
      ))}
    </ChipsWrapper>
  );
};

export default ReportsChips;
