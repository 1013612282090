import { SortDownIcon, SortUpIcon, SortWrap } from '../../../components/SortHeader';
import SearchHeader from '../../../components/SearchHeader';
import React, { useState, useEffect, useRef } from 'react';
import Scrollable from '../../../components/Scrollable';
import Button from '../../../components/Buttons/Button';
import BaseModal from '../../../components/BaseModal';
import Loader from '../../../components/Loader';
import { filteTorQuery } from '../context';
import doFetch from '../../../api/doFetch';
import Chips from './Chips';

import {
  ModalHeaderWrapper,
  SubmitAreaStyled,
  CameraIcon,
  CheckIcon,
  InfoBlock,
  Vehicle
} from './styledComponents';

export default function ({ isOpen, toggleHandler, filter: originFilter, images, ignore }) {
  const [filter, setFilter] = useState({ ...originFilter, vinFragment: '', pageNumber: 1 });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [sortDirection, setSortDirection] = useState('ASC');
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [selected, setSelected] = useState([]);
  const delay = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    doFetch(`/vehicles/reassign-photos`, {
      method: 'POST',
      body: JSON.stringify({ images, vins: selected })
    }).then(() => toggleHandler());
  };

  const onInputHandler = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    if (delay.current) clearTimeout(delay.current);
    delay.current = setTimeout(
      () => setFilter((filter) => ({ ...filter, vinFragment: value, pageNumber: 1 })),
      300
    );
  };

  const onClearHandler = () => {
    setSearchInput('');
    setFilter((filter) => ({ ...filter, vinFragment: '' }));
  };

  useEffect(() => {
    if (!filter) return;

    const query = `page=${filter.pageNumber}&limit=200${filteTorQuery(filter)}&condition=New&sort=uploadedImageCount__${sortDirection}`;
    setIsLoading(true);

    doFetch(`/vehicles?${query}`).then((result) => {
      setTotalItems(result?.meta?.totalItems || 0);
      const list = result.items
        .reduce(
          (result, dealer) => {
            result.push(...dealer.vehicles);
            return result;
          },
          filter?.pageNumber === 1 ? [] : vehicles
        )
        .filter((vehicle) => {
          if (!ignore) return true;
          return !ignore.includes(vehicle.vin);
        });
      setVehicles(list);

      setIsLoading(false);
      setIsLoadingMore(false);
    });
    // eslint-disable-next-line
  }, [filter, sortDirection]);

  return (
    <BaseModal
      isOpen={isOpen}
      toggleHandler={toggleHandler}
      header={<ModalHeaderWrapper>ASSIGN PHOTOS</ModalHeaderWrapper>}
    >
      <SearchHeader
        userInput={searchInput}
        placeholder='Search by VIN'
        onInputHandler={onInputHandler}
        onClearHandler={onClearHandler}
        style={{ marginTop: 1 }}
        className='full-width'
      />
      <Chips filter={originFilter} style={{ width: 350 }} className='full-width' />
      <InfoBlock>
        <div className='match'>{totalItems} matches</div>
        <div className='sort'>
          Photo amount
          <SortWrap onClick={() => setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')}>
            {sortDirection === 'ASC' ? <SortDownIcon /> : <SortUpIcon />}
          </SortWrap>
        </div>
      </InfoBlock>
      <Scrollable
        className='full-width'
        minHeight={`calc(100vh - 390px)`}
        isLoading={isLoading || isLoadingMore}
        bottomReached={() => {
          setIsLoadingMore(true);
          setFilter((filter) => ({ ...filter, pageNumber: filter.pageNumber + 1 }));
        }}
        style={{ margin: '0 -20px', width: 390 }}
      >
        {vehicles?.map((value, index) => (
          <Vehicle
            key={`vehicle-list-${index}`}
            onClick={() => {
              if (selected.includes(value.vin)) {
                setSelected((v) => [...v.filter((vin) => vin !== value.vin)]);
              } else {
                setSelected((v) => [...v, value.vin]);
              }
            }}
          >
            <div className='info'>
              <div className='top'>
                <div className='name'>
                  {value?.make || ''} {value?.model || ''}
                </div>
                <div className='value'>
                  <CameraIcon />
                  {value?.uploadedImageCount || 0}
                </div>
                <div className='select'>{selected.includes(value.vin) && <CheckIcon />}</div>
              </div>
              <div className='bottom'>
                <div className='stock'>{value?.stock || ''}</div>
                <div className='vin'>{value?.vin || ''}</div>
                <div className='color'>{value?.color || ''}</div>
              </div>
            </div>
          </Vehicle>
        ))}
      </Scrollable>
      <Loader isLoading={isLoading || isLoadingMore} firstLoad={true} style={{ marginTop: 42 }} />
      <form onSubmit={handleSubmit}>
        <SubmitAreaStyled>
          <Button onClick={toggleHandler} theme='white'>
            Cancel
          </Button>
          <Button type='submit' disabled={!selected?.length}>
            Assign
          </Button>
        </SubmitAreaStyled>
      </form>
    </BaseModal>
  );
}
