import React from 'react';
import styled from 'styled-components';

const ListItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;

  & > * {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &:hover {
    background-color: rgba(40, 56, 144, 0.05);
  }

  ${({ isChecked }) =>
    isChecked &&
    `
        justify-content: space-between;
        padding-right: 13px;
    `}
`;

const ItemNameStyled = styled.div`
  position: relative;
  min-width: 216px;
  font-family: NunitoSans;
  letter-spacing: -0.13px;
  color: #28283a;
  font-size: 14px;
  font-weight: 600;
`;

const ListItem = ({
  name,
  className,
  itemId,
  onClick,
  itemCss,
  children: Component,
  nameComponent: NameComponent
}) => {
  return (
    <ListItemWrapper className={className} data-id={itemId} onClick={onClick}>
      <ItemNameStyled css={itemCss}>
        {name}
        {NameComponent}
      </ItemNameStyled>
      {Component}
    </ListItemWrapper>
  );
};

const StyledListItem = styled(ListItem)``;
const StyledModalListItem = styled(StyledListItem)`
  padding-left: 20px;

  & > * {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const ManagerLabelStyled = styled.div`
  position: absolute;
  margin-top: 3px;
  opacity: 0.5;
  font-family: NunitoSans;
  font-size: 10px;
  font-weight: bold;
  color: #283890;
`;

export { StyledModalListItem, ManagerLabelStyled };
export default StyledListItem;
