import React, { useEffect, useState, useRef } from 'react';
import CloseButton from '../Buttons/CloseButton';
import styled, { css } from 'styled-components';

import COLORS from '../../COLORS';

const LabelStyled = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-family: NunitoSans;
  font-size: 12px;
  color: ${COLORS.label};
  font-weight: bold;
  width: 100%;
`;

const InputStyled = styled.input`
  ${(p) => css`
    width: 100%;
    height: 26px;
    margin-top: 8px;
    padding-left: 7px;
    padding-right: 20px;
    border-radius: 5px;
    border: solid 1px #f0f4fa;
    background-color: #fafaff;
    box-sizing: border-box;
    font-size: 12px;
    &::placeholder {
      color: #28283a;
      ${Boolean(p.disabled) &&
      css`
        color: #9595a8 !important;
        background-color: #fafaff !important;
      `}
    }
    border-radius: 5px !important;
    border-color: #f0f4fa !important;
    &:disabled {
      color: #9595a8 !important;
      background-color: #fafaff !important;
    }
  `}
`;

const InputWrap = styled.div`
  display: flex;
  position: relative;
`;

const LabelContentStyled = styled.span`
  ${({ required }) =>
    required &&
    `
    &::after {
        position: absolute;
        content: '*';
        color: red;
        top: 0;
        margin-left: 4px;
    }`}
`;

const ClearBtn = styled(CloseButton)`
  top: 16px;
  right: 6px;
  color: #677b8b;
  &:before,
  &:after {
    height: 14px;
  }
`;

export default function ({ label, value, onChange, ...props }) {
  const [localValue, setLocalValue] = useState(value || '');
  const timer = useRef();

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    if (value !== localValue) setLocalValue(value || '');
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => onChange(localValue), 300);

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
    // eslint-disable-next-line
  }, [localValue]);

  return (
    <LabelStyled>
      <LabelContentStyled>{label}</LabelContentStyled>
      <InputWrap>
        <InputStyled
          {...props}
          value={localValue}
          onChange={(e) => setLocalValue(e?.target?.value)}
        />
        {Boolean(localValue) && <ClearBtn onClick={() => onChange('')} />}
      </InputWrap>
    </LabelStyled>
  );
}
