import useSWR, { mutate } from 'swr';

import app from '../base';
import doFetch from './doFetch';

const useDealers = (condition, sortKey, sortDirection) => {
  const { data, error, mutate } = useSWR([
    `/dealerships?limit=99999&query=${condition}&sortKey=${sortKey}&sortDirection=${sortDirection}&showDeleted=true`,
    undefined,
    app.auth().currentUser.uid
  ]);

  return {
    dealers: data,
    isDealersLoading: !error && !data,
    isError: error,
    mutateDealers: mutate
  };
};

export const prefetchDealers = () =>
  mutate(
    [`/dealerships?limit=99999`, app.auth().currentUser.uid],
    async (dealers) => (dealers ? dealers : await doFetch(`/dealerships?limit=99999`)),
    false
  );

export default useDealers;
