import React, { createContext, useState } from 'react';

const ControlBarContext = createContext(null);
ControlBarContext.displayName = 'ControlBarContext';

const ControlBarProvider = ({ children }) => {
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [rightSide, setRightSide] = useState({ url: '', Component: null });

  const value = { setBreadCrumbs, breadCrumbs, rightSide, setRightSide };

  return <ControlBarContext.Provider value={value}>{children}</ControlBarContext.Provider>;
};

export { ControlBarContext, ControlBarProvider };
