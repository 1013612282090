import styled from "styled-components";
import { Link } from "react-router-dom";


const ItemLinkRelativeWrapper = styled.div`
    position: relative;
`;

const ItemLinkStyled = styled(Link)`
    text-decoration: none;
`;


export { ItemLinkRelativeWrapper };
export default ItemLinkStyled;
