import React, { createRef, useState } from 'react';
import styled from 'styled-components';

import { CalendarWrap, CIPopover, CIWrap } from '../../../components/Inputs/styles';
import { formattedDate } from '../../../utils/date';

const Label = styled.div`
  position: relative;
  padding-bottom: 15px;
  font-weight: bold;
  margin-top: -1px;
  font-size: 16px;
  color: #28283a;
  opacity: 0.5;
  align-self: flex-start;
  padding-right: 20px;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #28283a;
    border-right: 2px solid #28283a;
    transform: rotate(45deg);
    right: 5px;
    top: 6px;
  }
`;

const DateInput = ({ selectedDate, setSelectedDate, labelElement }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const calendarAnchor = createRef();

  const handleOpen = () => setAnchorEl(calendarAnchor.current);
  const handleClose = () => setAnchorEl(null);

  return (
    <CIWrap>
      {labelElement ? (
        <div ref={calendarAnchor} onClick={handleOpen}>
          {labelElement}
        </div>
      ) : (
        <Label ref={calendarAnchor} onClick={handleOpen}>
          {formattedDate(selectedDate)}
        </Label>
      )}
      <CIPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <CalendarWrap
          value={selectedDate}
          selectionType={'single'}
          maximumDate={new Date()}
          onSelect={(date) => {
            setSelectedDate(date);
            handleClose();
          }}
        />
      </CIPopover>
    </CIWrap>
  );
};

export default DateInput;
