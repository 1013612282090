import useSWR from 'swr';
import app from '../base';

const useSummary = (date) => {
  const { data, error, mutate } = useSWR([
    `/reports/users/summary?start=${date}&end=${date}`,
    undefined,
    app.auth().currentUser.uid
  ]);

  return {
    summary: data,
    isLoading: !error && !data,
    isError: error,
    mutateSummary: mutate
  };
};

export default useSummary;
