import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useParams, useRouteMatch } from 'react-router-dom';

import doFetch from '../api/doFetch';
import { formattedDate, formattedTime } from '../utils/date';
import ContentBoardStyled from '../components/ContentBoardStyled';
import Scrollable from '../components/Scrollable';
import { ControlBarContext } from './ControlBarContext';
import MediumButtonStyled from '../components/Buttons/MediumButtonStyled';
import useReportForDealerByUserByDay from '../api/useReportForDealerByUserByDay';

const TABLE_COLUMNS = [
  {
    id: 'daysInStock',
    name: 'DAYS IN STK'
  },
  {
    id: 'stock',
    name: 'STK#'
  },
  {
    id: 'vin',
    name: 'VIN'
  },
  {
    id: 'year',
    name: 'YEAR'
  },
  {
    id: 'makeModel',
    name: 'MAKE MODEL'
  },
  {
    id: 'time',
    name: 'TIME'
  },
  {
    id: 'result',
    name: 'RESULT'
  }
];

const ReportHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderLeftSide = styled.div``;

const HeaderRightSide = styled.div``;

const ReportTitle = styled.div`
  padding-top: 4px;
  font-family: NunitoSans;
  font-size: 20px;
  font-weight: bold;
  color: #283890;
`;

const ReportDate = styled.div`
  padding-top: 21px;
  padding-bottom: 15px;
  opacity: 0.5;
  font-family: NunitoSans;
  font-size: 16px;
  font-weight: bold;
  color: #28283a;
`;

const ContactItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const ContactLabelStyled = styled.div`
  text-align: right;
  flex-grow: 1;
  padding-right: 27px;
  font-family: NunitoSans;
  font-size: 10px;
  font-weight: bold;
  color: #677b8b;
`;

const ContactValueStyled = styled.div`
  min-width: 150px;
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: 600;
  color: #28283a;
`;

const CarTypeHeader = styled.div`
  padding-top: 25px;
  padding-bottom: 24px;
  font-family: NunitoSans;
  font-size: 16px;
  font-weight: bold;
  color: #28283a;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const TableHeader = styled.th`
  padding-bottom: 15px;
  font-family: NunitoSans;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #28283a;
`;

const TableHeaderRow = styled.tr``;

const TableCell = styled.td`
  border-top: 1px solid #edf2f9;
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #28283a;
`;

const TableRow = styled.tr`
  height: 50px;
`;

const Report = ({ getBreadCrumbs }) => {
  const { setBreadCrumbs, setRightSide } = useContext(ControlBarContext);
  const { hmsId, userId, reportDate } = useParams();
  const match = useRouteMatch();
  const isBilling = match.url.includes('billing');

  const { report } = useReportForDealerByUserByDay(hmsId, userId, reportDate, isBilling);

  const contacts = report && [
    {
      name: 'Phone',
      value: report.user.phone
    },
    {
      name: 'Area Manager',
      value: report.user.roleManagerName
    },
    {
      name: 'Manager phone number',
      value: report.user.roleManagerPhone
    }
  ];

  useEffect(() => {
    setRightSide({
      Component: (
        <MediumButtonStyled
          onClick={() => {
            doFetch(
              `/reports/dealerships/${hmsId}/users/${userId}/${reportDate}/download${
                isBilling ? `?notes=Full Shoot` : ''
              }`,
              {
                responseType: 'blob'
              }
            )
              .then((response) => response.blob())
              .then((response) => {
                const file = new Blob([response], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download = `Report - ${report.dealership.name} - ${report.user.displayName} ${report.day}.pdf`;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
                // window.open(fileURL);
              })
              .catch((error) => {
                alert(error);
              });
          }}
        >
          Download PDF
        </MediumButtonStyled>
      ),
      url: match.url
    });
  }, [setRightSide, match.url, hmsId, userId, reportDate, report, isBilling]);

  useEffect(() => {
    report &&
      report.user.displayName &&
      report.day &&
      report.dealership.name &&
      setBreadCrumbs(
        getBreadCrumbs({
          userName: report.user.displayName,
          date: formattedDate(report.day),
          dealerName: report.dealership.name,
          userId: report.user.uuid,
          hmsId
        })
      );
  }, [getBreadCrumbs, report, setBreadCrumbs, hmsId]);

  const formattedReports =
    report &&
    Array.isArray(report.reports) &&
    report.reports.map((report) => ({ ...report, time: formattedTime(report.time * 1000) }));

  const newCarsReports =
    Array.isArray(formattedReports) && formattedReports.filter((report) => report.type === 'New');
  const usedCarsReports =
    Array.isArray(formattedReports) && formattedReports.filter((report) => report.type === 'Used');

  const TABLES = [
    {
      name: 'New cars',
      reports: newCarsReports
    },
    {
      name: 'Used cars',
      reports: usedCarsReports
    }
  ];

  return (
    <ContentBoardStyled style={{ padding: '18px 22px' }}>
      <ReportHeader>
        <HeaderLeftSide>
          <ReportTitle>{report && report.user.displayName} Report</ReportTitle>
          <ReportDate>{report && formattedDate(report.day)}</ReportDate>
        </HeaderLeftSide>
        <HeaderRightSide>
          {contacts &&
            contacts
              .filter((contact) => !!contact.value)
              .map((contact, index) => (
                <ContactItemStyled key={contact.value + index}>
                  <ContactLabelStyled>{contact.name}</ContactLabelStyled>
                  <ContactValueStyled>{contact.value}</ContactValueStyled>
                </ContactItemStyled>
              ))}
        </HeaderRightSide>
      </ReportHeader>
      <Scrollable maxHeight={`calc(100vh - 97px - 50px - 18px - 89px)`}>
        {TABLES.map((table) => (
          <React.Fragment key={table.name}>
            {table.reports.length > 0 && (
              <>
                <CarTypeHeader>{table.name}</CarTypeHeader>
                <Table>
                  <thead>
                    <TableHeaderRow>
                      {TABLE_COLUMNS.map((column) => (
                        <TableHeader key={column.name}>{column.name}</TableHeader>
                      ))}
                    </TableHeaderRow>
                  </thead>
                  <tbody>
                    {table.reports.map((report, index) => (
                      <TableRow key={report.vin + report.time + index}>
                        {TABLE_COLUMNS.map((column) => (
                          <TableCell key={column.id + report.vin}>{report[column.id]}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </React.Fragment>
        ))}
      </Scrollable>
    </ContentBoardStyled>
  );
};

export default Report;
