import React from 'react';
import styled from 'styled-components';

import { sortUpIcon, sortDownIcon } from '../img';
import DropdownMenu from './DropdownMenu';

// const MENU_ID = 'sort';
export const SortUpIcon = styled.div`
  margin: 12px 0;
  background-image: url(${sortUpIcon});
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const SortDownIcon = styled.div`
  margin: 12px 0;
  background-image: url(${sortDownIcon});
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SortBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  flex: 0.5;
`;

export const SortWrap = styled.div`
  margin-right: 32px;
`;

const SortHeader = ({ sortKey, sortDirection, setSortKey, setSortDirection, items }) => {
  const data = { items: items ? items : [] };
  return (
    <SortBlock>
      <DropdownMenu
        single={1}
        data={data}
        keys={{ id: 'id', name: 'name' }}
        selectedItems={data.items.find((i) => i.id === sortKey)}
        setSelectedItems={(e) => setSortKey(e.id)}
      />
      <SortWrap onClick={() => setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')}>
        {sortDirection === 'ASC' ? <SortDownIcon /> : <SortUpIcon />}
      </SortWrap>
    </SortBlock>
  );
};

export default SortHeader;
