import styled from "styled-components";

import MediumButtonStyled from "./MediumButtonStyled";


const SmallButtonStyled = styled(MediumButtonStyled)`
    padding: 4px 14px;
    font-size: 10px;
    letter-spacing: -0.09px;
`;

export default SmallButtonStyled;
