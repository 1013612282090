import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ModalProvider } from 'styled-react-modal'
import SwrContext from './SwrProvider';

import './App.css';
import { AuthProvider } from './Auth';
import PrivateRoute from "./PrivateRoute";
import LoginPage from "./pages/Login/LoginPage";
import MainPage from "./pages/MainPage";


const App = () => (
    <AuthProvider>
        <SwrContext>
            <ModalProvider>
                <Router>
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>
                    <PrivateRoute exact path="/">
                        <Redirect to={'/users'} />
                        <MainPage />
                    </PrivateRoute>
                    <PrivateRoute path="/">
                        <MainPage />
                    </PrivateRoute>
                </Router>
            </ModalProvider>
        </SwrContext>
    </AuthProvider>
);

export default App;
