import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import BaseModal from "../../../components/BaseModal";
import Input from "../../../components/Input";
import Button from "../../../components/Buttons/Button";
import doFetch from "../../../api/doFetch";
import AddEmailIcon from '../../../img/add-email.svg'
import CloseButton from "../../../components/Buttons/CloseButton";
import { parseEmails } from "../../../utils/email";


const EMAILS_COUNT_LIMIT = 5;

const ModalHeaderWrapper = styled.div`
    display: flex;
    color: #283890;
`;

const SubmitAreaStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 32px;
`;

const DealerName = styled.div`
    margin-top: 14px;
    margin-bottom: 6px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    color: #283890;
`;

const AddEmailIconStyled = styled.div`
    width: 13px;
    height: 13px;
    background-image: url(${AddEmailIcon});
    background-repeat: no-repeat;
`;

const AddEmailTextStyled = styled.div`
    margin-left: 15px;
    font-size: 12px;
    font-weight: bold;
    color: #283890;
`;

const AddEmailWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 18px;
    margin-left: 12px;
    cursor: pointer;
`;

const InputWrapper = styled.div`
    position: relative;
`;

const EditEmailsModal = ({ isOpen, toggleHandler, dealer, updateDealer }) => {
    const [emails, setEmails] = useState(['']);

    useEffect(() => {
        dealer && setEmails(parseEmails(dealer.email));
    }, [dealer]);

    const addEmail = () => {
        setEmails([...emails, '']);
    }

    const onEmailInput = ({ index, event }) => {
        const emailsCopy = [...emails];
        emailsCopy[index] = event.target.value;
        setEmails(emailsCopy);
    }

    const clearInput = (index) => {
        const emailsCopy = [...emails];
        (emailsCopy.length > 1)
            ? emailsCopy.splice(index, 1)
            : emailsCopy[index] = '';
        setEmails(emailsCopy);
    };

    const handleSubmitUser = (event) => {
        event.preventDefault();
        
        const preparedEmails = emails.filter(emails => !!emails).join(', ');

        const data = {
            email: preparedEmails,
        }

        doFetch(`/dealerships/${dealer.hmsId}`, { method: 'PUT', body: JSON.stringify(data) })
            .then((dealer) => {
                toggleHandler();
                updateDealer(dealer);
            }).catch(error => alert(error));
    }

    return (
        <BaseModal
            isOpen={isOpen}
            toggleHandler={toggleHandler}
            header={<ModalHeaderWrapper>EDIT DEALERSHIP EMAIL</ModalHeaderWrapper>}
        >
            <DealerName>{ dealer && dealer.name }</DealerName>
            <form onSubmit={handleSubmitUser}>
                {
                    emails.map((email, index) => (
                        <InputWrapper key={`emails${index}`}>
                            <Input
                                name={`email${index}`}
                                type="email"
                                value={email}
                                onChange={(event) => { onEmailInput({ index, event }) }}
                                label="Email address"
                                noLabel={index !== 0}
                                css={{ marginBottom: '8px', paddingRight: '29px' }}
                            />
                            {
                                email && (
                                    <CloseButton
                                        css={{ bottom: '20px', right: '11px', top: 'auto' }}
                                        onClick={() => clearInput(index)}
                                    />
                                )
                            }
                        </InputWrapper>
                    ))
                }
                {
                    (emails.length < EMAILS_COUNT_LIMIT) && (
                        <AddEmailWrapper onClick={addEmail}>
                            <AddEmailIconStyled />
                            <AddEmailTextStyled>Add email address</AddEmailTextStyled>
                        </AddEmailWrapper>
                    )
                }
                <SubmitAreaStyled>
                    <Button type="submit">Save</Button>
                </SubmitAreaStyled>
            </form>
        </BaseModal>
    );
}

export default EditEmailsModal;
