import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import {
  LogoImg,
  usersImg,
  dealersImg,
  usersActiveImg,
  dealersActiveImg,
  reportsIcon,
  reportsActiveIcon,
  analyticsIcon,
  analyticsActiveIcon,
  vehicleListIcon,
  vehicleListIconActive,
  dailyActivity,
  dailyActivityActive,
  billing,
  billingActive,
  filteringActiveIcon,
  filteringIcon
} from '../img';
import LogOut from './LogOut';
import { prefetchDealers } from '../api/useDealers';

const CLEAR_IMG = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 239px;
  height: 100vh;
  border-right: 2px solid #edf2f9;
`;

const LogoImgStyled = styled.img`
  width: 60px;
  height: 64px;
  margin-top: 18px;
  margin-left: 26px;
  margin-bottom: 35px;
`;

const MenuList = styled.ul`
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const IconStyled = styled.img`
  width: 16px;
  height: 16px;
  padding-left: 47px;
`;

const LinkWrapperStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 217px;
  height: 36px;
  border-radius: 100px;
  margin-bottom: 20px;
  margin-left: -15px;
  font-family: NunitoSans;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  color: rgba(40, 40, 58, 0.5);
  outline: none;

  &:hover {
    background-color: rgba(40, 56, 144, 0.05);
  }

  ${IconStyled} {
    margin-right: 17px;
  }
`;

const activeNavLinkStyle = {
  backgroundColor: 'rgba(40, 56, 144, 0.05)',
  color: '#283890'
};

const MENU_ITEMS = [
  {
    label: 'Manage users',
    link: '/users',
    icon: usersImg,
    activeIcon: usersActiveImg
  },
  {
    label: 'Dealership list',
    link: '/dealers',
    icon: dealersImg,
    activeIcon: dealersActiveImg,
    prefetch: prefetchDealers
  },
  {
    label: 'Reports',
    link: '/reports',
    icon: reportsIcon,
    activeIcon: reportsActiveIcon
  },
  {
    label: 'Vehicle list',
    link: '/vehicles',
    icon: vehicleListIcon,
    activeIcon: vehicleListIconActive
  },
  {
    label: 'Daily Activity',
    link: '/activity',
    icon: dailyActivity,
    activeIcon: dailyActivityActive
  },
  {
    label: 'Billing',
    link: '/billing',
    icon: billing,
    activeIcon: billingActive
  },
  {
    label: 'Analytics',
    link: '/analytics',
    icon: analyticsIcon,
    activeIcon: analyticsActiveIcon
  },
  {
    label: 'Photo filtering',
    link: '/photo-filtering',
    icon: filteringIcon,
    activeIcon: filteringActiveIcon
  }
];

const Menu = ({ location }) => {
  const currentCategoryUrl = `/${location.pathname.split('/')?.[1]}`;

  return (
    <MenuWrapper>
      <LogoImgStyled src={LogoImg} />
      <MenuList>
        {MENU_ITEMS.map(({ label, link, icon, activeIcon, prefetch }) => {
          const isActive = currentCategoryUrl === link;
          const currentIcon = isActive ? activeIcon : icon;

          return (
            <li key={link} onMouseEnter={prefetch}>
              <LinkWrapperStyled to={link} activeStyle={activeNavLinkStyle}>
                {<IconStyled src={currentIcon || CLEAR_IMG} />}
                {label}
              </LinkWrapperStyled>
            </li>
          );
        })}
      </MenuList>
      <LogOut />
    </MenuWrapper>
  );
};

export default withRouter(Menu);
