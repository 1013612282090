import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import doFetch from '../../api/doFetch';
import useDealer from '../../api/useDealer';
import useVehicle from '../../api/useVehicle';
import { arrayMove } from '../../utils/arrayMove';
import { ControlBarContext } from '../ControlBarContext';
import VehiclePhotos from './components/vehicles/VehiclePhotos';
import VehicleInfoBoard from './components/vehicles/VehicleInfoBoard';
import VehicleAddPhotoModal from './components/vehicles/VehicleAddPhotoModal';

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 482px;
  margin: 25px 25px 0 0;
`;

const VehicleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

let lastVehicleRank = null;

const DealerVehicle = ({ getBreadCrumbs }) => {
  const { setBreadCrumbs } = useContext(ControlBarContext);
  const { hmsId, vin } = useParams();
  const { dealer } = useDealer(hmsId);
  const { vehicle, mutateVehicle } = useVehicle(hmsId, vin);
  const [vehicleImages, setVehicleImages] = useState([]);
  const [isUploadPhotosOpen, setIsUploadPhotosOpen] = useState(false);

  const toggleUploadModal = () => setIsUploadPhotosOpen(!isUploadPhotosOpen);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const temp = arrayMove(vehicleImages, oldIndex, newIndex).map((img, index) => ({
      ...img,
      rank: index
    }));
    setVehicleImages(temp);
    updatePhotosSort(temp);
  };

  const updatePhotosSort = (data) => {
    doFetch(`/vehicles/dealership/${hmsId}/vehicle/${vin}/images`, {
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then(() => {})
      .catch((error) => console.log(error));
  };

  const uploadImage = (img, id) => {
    const body = new FormData();
    body.append('image', img, img.name);
    return doFetch(
      `/vehicles/dealership/${hmsId}/vehicle/${vin}/images/${id}`,
      {
        method: 'POST',
        body
      },
      {}
    );
  };

  const prepareS3ForUpload = (imagesList) => {
    return doFetch(`/vehicles/dealership/${hmsId}/vehicle/${vin}/images`, {
      method: 'POST',
      body: JSON.stringify(imagesList)
    });
  };

  const handleUploadImages = (images) => {
    prepareS3ForUpload(images.map((img, i) => ({ name: img.name, rank: lastVehicleRank + i })))
      .then((imagesList) => {
        Promise.all(
          imagesList
            .filter((img) => !img?.url)
            .map((image, index) => uploadImage(images[index], image.id))
        ).then((image) => {
          setVehicleImages(vehicleImages.concat(image));
          setIsUploadPhotosOpen(false);
        });
      })
      .catch((error) => console.log(error));
  };

  const handleDeletePhotos = (items) => {
    doFetch(`/vehicles/dealership/${hmsId}/vehicle/${vin}/images`, {
      method: 'DELETE',
      body: JSON.stringify({ ids: items.map((item) => item.id) })
    })
      .then(() => mutateVehicle())
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    lastVehicleRank = vehicleImages?.length;
  }, [vehicleImages.length]);

  useEffect(() => {
    dealer && vehicle && setBreadCrumbs(getBreadCrumbs({ dealer, stock: vehicle?.stock }));
    vehicle?.images &&
      setVehicleImages(
        vehicle.images
          // .filter((img) => img.url)
          .sort((a, b) => a.rank - b.rank)
      );
  }, [getBreadCrumbs, setBreadCrumbs, vehicle, dealer]);

  return (
    <>
      <VehicleWrapper>
        <VehiclePhotos
          items={vehicleImages}
          onSortEnd={onSortEnd}
          openPhotoUploadModal={toggleUploadModal}
          editable={vehicle && vehicle.imageType === 'HomeNet'}
          handleDeletePhotos={handleDeletePhotos}
          vehicle={vehicle}
        />
        <RightSide>
          <VehicleInfoBoard vehicle={vehicle} />
        </RightSide>
      </VehicleWrapper>
      <VehicleAddPhotoModal
        isOpen={isUploadPhotosOpen}
        toggleHandler={toggleUploadModal}
        uploadImages={handleUploadImages}
      />
    </>
  );
};

export default DealerVehicle;
