import { Fade, Popover, withStyles } from '@material-ui/core';
import React, { createRef, useState } from 'react';
import styled from 'styled-components';

import COLORS from '../../COLORS';
import { checkedIcon } from '../../img';

const CIWrap = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-size: 12px;
  color: ${COLORS.label};
  font-weight: bold;
  width: 100%;
`;

const CIInput = styled.div`
  flex: 1;
  height: 26px;
  min-height: 26px;
  margin-top: 8px;
  padding: 7px;
  border-radius: 5px;
  border: solid 1px #f0f4fa;
  background-color: #fafaff;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  color: #28283a;

  &:first-child {
    margin-right: 6px;
  }

  ${({ highlited }) =>
    highlited &&
    `
        border: solid 1px #283890;
    `}
`;

const CIBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #28283a;
  padding-left: 12px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  user-select: none;

  &:hover {
    background: #eaebf4;
  }

  &:not(:nth-child(7n + 7)) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      right: 0;
      bottom: 0;
      left: 12px;
      background: #eaebf4;
    }
  }

  ${(props) =>
    props.selected &&
    `&::after {
      display: block;
      position: absolute;
      content: '';
      width: 18px;
      height: 18px;
      background-image: url(${checkedIcon});
      background-repeat: no-repeat;
      background-size: cover;
      right: 6px;
    }`}
`;

const CIPopover = withStyles({
  paper: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(3, 104px)',
    gridTemplateRows: 'repeat(7, 30px)',
    overflow: 'hidden',
    borderRadius: '7px',
    boxShadow: '0 14px 17px 0 rgba(16, 32, 45, 0.33)'
  }
})((props) => <Popover TransitionComponent={Fade} {...props} />);

const CountInput = ({ label, selectedCount, setSelectedCount, dataSet, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverAnchor = createRef();

  const handleOpen = () => setAnchorEl(popoverAnchor.current);
  const handleClose = () => setAnchorEl(null);

  return (
    <CIWrap>
      <span>{label}</span>
      {Boolean(disabled) ? (
        <CIInput ref={popoverAnchor}></CIInput>
      ) : (
        <CIInput ref={popoverAnchor} onClick={handleOpen}>
          {selectedCount?.name}
        </CIInput>
      )}
      <CIPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {dataSet.map((item, index) => (
          <CIBox
            key={index}
            selected={item.name === selectedCount?.name}
            onClick={() => setSelectedCount(item)}
          >
            {item.name}
          </CIBox>
        ))}
      </CIPopover>
    </CIWrap>
  );
};

export default CountInput;
