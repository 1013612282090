import React, { useEffect, useState } from 'react';

import app from './base';
import SplashScreen from "./components/SplashScreen";


const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';
export { AuthContext };

const getIdToken = (currentUser) => (
    new Promise((resolve, reject) => {
        currentUser.getIdToken()
            .then(token => {
                resolve(token)
            })
            .catch(err => reject(err));
    })
);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        app.auth().onIdTokenChanged((user) => {
            const currentUser = app.auth().currentUser;
            if (currentUser) {
                getIdToken(currentUser)
                    .then((token) => {
                        // setCurrentUser({...currentUser, token})

                        return (
                            fetch(`${process.env.REACT_APP_API_HOST}/users/${user.uid}`, {
                                mode: 'cors',
                                credentials: 'include',
                                withCredentials: true,
                                headers: {
                                    Authorization: token,
                                    'Content-Type': 'application/json',
                                },
                            })
                                .then(data => data.json())
                                .then(data => {
                                    setCurrentUser({...currentUser, token, ...data});
                                    setPending(false);
                                })
                                .catch(() => {
                                    alert('Access denied');
                                })
                        );
                    })

            } else {
                setPending(false);
                setCurrentUser(user);
            }
        }); 
    }, []);

    if (pending) {
        return <SplashScreen />
    }
    
    return (
        <AuthContext.Provider value={{ currentUser }}>
            { children }
        </AuthContext.Provider>
    );
};
