import React from 'react';
import styled from 'styled-components';

import BaseModal from "../../../components/BaseModal";
import Input from "../../../components/Input";
import PasswordInput from "../../../components/PasswordInput";
import Button from "../../../components/Buttons/Button";
import doFetch from "../../../api/doFetch";


const ModalHeaderWrapper = styled.div`
    display: flex;
    color: #283890;
`;

const SubmitAreaStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 32px;
`;

const EditUserModal = ({ isOpen, toggleHandler, user, mutateUser }) => {
    const handleSubmitUser = (event) => {
        event.preventDefault();
        const { username, email, password, phoneNumber, areaManager, managerPhone } = event.target.elements;

        const data = {
            displayName: username.value,
            email: email.value,
            phone: phoneNumber.value,
            manager: {
                displayName: areaManager.value,
                phone: managerPhone.value,
            },
        };

        if (password.value !== '') {
            data.password = password.value;
        } 

        doFetch(`/users/${user.uuid}`, { method: 'PUT', body: JSON.stringify(data) })
            .then((user) => {
                mutateUser(user, false);
                toggleHandler();
            }).catch(error => alert(error));
    }

    return (
        <BaseModal
            isOpen={isOpen}
            toggleHandler={toggleHandler}
            header={<ModalHeaderWrapper>EDIT USER INFORMATION</ModalHeaderWrapper>}
        >
            <form onSubmit={handleSubmitUser}>
                <Input label={'Username'} name={'username'} defaultValue={user?.displayName} />
                <Input label={'Email'} type="email" name={'email'} defaultValue={user?.email} />
                <PasswordInput label={'Password'} />
                <Input label={'Phone number'} name={'phoneNumber'} defaultValue={user?.phone} />
                <Input label={'Area manager'} name={'areaManager'} defaultValue={user?.roleManagerName} />
                <Input label={'Manager phone number'} name={'managerPhone'} defaultValue={user?.roleManagerPhone} />
                <SubmitAreaStyled>
                    <Button type="submit">Update</Button>
                </SubmitAreaStyled>
            </form>
        </BaseModal>
    );
}

export default EditUserModal;
