import React, { useState } from 'react';
import styled from 'styled-components';

import ContentBoardStyled from "../../../components/ContentBoardStyled";
import StyledBoardHeader from "../../../components/StyledBoardHeader";
import UserInfo from "./UserInfo";
import EditUserModal from "./EditUserModal";
import EditManagerModal from "./EditManagerModal";
import { getIsManager } from "../../../utils/users";
import useUser from "../../../api/useUser";


const UserInfoBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 340px;
    padding: 12px 20px 4px;
`;

const LABELS = {
    displayName: 'Username',
    email: 'Email',
    phone: 'Phone number',
    roleManagerName: 'Area Manager',
    roleManagerPhone: 'Manager Phone Number',
};

const UserInfoBoard = ({ userId }) => {
    const { user, mutateUser } = useUser(userId);
    const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
    
    const toggleModal = () => setIsOpenEditUserModal(!isOpenEditUserModal);
    
    const isManager = user && getIsManager(user.role);
    const EditModalComponent = isManager ? EditManagerModal : EditUserModal;

    return (
        <ContentBoardStyled css={{
            minHeight: isManager ? '134px' : '194px',
            marginLeft: 0,
            marginTop: 0,
            width: '482px'
        }}>
            <StyledBoardHeader button={{ name: 'Edit', handler: toggleModal }}>USER INFORMATION</StyledBoardHeader>
            <UserInfoBlock>
                {
                    user && Object.keys(LABELS).filter((key) => !!user[key]).map((key) => (
                        <UserInfo label={LABELS[key]} data={user[key]} key={key} />
                    ))
                }
                <EditModalComponent
                    isOpen={isOpenEditUserModal}
                    toggleHandler={toggleModal}
                    user={user}
                    mutateUser={mutateUser}
                />
            </UserInfoBlock>
        </ContentBoardStyled>
    );
};

export default UserInfoBoard;
