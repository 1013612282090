import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import PasswordInput from '../../../components/PasswordInput';
import Button from '../../../components/Buttons/Button';
import BaseModal from '../../../components/BaseModal';
import Input from '../../../components/Input';
import doFetch from '../../../api/doFetch';
import { AuthContext } from '../../../Auth';
import ErrorModal from '../../../components/ErrorModal';

const TABS = [{ name: 'CREATE USER' }, { name: 'MANAGER' }];

const TabStyled = styled.div`
  margin-right: 40px;
  height: 32px;
  box-sizing: border-box;
  color: rgba(40, 40, 58, 0.5);
  ${({ isActive }) => isActive && `border-bottom: 1px solid #283890; color: #283890;`}
  cursor: pointer;
`;

const ModalHeaderWrapper = styled.div`
  display: flex;
`;

const SubmitAreaStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
`;

const Header = ({ activeTab, setActiveTab, isAdmin }) => (
  <ModalHeaderWrapper>
    {(isAdmin ? TABS : TABS.slice(0, 1)).map(({ name }) => {
      const isActive = name === activeTab;
      return (
        <TabStyled
          key={name}
          isActive={isActive}
          onClick={isActive ? () => {} : () => setActiveTab(name)}
        >
          {name}
        </TabStyled>
      );
    })}
  </ModalHeaderWrapper>
);

const createUser = (data) =>
  doFetch('/users/create', { method: 'POST', body: JSON.stringify(data) });

const CreateUserModal = ({ isOpen, toggleHandler, updateUsers }) => {
  const { register, handleSubmit, formState, reset } = useForm({ mode: 'onChange' });
  const [currentTab, setCurrentTab] = useState(TABS[0].name);
  const { currentUser } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage]=useState(null);

  const isAdmin = currentUser.role === 'admin';
  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [setIsModalOpen, isModalOpen]);

  const onFormSubmit = ({ displayName, email, password, phone, areaManager, managerPhoneNumber }) =>
    createUser({
      displayName,
      email,
      password,
      dealershipIds: [],
      ...(currentTab === TABS[0].name
        ? {
            role: 'user',
            phone,
            manager: {
              displayName: areaManager,
              phone: managerPhoneNumber
            }
          }
        : { role: 'manager' })
    })
      .then(({ statusCode, response }) => {
        if (statusCode >= 400) {
          throw new Error(response.message);
        }
        setErrorMessage(null);
        updateUsers();
        toggleHandler();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        toggleModal();
      });
  useEffect(reset, [currentTab]);

  return (
    <BaseModal
      isOpen={isOpen}
      toggleHandler={toggleHandler}
      header={<Header activeTab={currentTab} setActiveTab={setCurrentTab} isAdmin={isAdmin} />}
    >
      <ErrorModal
        isOpen={isModalOpen}
        toggleHandler={toggleModal}
        errorMessage={errorMessage}
      />
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Input
          label={'Username'}
          name={'displayName'}
          placeholder={'Username'}
          required
          ref={register({ required: true })}
        />
        <Input
          label={'Email'}
          type='email'
          name={'email'}
          placeholder={'example@email.com'}
          required
          ref={register({ required: true })}
        />
        <PasswordInput
          label={'Password'}
          required
          ref={register({ required: true, minLength: 6 })}
        />
        {currentTab === TABS[0].name && (
          <>
            <Input
              ref={register}
              label={'Phone number'}
              name={'phone'}
              placeholder={'123-456-789'}
            />
            <Input
              ref={register}
              label={'Area manager'}
              name={'areaManager'}
              placeholder={'Area manager name'}
            />
            <Input
              ref={register}
              label={'Manager phone number'}
              name={'managerPhoneNumber'}
              placeholder={'123-456-789'}
            />
          </>
        )}
        <SubmitAreaStyled>
          <Button disabled={!formState.isValid} type='submit'>
            Create
          </Button>
        </SubmitAreaStyled>
      </form>
    </BaseModal>
  );
};

export default CreateUserModal;
