import React from 'react';
import styled from 'styled-components';

import BaseModal from '../../../components/BaseModal';
import Input from '../../../components/Input';
import Button from '../../../components/Buttons/Button';
import doFetch from '../../../api/doFetch';

const ModalHeaderWrapper = styled.div`
  display: flex;
  color: #283890;
`;

const SubmitAreaStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
`;

const DealerName = styled.div`
  margin-top: 14px;
  margin-bottom: 6px;
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: bold;
  color: #283890;
`;

const EditPricesModal = ({ isOpen, dealer, labels, toggleHandler, updateDealer }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = Object.keys(labels).reduce(
      (o, key) => Object.assign(o, { [key]: event.target.elements[key].value }),
      {}
    );

    doFetch(`/dealerships/${dealer.hmsId}/price`, { method: 'PUT', body: JSON.stringify(data) })
      .then((dealer) => {
        toggleHandler();
        updateDealer(dealer);
      })
      .catch((error) => alert(error));
  };

  return (
    <BaseModal
      isOpen={isOpen}
      toggleHandler={toggleHandler}
      header={<ModalHeaderWrapper>EDIT PRICE PER SERVICE</ModalHeaderWrapper>}
    >
      <DealerName>{dealer && dealer.name}</DealerName>
      <form onSubmit={handleSubmit}>
        {dealer &&
          labels &&
          Object.keys(labels).map((key) => (
            <Input
              type='number'
              prefix='$'
              min='0'
              step='.01'
              key={key}
              defaultValue={dealer[key]}
              label={labels[key]}
              name={key}
            />
          ))}

        <SubmitAreaStyled>
          <Button type='submit'>Save</Button>
        </SubmitAreaStyled>
      </form>
    </BaseModal>
  );
};

export default EditPricesModal;
