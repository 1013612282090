import useSWR from 'swr';

import app from '../base';

const useVehilcleMakes = () => {
  const { data, error, mutate } = useSWR([
    `/vehicles/makes`,
    undefined,
    app.auth().currentUser.uid
  ]);

  return {
    makes: data,
    isLoading: !error && !data,
    isError: error,
    mutateMakes: mutate
  };
};

export default useVehilcleMakes;
