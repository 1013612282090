import React from 'react';
import styled from 'styled-components';

import COLORS from '../COLORS';

const LabelStyled = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    font-family: NunitoSans;
    font-size: 12px;
    color: ${COLORS.label};
    font-weight: bold;
`;

const InputStyled = styled.textarea`
    width: 356px;
    resize: none;
    margin-top: 8px;
    padding: 13px;
    border-radius: 5px;
    border: solid 1px #f0f4fa;
    background-color: #fafaff;
    box-sizing: border-box;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: 600;
`;

const InputWrap = styled.div`
    display: flex;
    position: relative;
`;

const LabelContentStyled = styled.span`
    ${({ required }) =>
        required &&
        `
    &::after {
        position: absolute;
        content: '*';
        color: red;
        top: 0;
        margin-left: 4px;
    }`}
`;

const TextArea = ({ label, noLabel, required, ...inputProps }) => {
    return noLabel ? (
        <InputStyled {...inputProps} />
    ) : (
        <LabelStyled>
            <LabelContentStyled required={required}>{label}</LabelContentStyled>
            <InputWrap>
                <InputStyled {...inputProps} />
            </InputWrap>
        </LabelStyled>
    );
};

export default TextArea;
