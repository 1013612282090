import useSWR from 'swr';
import app from '../base';

const useVehicle = (hmsId, vin) => {
  const { data, error, mutate } = useSWR([
    `/vehicles/dealership/${hmsId}/vehicle/${vin}`,
    undefined,
    app.auth().currentUser.uid
  ]);

  return {
    vehicle: data,
    isLoading: !error && !data,
    isError: error,
    mutateVehicle: mutate
  };
};

export default useVehicle;
