import app from '../base';

const defaultOptions = {
  mode: 'cors',
  credentials: 'include',
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' }
};

const doFetch = async (path, options, headers) => {
  const token = await app.auth().currentUser.getIdToken();

  return fetch(`${process.env.REACT_APP_API_HOST}${path}`, {
    ...defaultOptions,
    headers: {
      Authorization: token,
      ...(typeof headers === 'object' ? headers : defaultOptions.headers)
    },
    ...options
  })
    .then(response => {
      const contentType = response.headers.get('content-type');

      if (!!contentType && contentType.includes('application/json')) {
        return response.json();
      }

      return response;
    })
    .catch(error => {
      console.log(error);
    });
};
export default doFetch;
