import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import useDealers from '../../api/useDealers';
import { useInfo, useRefresh } from '../../context';

import LoadIcon from '../../components/LoadIcons';
import { formattedAgoDate } from '../../utils/date';
import Scrollable from '../../components/Scrollable';
import { Link, useRouteMatch } from 'react-router-dom';
import SearchHeader from '../../components/SearchHeader';
import { ControlBarContext } from '../ControlBarContext';
import NoItemsInList from '../../components/NoItemsInList';
import { editIcon, vehicleListIconActive } from '../../img';
import StyledListItem from '../../components/StyledListItem';
import CloseButton from '../../components/Buttons/CloseButton';
import filterStore, { startRange } from './services/DealerService';
import EditEmailsModal from '../Dealer/components/EditEmailsModal';
import ContentBoardStyled from '../../components/ContentBoardStyled';
import SmallButtonStyled from '../../components/Buttons/SmallButtonStyled';
import { EmailsStyled, NoEmailStyled } from '../../components/EmailStyled';
import MediumButtonStyled from '../../components/Buttons/MediumButtonStyled';
import ItemLinkStyled, { ItemLinkRelativeWrapper } from '../../components/ItemLinkStyled';

const EmailsWrapper = styled.section`
  position: absolute;
  top: 0;
  right: 30px;
  display: flex;
  align-items: center;
  width: 38%;
  height: 79px;
`;

const EditEmailIcon = styled.div`
  margin-left: 14px;
  background-image: url(${editIcon});
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const VehicleListIcon = styled(Link)`
  text-decoration: none;
  margin-left: 8px;
  background: rgba(39, 55, 144, 0.09) no-repeat center url(${vehicleListIconActive});
  width: 24px;
  border-radius: 17.5px;
  height: 24px;
  cursor: pointer;
`;

const DetailsStyled = styled.div`
  font-family: NunitoSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: normal;
  color: #28283a;
`;

const StatusBlock = styled.div`
  margin-top: -24px;
  display: flex;
  align-items: center;
  color: rgba(40, 40, 58, 0.7);
  font-weight: 600;
  font-size: 14px;
  & > div {
    margin-left: 24px;
  }
`;

const SuccessMessage = styled.div`
  position: absolute;
  top: 26px;
  left: calc(50% - 133px);
  color: rgba(40, 40, 58, 0.7);
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
  border-radius: 8px;
  box-shadow: rgba(16, 32, 45, 0.13) 0px 1px 6px 0px;
  background: white;
  display: flex;

  & > div {
    position: sticky;
    margin-top: 5px;
    margin-left: 24px;
  }
`;

const StatusInfo = () => {
  const timer = useRef();
  const upgrade = useRef(false);
  const refreshFn = useRefresh();
  const dealerUpdateInfo = useInfo();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (dealerUpdateInfo) {
      if (dealerUpdateInfo.hmsIds.includes('global')) {
        upgrade.current = true;
      } else if (upgrade.current) {
        upgrade.current = false;
        setShowSuccess(true);
        timer.current = setTimeout(() => setShowSuccess(false), 30000);
      }
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [dealerUpdateInfo]);

  return (
    <StatusBlock>
      {(dealerUpdateInfo?.status === 'DONE' || !dealerUpdateInfo.hmsIds.includes('global')) && (
        <>Last refreshed: {moment(dealerUpdateInfo.date).format('MM/DD/YYYY h:mma')}</>
      )}
      <MediumButtonStyled
        onClick={() => refreshFn()}
        disabled={dealerUpdateInfo?.status !== 'DONE' && dealerUpdateInfo.hmsIds.includes('global')}
      >
        {dealerUpdateInfo?.status !== 'DONE' && dealerUpdateInfo.hmsIds.includes('global') && (
          <LoadIcon disabled={true} />
        )}
        Master reset
      </MediumButtonStyled>
      {Boolean(showSuccess) && (
        <SuccessMessage>
          The master reset is complete
          <CloseButton onClick={() => setShowSuccess(false)} />
        </SuccessMessage>
      )}
    </StatusBlock>
  );
};

const DealershipListPage = ({ getBreadCrumbs }) => {
  const match = useRouteMatch();
  const refreshFn = useRefresh();
  const dealerUpdateInfo = useInfo();
  const [dealerForModal, setDealerForModal] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterState] = useState(filterStore.initialState);
  const [selectedDates, setSelectedDates] = useState(filterState.dates);
  const [searchInput, setSearchInput] = useState(filterState.search);
  const [datesFilterActive, setDatesFilterActive] = useState(filterState.datesFilter);
  const [sortKey, setSortKey] = useState(filterState.sortKey);
  const [sortDirection, setSortDirection] = useState(filterState.sortDirection);
  const { setBreadCrumbs, setRightSide } = useContext(ControlBarContext);
  const { dealers, mutateDealers } = useDealers(
    searchInput,
    selectedDates,
    datesFilterActive,
    sortKey,
    sortDirection
  );

  const onInputHandler = (event) => setSearchInput(event.target.value);
  const onClearHandler = () => setSearchInput('');
  const onCalendarHandler = (dates) => {
    setSelectedDates(dates);
    setDatesFilterActive(true);
  };
  const onResetCalendarHendler = () => {
    setSelectedDates(startRange);
    setDatesFilterActive(false);
  };

  useEffect(() => setBreadCrumbs(getBreadCrumbs()), [getBreadCrumbs, setBreadCrumbs]);

  const toggleModal = (dealer) => {
    setDealerForModal(dealer);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setRightSide({
      Component: <StatusInfo />,
      url: match.url
    });
    // eslint-disable-next-line
  }, [setRightSide, match.url, dealerUpdateInfo]);

  return (
    <ContentBoardStyled isWithLinkRelativeWrapper>
      <SearchHeader
        userInput={searchInput}
        placeholder='Search by name'
        onInputHandler={onInputHandler}
        onClearHandler={onClearHandler}
        calendarDates={selectedDates}
        setCalendarDates={onCalendarHandler}
        resetCalendar={onResetCalendarHendler}
        filterIsActive={datesFilterActive}
        sortItems={[
          { id: 'Name', name: 'Name' },
          { id: 'BillingDate', name: 'Last billing date' }
        ]}
        sortKey={sortKey}
        setSortKey={setSortKey}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <Scrollable maxHeight={`calc(100vh - 200px)`}>
        <EditEmailsModal
          isOpen={isModalOpen}
          dealer={dealerForModal}
          toggleHandler={toggleModal}
          updateDealer={() => mutateDealers()}
        />
        {dealers && dealers?.items && dealers.items?.length ? (
          dealers.items.map((dealer) => (
            <ItemLinkRelativeWrapper key={dealer.hmsId}>
              <ItemLinkStyled to={`/dealers/reports/${dealer.hmsId}`}>
                <StyledListItem name={dealer.name} itemCss={{ width: '44%' }}>
                  <DetailsStyled>
                    {dealer.lastBillingDate ? formattedAgoDate(dealer.lastBillingDate) : '-'}
                  </DetailsStyled>
                </StyledListItem>
              </ItemLinkStyled>
              <EmailsWrapper>
                {dealer.email ? (
                  <EmailsStyled css={{ width: 'calc(100% - 72px)' }}>{dealer.email}</EmailsStyled>
                ) : (
                  <NoEmailStyled css={{ width: 'calc(100% - 72px)' }}>
                    no email address
                  </NoEmailStyled>
                )}
                <EditEmailIcon onClick={() => toggleModal(dealer)} />
                <VehicleListIcon to={`/dealers/${dealer.hmsId}/vehicles`} />
                <SmallButtonStyled
                  onClick={() => refreshFn(dealer.hmsId)}
                  disabled={
                    dealerUpdateInfo?.status !== 'DONE' &&
                    dealerUpdateInfo.hmsIds.includes(String(dealer.hmsId))
                  }
                  style={{ marginLeft: 8 }}
                >
                  <LoadIcon
                    disabled={
                      dealerUpdateInfo?.status !== 'DONE' &&
                      dealerUpdateInfo.hmsIds.includes(String(dealer.hmsId))
                    }
                  />
                  {dealerUpdateInfo?.status !== 'DONE' &&
                  dealerUpdateInfo.hmsIds.includes(String(dealer.hmsId))
                    ? 'Updating'
                    : 'Refresh'}
                </SmallButtonStyled>
              </EmailsWrapper>
            </ItemLinkRelativeWrapper>
          ))
        ) : (
          <NoItemsInList>
            {searchInput ? 'No dealership found' : 'No dealership to show'}
          </NoItemsInList>
        )}
      </Scrollable>
    </ContentBoardStyled>
  );
};

export default DealershipListPage;
