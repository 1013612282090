import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import doFetch from '../../../api/doFetch';

import Scrollable from '../../../components/Scrollable';
import { ModalContentBoardStyled } from '../../../components/ContentBoardStyled';
import { StyledModalListItem } from '../../../components/StyledListItem';
import SearchHeader from '../../../components/SearchHeader';

import { checkedIcon } from '../../../img';
import useDealers from '../../../api/useDealers';
import NoItemsInList from '../../../components/NoItemsInList';

const CheckedStyled = styled.div`
  display: flex;
  padding: 0;
  margin-left: auto;
  margin-right: -19px;
  width: 25px;
  height: 25px;
  background-image: url(${checkedIcon});
  background-repeat: no-repeat;
`;

const toggleArrayValue = (array, value) => {
  const arrayCopy = [...array];
  const index = arrayCopy.findIndex((checkValue) => {
    return checkValue === value;
  });

  index === -1 ? arrayCopy.push(value) : arrayCopy.splice(index, 1);

  return arrayCopy;
};

const hasDealer = (dealers, targetDealer) =>
  dealers.some((dealer) => dealer.hmsId === targetDealer.hmsId);

const sortSelectedDealersFirst = (dealers, user) => {
  const dealersCopy = [...dealers];
  const selectedDealers = dealersCopy.filter((dealer) => hasDealer(user.dealerships, dealer));
  const otherDealers = dealersCopy.filter((dealer) => !hasDealer(user.dealerships, dealer));
  return selectedDealers.concat(otherDealers);
};

const UserDealersBoard = ({ user, mutateUser }) => {
  const [userInput, setUserInput] = useState('');
  const [sortedDealers, setSortedDealers] = useState([]);
  const [isDealersSorted, setIsDealersSorted] = useState(false);

  const { dealers } = useDealers(userInput);

  useEffect(() => {
    if (user && dealers && !sortedDealers.length && !isDealersSorted) {
      setSortedDealers(sortSelectedDealersFirst(dealers.items, user));
      setIsDealersSorted(true);
    }
  }, [sortedDealers.length, user, dealers, isDealersSorted]);

  const searchDealers =
    userInput && sortedDealers.filter((dealer) => dealer.name.toLowerCase().includes(userInput));

  const dealersToShow = searchDealers || sortedDealers;

  const onInputHandler = (event) => {
    setUserInput(event.target.value.toLowerCase());
  };

  const onClearHandler = () => {
    setUserInput('');
  };

  const onChangeDealerHandler = (event) => {
    const dealerId = Number(event.currentTarget.getAttribute('data-id'));
    const userDealersIds = user.dealerships.map((dealer) => dealer.hmsId);
    const dealershipIds = toggleArrayValue(userDealersIds, dealerId);

    doFetch(`/users/${user.uuid}`, {
      method: 'PUT',
      body: JSON.stringify({ uuid: user.uuid, dealershipIds })
    }).then((user) => {
      mutateUser(user, false);
    });
  };

  return (
    <ModalContentBoardStyled css={{ marginLeft: 0, marginTop: 0, marginBottom: 0, flex: 1 }}>
      <SearchHeader
        userInput={userInput}
        onInputHandler={onInputHandler}
        placeholder='Search location'
        onClearHandler={onClearHandler}
      />
      <Scrollable maxHeight={`calc(100vh - 97px - 25px - 192px - 25px - 50px - 30px)`}>
        {dealersToShow.length ? (
          dealersToShow.map((dealer, index) => {
            const isChecked =
              user.dealerships &&
              user.dealerships.some((userDealer) => userDealer.hmsId === dealer.hmsId);

            return (
              <StyledModalListItem
                key={dealer.name + index}
                name={dealer.name}
                css={{ cursor: 'pointer' }}
                onClick={onChangeDealerHandler}
                itemId={dealer.hmsId}
              >
                {isChecked && <CheckedStyled />}
              </StyledModalListItem>
            );
          })
        ) : (
          <NoItemsInList>No dealers found</NoItemsInList>
        )}
      </Scrollable>
    </ModalContentBoardStyled>
  );
};

export default UserDealersBoard;
