import React from 'react';
import { SWRConfig } from "swr";

import doFetch from "./api/doFetch";


const SwrProvider = ({ children }) => (
    <SWRConfig
        value={{
            fetcher: doFetch,
        }}
    >
        { children }
    </SWRConfig>
);

export default SwrProvider;
