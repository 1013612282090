import React from 'react';
import styled from 'styled-components';

import ContentBoardStyled from '../../../../components/ContentBoardStyled';
import StyledBoardHeader from '../../../../components/StyledBoardHeader';
import VehicleInfoBox from './VehicleInfoBox';

const InfoBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 340px;
  padding: 12px 20px 4px;
`;

const LABELS = {
  make: 'Make',
  model: 'Model',
  year: 'Year',
  stock: 'Stock',
  vin: 'VIN',
  color: 'Color',
  type: 'Type',
  imageCount: 'Photo Count (HomeNet CSV)',
  uploadedImageCount: 'Photo Count (lotflo.net app)',
  daysInStock: 'Days in stock',
  lastImageUploadedOn: 'Photo updated',
  imageType: 'Source location'
};

const VehicleInfoBoard = ({ vehicle }) => {
  return (
    <ContentBoardStyled css={{ minHeight: '130px', marginLeft: 0, marginTop: 0, width: '482px' }}>
      <StyledBoardHeader>VEHICLE INFORMATION</StyledBoardHeader>
      <InfoBlock>
        {vehicle &&
          Object.keys(LABELS).map((key) => (
            <VehicleInfoBox label={LABELS[key]} labelKey={key} data={vehicle[key]} key={key} />
          ))}
      </InfoBlock>
    </ContentBoardStyled>
  );
};

export default VehicleInfoBoard;
