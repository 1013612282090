import moment from 'moment';

const preapareQueries = (filterState, download) => {
    const datesQuery = `start=${moment(filterState.dates.start).format('YYYY-MM-DD')}&end=${moment(
        filterState.dates.end
    ).format('YYYY-MM-DD')}`;

    const sxmDatesQuery = `start=${moment(filterState.sxmDates.start).format('YYYY-MM-DD')}&end=${moment(
        filterState.sxmDates.end
    ).format('YYYY-MM-DD')}`;
    const conditionQuery = filterState.condition?.name !== 'All' ? `&condition=${filterState.condition?.name}` : '';
    const notesQuery = filterState.data.notes.length
        ? filterState.data.notes.map((note) => `&notes=${note.value}`).join('')
        : '';
    const usersQuery = filterState.data.users.length
        ? filterState.data.users.map((user) => `&uuids=${user.uuid}`).join('')
        : '';
    const locationsQuery = filterState.data.locations.length
        ? filterState.data.locations.map((location) => `&hmsIds=${location.hmsId}`).join('')
        : '';
    const resultUrl = filterState.siriusxm
        ? `/siriusxm-reports/list${download ? '/download' : ''}?${sxmDatesQuery}`
        : `/reports/list${
              download ? '/download' : ''
          }?${datesQuery}${conditionQuery}${usersQuery}${locationsQuery}${notesQuery}`;

    return resultUrl;
};

export { preapareQueries };
