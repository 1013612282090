import useSWR from 'swr';

import app from "../base";


const useDealer = (dealerId) => {
    const { data, error, mutate } = useSWR([`/dealerships/${dealerId}`, undefined, app.auth().currentUser.uid]);

    return {
        dealer: data,
        isDealersLoading: !error && !data,
        isError: error,
        mutateDealer: mutate,
    }
};

export default useDealer;
