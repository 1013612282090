import { useSWRInfinite } from 'swr';

import app from '../base';
import { preapareQueries } from '../utils/urls';

const useReports = (filterState, limit = 20) => {
  const resultUrl = preapareQueries(filterState, false);

  const { data, error, mutate, size, setSize } = useSWRInfinite(
    (index) => `${resultUrl}&limit=${limit}&page=${index + 1}`,
    undefined,
    app.auth().currentUser.uid
  );

  return {
    reports: data,
    isLoading: !error && !data,
    isLoadingMore: (!error && !data) || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    isError: error,
    reloadReports: mutate,
    size,
    setSize
  };
};

export default useReports;
