import styled from "styled-components";

import searchIcon from "../img/search.svg";


const SearchIconStyled = styled.div`
    background-image: url(${searchIcon});
    width: 17px;
    height: 16px;
`;

export default SearchIconStyled;
