import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import BaseModal from '../../../../components/BaseModal';
import Loader from '../../../../components/Loader';

const ModalHeaderWrapper = styled.div`
  display: flex;
  color: #283890;
`;

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const VehicleAddPhotoModal = ({ isOpen, toggleHandler, uploadImages }) => {
  const [filesUploading, setFilesUploading] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFilesUploading(true);
      uploadImages(acceptedFiles);
    },
    [uploadImages]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop
  });

  useEffect(() => setFilesUploading(false), [toggleHandler]);

  return (
    <BaseModal
      isOpen={isOpen}
      toggleHandler={toggleHandler}
      header={<ModalHeaderWrapper>ADD PHOTO</ModalHeaderWrapper>}
    >
      <Container
        style={{ visibility: filesUploading ? 'hidden' : 'visible' }}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some photos here, or click to select photos.</p>
      </Container>
      {filesUploading && <Loader top={115} isLoading={filesUploading} firstLoad={true} />}
    </BaseModal>
  );
};

export default VehicleAddPhotoModal;
