import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useInfo, useRefresh } from '../context';
import moment from 'moment';

import LoadIcon from '../components/LoadIcons';

import { ControlBarContext } from '../pages/ControlBarContext';

const ControlBarWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 29px;
  padding-right: 32px;
  min-height: 95px;
  border-bottom: 2px solid #fafaff;
  background-color: #fff;
`;

const BreadCrumbStyled = styled(Link)`
  font-family: NunitoSans;
  font-size: 18px;
  font-weight: bold;
  color: #28283a;
  text-decoration: none;
  opacity: 0.5;
`;

const BreadCrumbsActiveStyled = styled.div`
  font-family: NunitoSans;
  font-size: 18px;
  font-weight: bold;
  color: #283890;
`;
const BreadCrumbsActiveStyledSub = styled.div`
  ${(p) => css`
    position: absolute;
    font-size: 11px;
    color: #28283a;
    font-weight: 600;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > a {
      display: flex;
      flex-direction: row;
      align-items: center;
      ${p.disabled && `color: #283890 !important;`}
      cursor: pointer;
    }
  `}
`;

const BreadCrumbsArrow = styled.div`
  border: solid #28283a;
  display: inline-block;
  transform: rotate(-45deg);
  padding: 2.3px;
  border-width: 0px 2px 2px 0;
  margin-top: 3px;
  margin-left: 25px;
  margin-right: 24px;
  opacity: 0.4;
`;

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  position: absolute;
  right: 48px;
  top: 54px;
`;

const Tab = styled(Link)`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 13px;
  color: rgba(40, 40, 58, 0.500765);
  cursor: pointer;
`;

const ActiveTab = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 13px;
  border-bottom: 2px solid #283890;
  color: #283890;
  cursor: none;
`;

const LinkBtn = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RightSide = styled.div``;

const ControlBar = () => {
  const { breadCrumbs, rightSide } = useContext(ControlBarContext);
  const pathname = window.location.pathname.replace(/\//g, '');
  const dealerUpdateInfo = useInfo();
  const refreshFn = useRefresh();

  return (
    <ControlBarWrapperStyled>
      <BreadcrumbsWrapper>
        {breadCrumbs.map(({ url, name, hmsId, lastUpdateDate }, index, array) => {
          const isLast = array.length - 1 === index;

          return isLast ? (
            <BreadCrumbsActiveStyled key={index}>
              {name}
              {Boolean(hmsId) && (
                <BreadCrumbsActiveStyledSub>
                  Last refreshed:{' '}
                  {Boolean(lastUpdateDate)
                    ? moment(lastUpdateDate).format('MM/DD/YYYY h:mma')
                    : '-'}
                  <LinkBtn
                    onClick={() => refreshFn(hmsId)}
                    disabled={
                      dealerUpdateInfo?.status !== 'DONE' &&
                      (dealerUpdateInfo?.hmsIds || []).includes(String(hmsId))
                    }
                    style={{ marginLeft: 8 }}
                  >
                    <LoadIcon
                      disabled={
                        dealerUpdateInfo?.status !== 'DONE' &&
                        (dealerUpdateInfo?.hmsIds || []).includes(String(hmsId))
                      }
                    />
                    {dealerUpdateInfo?.status !== 'DONE' &&
                    (dealerUpdateInfo.hmsIds || []).includes(String(hmsId))
                      ? 'Updating'
                      : 'Refresh'}
                  </LinkBtn>
                </BreadCrumbsActiveStyledSub>
              )}
            </BreadCrumbsActiveStyled>
          ) : (
            <div key={index}>
              <BreadCrumbStyled to={url}>{name}</BreadCrumbStyled>
              <BreadCrumbsArrow />
            </div>
          );
        })}
      </BreadcrumbsWrapper>
      <RightSide>{window.location.pathname === rightSide.url && rightSide.Component}</RightSide>
      {(pathname === 'dealers' || pathname === 'dealers-deleted') && (
        <Tabs>
          {pathname === 'dealers' ? (
            <ActiveTab>Existing</ActiveTab>
          ) : (
            <Tab to='dealers'>Existing</Tab>
          )}
          {pathname === 'dealers-deleted' ? (
            <ActiveTab>Deleted</ActiveTab>
          ) : (
            <Tab to='/dealers-deleted'>Deleted</Tab>
          )}
        </Tabs>
      )}
    </ControlBarWrapperStyled>
  );
};

export default ControlBar;
