import useSWR from 'swr';
import app from '../base';

const useUsers = (condition) => {
  const { data, error, mutate } = useSWR([
    `/users?limit=99999&query=${condition}`,
    undefined,
    app.auth().currentUser.uid,
  ]);

  return {
    users: data,
    isLoadingUsers: !error && !data,
    isError: error,
    mutateUsers: mutate,
  };
};

export default useUsers;
