import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '../../../components/Buttons/Button';
import BaseModal from '../../../components/BaseModal';
import TextArea from '../../../components/TextArea';
import doFetch from '../../../api/doFetch';

const ModalHeaderWrapper = styled.div`
    display: flex;
    color: #283890;
`;

const SubmitAreaStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 32px;
`;

const DealerName = styled.div`
    margin-top: 14px;
    margin-bottom: 6px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    color: #283890;
`;

const EditDetailsModal = ({ isOpen, dealer, toggleHandler, updateDetails }) => {
    const [note, setNote] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        doFetch(`/dealerships/${dealer.hmsId}/note`, { method: 'PUT', body: JSON.stringify({ note }) })
            .then(() => {
                toggleHandler();
                updateDetails(note);
            })
            .catch((error) => alert(error));
    };

    useEffect(() => {
        dealer && setNote(dealer.note || '');
    }, [dealer]);

    return (
        <BaseModal
            isOpen={isOpen}
            toggleHandler={toggleHandler}
            header={<ModalHeaderWrapper>EDIT DEALERSHIP DETAILS</ModalHeaderWrapper>}>
            <DealerName>{dealer && dealer.name}</DealerName>
            <form onSubmit={handleSubmit}>
                <TextArea label="Note" value={note} maxLength={255} onChange={(event) => setNote(event.target.value)} />
                <SubmitAreaStyled>
                    <Button type="submit">Save</Button>
                </SubmitAreaStyled>
            </form>
        </BaseModal>
    );
};
export default EditDetailsModal;
