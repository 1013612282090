import React from "react";
import styled from 'styled-components';

import app from "../base";
import logoutImg from "../img/logout.svg";


const LogoutContainerStyled = styled.div`
    display: flex;
    align-items: center;
    padding-left: 32px;
    height: 55px;
    border-top: 2px solid #edf2f9;
    box-sizing: border-box;
`;

const LogoutButtonStyled = styled.div`
    display: flex;
    cursor: pointer;
`;

const LogoutLabelStyled = styled.span`
    margin-left: 16px;
    opacity: 0.5;
    font-family: NunitoSans;
    font-size: 12px;
    font-weight: bold;
    color: #28283a;
`;

const LogOut = () => {
    return (
        <LogoutContainerStyled>
            <LogoutButtonStyled>
                <img src={logoutImg} alt="logout" />
                <LogoutLabelStyled onClick={() => app.auth().signOut()}>Logout</LogoutLabelStyled>
            </LogoutButtonStyled>
        </LogoutContainerStyled>
    );
};

export default LogOut;
