import styled from "styled-components";

const EmailsStyled = styled.div`
    font-family: NunitoSans;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    color: rgba(40, 40, 58, 0.7);
`;

const NoEmailStyled = styled.div`
    font-family: NunitoSans;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    color: rgba(40, 40, 58, 0.7);
    opacity: 0.5;
`;

export { EmailsStyled, NoEmailStyled };
