import React from "react";
import styled from "styled-components";


const UserDataWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex-basis: 140px;
    margin-bottom: 20px;
`;

const UserInfoLabelStyled = styled.div`
    font-family: NunitoSans;
    font-size: 10px;
    font-weight: bold;
    color: #677b8b;
    margin-bottom: 10px;
`;

const UserInfoDataStyled = styled.div`
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: 600;
    color: #28283a;
`;

const UserInfo = ({ label, data }) => (
    <UserDataWrapper>
        <UserInfoLabelStyled>{ label }</UserInfoLabelStyled>
        <UserInfoDataStyled>{ data }</UserInfoDataStyled>
    </UserDataWrapper>
);

export default UserInfo;
