import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo
} from 'react';
import usePatchUpdateInfo from '../api/usePatchUpdateInfo';
import useUpdateInfo from '../api/useUpdateInfo';

const context = createContext();

export const ProvideDealersInfo = ({ children }) => {
  const updateFn = useUpdateInfo();
  const timer = useRef();
  const [info, setInfo] = useState({
    status: 'DONE',
    date: null,
    hmsIds: []
  });

  useEffect(() => {
    updateFn().then(setInfo);
    timer.current = setInterval(async () => {
      updateFn().then(setInfo);
    }, 30000);

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
    // eslint-disable-next-line
  }, []);

  const contextValue = useMemo(() => ({ info, setInfo }), [info]);

  return (
    <>{contextValue && <context.Provider value={contextValue}>{children}</context.Provider>}</>
  );
};

export const useInfo = () => {
  return useContext(context).info;
};

export const useRefresh = () => {
  const { setInfo } = useContext(context);
  const patchFn = usePatchUpdateInfo();

  return useCallback(async (hmsId = null) => {
    await patchFn(hmsId);
    setInfo((info) => ({
      status: 'PENDING',
      hmsIds: [...info.hmsIds, hmsId ? String(hmsId) : 'global']
    }));
    // eslint-disable-next-line
  }, []);
};
