import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from './Auth';
import { hasAccess } from './utils/users'; 


const PrivateRoute = ({ children: RouteComponent, ...rest }) => {
    const { currentUser } = useContext(AuthContext);

    return (
        <Route {...rest}>
            {
                (currentUser && hasAccess(currentUser.role))
                    ? RouteComponent
                    : <Redirect to={'/login'} />
            }
        </Route>
    );
};


export default PrivateRoute
