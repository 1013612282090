import useSWR from 'swr';

import app from '../base';

const useReportForDealerByUserByDay = (dealerId, userId, date, isBilling) => {
  const billingFilters = isBilling ? `notes=Full Shoot` : '';
  const { data, error, mutate } = useSWR(
    dealerId && userId && date
      ? [
          `/reports/dealerships/${dealerId}/users/${userId}/${date}?${billingFilters}`,
          /* options */ undefined,
          app.auth().currentUser.uid
        ]
      : null
  );

  return {
    report: data,
    isLoadingUser: !error && !data,
    isError: error,
    mutateReport: mutate
  };
};

export default useReportForDealerByUserByDay;
