import React, { createRef, useState } from 'react';

import { CIWrap, CIBox, CIPopover, CIBoxWrap, CalendarWrap } from './styles';
import { formattedDate } from '../../utils/date';

const CalendarInput = ({ selectedDates, setSelectedDates }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const calendarAnchor = createRef();

  const handleOpen = () => setAnchorEl(calendarAnchor.current);
  const handleClose = () => setAnchorEl(null);

  return (
    <CIWrap>
      <span>Date range</span>
      <CIBoxWrap ref={calendarAnchor}>
        <CIBox onClick={handleOpen}>{formattedDate(selectedDates?.start)}</CIBox> 
        <CIBox onClick={handleOpen}>{formattedDate(selectedDates?.end)}</CIBox>
      </CIBoxWrap>
      <CIPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <CalendarWrap value={selectedDates} singleDateRange={true} onSelect={setSelectedDates} />
      </CIPopover>
    </CIWrap>
  );
};

export default CalendarInput;
