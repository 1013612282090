import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import ContentBoardStyled from "../components/ContentBoardStyled";
import Scrollable from "../components/Scrollable";
import { ControlBarContext } from "./ControlBarContext";

const IFRAME_URL = [
    'https://analytics.lotflo.com/public/question/3f2a2019-a7dc-4487-84de-80309448d056',
    'https://analytics.lotflo.com/public/question/1be3332d-3989-40d1-8198-43bfaf7e5b5a',
    'https://analytics.lotflo.com/public/question/245806b0-9cca-4c5f-92f2-4188b19b0656',
    'https://analytics.lotflo.com/public/question/1926b2a8-c118-4503-8736-f9adbc9d516a',
    'https://analytics.lotflo.com/public/question/3973bff3-d29c-48c8-9534-9a24ce7133d2',
];

const IframeStyled = styled.iframe`
    margin-right: 20px;
    border: 0;
    width: calc(100% - 20px);
    height: 600px;
    allowTransparency
`;

const AnalyticsPage = ({ getBreadCrumbs }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { setBreadCrumbs } = useContext(ControlBarContext);
    
    const setIsLoaded = () => setIsLoading(false);

    useEffect(() => {
        setBreadCrumbs(getBreadCrumbs());
    }, [setBreadCrumbs, getBreadCrumbs]);
    
    return (
        <ContentBoardStyled css={{ padding: '0 1px 0 20px' }}>
            <Scrollable maxHeight={`calc(100vh - 170px)`}>
                {
                    isLoading && <div>Loading...</div>
                }
                {
                    IFRAME_URL.map(iframeUrl => (
                        <IframeStyled src={iframeUrl} allowTransparency key={iframeUrl} onLoad={setIsLoaded} />
                    ))
                }
            </Scrollable>
        </ContentBoardStyled>
    );
}

export default AnalyticsPage;
