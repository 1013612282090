import React, { useState } from 'react';
import styled from 'styled-components';

import ContentBoardStyled from '../../../components/ContentBoardStyled';
import StyledBoardHeader from '../../../components/StyledBoardHeader';
import EditDetailsModal from './EditDetailsModal';

const Block = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 340px;
    padding: 20px;
    font-size: 14px;
    color: #28283a;
    overflow: hidden;
    word-break: break-all;
`;

const DealerDetailsBoard = ({ dealer, updateDealer }) => {
    const [isOpenModal, setIsOpenEditUserModal] = useState(false);
    const toggleModal = () => setIsOpenEditUserModal(!isOpenModal);

    return (
        <ContentBoardStyled css={{ marginLeft: 0, marginTop: 0 }}>
            <StyledBoardHeader button={{ name: 'Edit', handler: toggleModal }}>DEALERSHIP DETAILS</StyledBoardHeader>
            <Block>
                {dealer?.note || '—'}
                <EditDetailsModal
                    dealer={dealer}
                    isOpen={isOpenModal}
                    toggleHandler={toggleModal}
                    updateDetails={updateDealer}
                />
            </Block>
        </ContentBoardStyled>
    );
};

export default DealerDetailsBoard;
