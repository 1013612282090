import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import ReportsBoard from './components/ReportsBoard';
import UserInfoBoard from './components/UserInfoBoard';
import UserDealersBoard from './components/UserDealersBoard';
import { ControlBarContext } from '../ControlBarContext';
import { getIsManager } from '../../utils/users';
import useUser from '../../api/useUser';

const UserProfileWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 25px;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 482px;
  margin-left: 25px;
`;

const UserProfile = ({ getBreadCrumbs }) => {
  const { setBreadCrumbs } = useContext(ControlBarContext);
  let { userId } = useParams();

  const { user, isLoadingUser, mutateUser } = useUser(userId);

  useEffect(() => {
    user && user.displayName && setBreadCrumbs(getBreadCrumbs({ userName: user.displayName }));
  }, [setBreadCrumbs, user, getBreadCrumbs]);

  return (
    !isLoadingUser && (
      <UserProfileWrapper>
        {user && user.role && getIsManager(user.role) ? (
          <UserInfoBoard userId={userId} />
        ) : (
          <>
            <ReportsBoard userId={userId} />
            <RightSide style={{ height: '100%' }}>
              <UserInfoBoard userId={userId} />
              <UserDealersBoard user={user} mutateUser={mutateUser} />
            </RightSide>
          </>
        )}
      </UserProfileWrapper>
    )
  );
};

export default UserProfile;
