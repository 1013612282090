import styled from 'styled-components';

const NoItemsInList = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30vh;
    font-family: NunitoSans;
    font-weight: 600;
    font-size: 14px;
    color: #28283a;
`;

export default NoItemsInList;
