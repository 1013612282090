const initialState = {
  search: '',
  sortKey: 'Name',
  sortDirection: 'ASC'
};

const filterStore = {
  initialState
};

export default filterStore;
