import React, { useState } from 'react';
import styled from 'styled-components';

import COLORS from '../COLORS';
import { List, SIMenuItem, SIMenuWrap } from './Inputs/styles';

const DMWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  font-size: 12px;
  font-weight: bold;
  color: ${COLORS.label};
  width: 100%;
`;

const DMBox = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  border-radius: 17.5px;
  padding: 8px 16px;
  margin: auto 0;
  width: fit-content;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    background-color: rgba(39, 55, 144, 0.09);
    color: #283890;
    }`}
`;

const DropdownMenu = ({ single, label, data, array, keys, selectedItems, setSelectedItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => setAnchorEl(event?.currentTarget || null);
  const handleItemClick = (item, isArray) => {
    selectedItems?.some((si) => (isArray ? si === item : si[keys.id] === item[keys.id]))
      ? setSelectedItems(
          selectedItems?.filter((selectedItem) =>
            isArray ? selectedItem !== item : selectedItem[keys.id] !== item[keys.id]
          )
        )
      : setSelectedItems([item].concat(selectedItems));
  };

  const handleSignleItemClick = (item) => {
    setSelectedItems(item);
    handleMenu(null);
  };

  return (
    <DMWrap>
      <DMBox
        onClick={Boolean(data || array?.length) ? handleMenu : undefined}
        notEmpty={Boolean(data || array?.length)}
        count={selectedItems?.length}
        disabled={Boolean(anchorEl)}
      >
        {array?.length ? selectedItems : selectedItems[keys?.name]}
      </DMBox>
      <SIMenuWrap
        single={single}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenu(null)}
        className="fix-position"
      >
        <List single={single}>
          {data?.items.map((item, index) => (
            <SIMenuItem
              key={index}
              selected={
                single
                  ? selectedItems === item
                  : selectedItems?.some((selectedItem) => selectedItem[keys.id] === item[keys.id])
              }
              onClick={() =>
                single ? handleSignleItemClick(item, false) : handleItemClick(item, false)
              }
            >
              {item[keys.name]}
            </SIMenuItem>
          ))}
          {array?.map((item, index) => (
            <SIMenuItem
              key={index}
              selected={
                single
                  ? selectedItems === item
                  : selectedItems?.some((selectedItem) => selectedItem === item)
              }
              onClick={() =>
                single ? handleSignleItemClick(item, true) : handleItemClick(item, true)
              }
            >
              {item}
            </SIMenuItem>
          ))}
        </List>
      </SIMenuWrap>
    </DMWrap>
  );
};

export default DropdownMenu;
