import React from 'react';
import styled from 'styled-components';

import BaseModal from './BaseModal';
import Button from './Buttons/Button';

const ModalHeaderWrapper = styled.div`
  display: flex;
  color: #283890;
`;

const SubmitAreaStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 43px;
`;

const ConfirmText = styled.div`
  padding: 24px 0 0;
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.13px;
  color: #28283a;
`;


const SaveButton = styled(Button)``;

const ErrorModal = ({ isOpen, errorMessage, toggleHandler }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    toggleHandler();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      toggleHandler={toggleHandler}
      header={<ModalHeaderWrapper>ERROR</ModalHeaderWrapper>}
    >
      <ConfirmText>{errorMessage}</ConfirmText>
      <form onSubmit={handleSubmit}>
        <SubmitAreaStyled>
          <SaveButton type='submit'>Continue</SaveButton>
        </SubmitAreaStyled>
      </form>
    </BaseModal>
  );
};

export default ErrorModal;
