import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import filterStore, {
  ConditionFilterData,
  CountSetData
} from '../../services/VehiclesFilterService';
import useVehilcleMakes from '../../../../api/useVehilcleMakes';
import ContentBoardStyled from '../../../../components/ContentBoardStyled';
import StyledBoardHeader from '../../../../components/StyledBoardHeader';
import CountInput from '../../../../components/Inputs/CountInput';
import YearInput from '../../../../components/Inputs/YearInput';
import SelectInput from '../../../../components/Inputs/SelectInput';
import { formattedDateTime } from '../../../../utils/date';
import doFetch from '../../../../api/doFetch';
import COLORS from '../../../../COLORS';

const FiltersWrap = styled.div`
  padding: 0 20px 24px;
`;

const Label = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-size: 12px;
  color: ${COLORS.label};
  font-weight: bold;
  width: 100%;

  div {
    font-size: 14px;
    margin-top: 10px;
    font-weight: normal;
    color: #28283a;
  }
`;

const loadModels = (make) => {
  return doFetch(`/vehicles/makes/${make}/models`, {
    method: 'GET'
  });
};

const VehiclesFilterBoard = ({ lastUpdateTime }) => {
  const { makes } = useVehilcleMakes();

  const [preparedModels, setPreparedModels] = useState([]);
  const [filterState, setFilterState] = useState(filterStore.initialState);

  const [selectedCondition, setSelectedCondition] = useState(filterState.condition);
  const [selectedMakes, setSelectedMakes] = useState(filterState.makes);
  const [selectedModels, setSelectedModels] = useState(filterState.models);
  const [selectedYears, setSelectedYears] = useState(filterState.years);
  const [selectedPhotoCount, setSelectedPhotoCount] = useState(filterState.photoCount);

  const resetFilters = () => {
    filterStore.resetFilters();
    setPreparedModels([]);
  };

  const updateFiltersHandler = useCallback(
    () =>
      filterStore.sendMessage({
        makes: selectedMakes,
        models: selectedModels,
        condition: selectedCondition,
        years: selectedYears,
        photoCount: selectedPhotoCount
      }),
    [selectedCondition, selectedMakes, selectedModels, selectedYears, selectedPhotoCount]
  );

  const prepareModels = useCallback(
    (makes) =>
      makes.length
        ? Promise.all(makes.map((make) => loadModels(make))).then((m) => {
            const arr = m.flat();
            setPreparedModels(arr);
            setSelectedModels(makes.filter((sm) => arr.includes(sm)));
          })
        : setPreparedModels([]),
    [setPreparedModels, setSelectedModels]
  );

  useLayoutEffect(() => {
    const subs = filterStore.subscribe(setFilterState);
    filterStore.init();
    return () => subs.unsubscribe();
  }, []);

  useEffect(updateFiltersHandler, [
    setSelectedCondition,
    setSelectedMakes,
    setSelectedModels,
    setSelectedYears,
    setSelectedPhotoCount,
    updateFiltersHandler
  ]);

  useEffect(() => {
    setSelectedCondition(filterState.condition);
    setSelectedMakes(filterState.makes);
    setSelectedModels(filterState.models);
    setSelectedYears(filterState.years);
    setSelectedPhotoCount(filterState.photoCount);
  }, [filterState]);

  useEffect(() => {
    prepareModels(selectedMakes);
  }, [selectedMakes, prepareModels]);

  return (
    <ContentBoardStyled css={{ marginLeft: 0, marginTop: 0 }}>
      <StyledBoardHeader button={{ name: 'Reset', handler: resetFilters }}>
        FILTER
      </StyledBoardHeader>
      <FiltersWrap>
        <SelectInput
          single={1}
          label={'Condition'}
          data={ConditionFilterData}
          keys={{ id: 'id', name: 'name', type: 'conditions' }}
          selectedItems={filterState.condition}
          setSelectedItems={setSelectedCondition}
        />
        <SelectInput
          label={'Make'}
          array={makes}
          selectedItems={filterState.makes}
          setSelectedItems={setSelectedMakes}
        />
        <SelectInput
          label={'Model'}
          array={preparedModels}
          selectedItems={filterState.models}
          setSelectedItems={setSelectedModels}
        />
        <YearInput selectedYears={filterState.years} setSelectedYears={setSelectedYears} />
        <CountInput
          label={'Photo Count (HomeNet CSV)'}
          selectedCount={filterState.photoCount}
          setSelectedCount={setSelectedPhotoCount}
          dataSet={CountSetData}
        />
        <Label>
          <span>Photo updated time</span>
          <div>{lastUpdateTime ? formattedDateTime(lastUpdateTime) : '–'}</div>
        </Label>
      </FiltersWrap>
    </ContentBoardStyled>
  );
};

export default VehiclesFilterBoard;
