import React, { useState } from 'react';
import {
  SIWrap,
  SIBox,
  Search,
  SearchIcon,
  SearchInput,
  List,
  SIMenuItem,
  SIMenuWrap,
  SearchClearButton
} from './styles';

const getShortPlaceholder = (isArray, selected, keys, label) =>
  selected?.length
    ? selected.map((item) => (isArray ? item : item[keys?.name])).join(', ')
    : `Filter by ${label}`;

const SelectInput = ({
  single,
  label,
  data,
  array,
  keys,
  selectedItems,
  setSelectedItems,
  sort = true,
  disabled
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchInput, setSearchInput] = useState('');

  const onInputHandler = (event) => setSearchInput(event.target.value);
  const onClearHandler = () => setSearchInput('');

  const handleMenu = (event) => setAnchorEl(event?.currentTarget || null);
  const handleItemClick = (item, isArray) => {
    selectedItems?.some((si) => (isArray ? si === item : si[keys.id] === item[keys.id]))
      ? setSelectedItems(
          selectedItems?.filter((selectedItem) =>
            isArray ? selectedItem !== item : selectedItem[keys.id] !== item[keys.id]
          )
        )
      : setSelectedItems([item].concat(selectedItems));
  };

  const handleSignleItemClick = (item) => {
    setSelectedItems(item);
    handleMenu(null);
  };

  return (
    <SIWrap>
      <span>{label}</span>
      {Boolean(disabled) ? (
        <SIBox notEmpty={false} count={0}>
          Filter by {label}
        </SIBox>
      ) : (
        <SIBox
          onClick={Boolean(data || array?.length) ? handleMenu : undefined}
          notEmpty={Boolean(data || array?.length)}
          count={Array.isArray(selectedItems) ? selectedItems?.length : 0}
        >
          {single
            ? array?.length
              ? (Array.isArray(selectedItems) && selectedItems.length) || selectedItems
                ? selectedItems
                : `Filter by ${label}`
              : selectedItems[keys?.name]
            : getShortPlaceholder(array?.length, selectedItems, keys, label)}
        </SIBox>
      )}
      <SIMenuWrap
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenu(null)}
      >
        {single ? null : (
          <Search>
            <SearchIcon />
            <SearchInput
              value={searchInput}
              onChange={onInputHandler}
              placeholder={`Filter by ${label}`}
            />
            {searchInput && <SearchClearButton onClick={onClearHandler} />}
          </Search>
        )}
        <List single={single}>
          {data?.items
            ?.filter((item) =>
              searchInput.length
                ? item[keys.name].toLowerCase().includes(searchInput.toLowerCase())
                : item
            )
            .sort((a, b) => {
              if (!sort) return -1;
              return a[keys.name] < b[keys.name] ? -1 : 1;
            })
            .map((item, index) => (
              <SIMenuItem
                key={index}
                selected={
                  single
                    ? selectedItems === item
                    : selectedItems?.some((selectedItem) => selectedItem[keys.id] === item[keys.id])
                }
                onClick={() =>
                  single ? handleSignleItemClick(item, false) : handleItemClick(item, false)
                }
              >
                {item[keys.name]}
              </SIMenuItem>
            ))}
          {array
            ?.filter((item) =>
              searchInput.length ? item.toLowerCase().includes(searchInput.toLowerCase()) : item
            )
            .sort((a, b) => {
              if (!sort) return -1;
              return a < b ? -1 : 1;
            })
            .map((item, index) => (
              <SIMenuItem
                key={index}
                selected={
                  single
                    ? selectedItems === item
                    : selectedItems?.some((selectedItem) => selectedItem === item)
                }
                onClick={() =>
                  single ? handleSignleItemClick(item, true) : handleItemClick(item, true)
                }
              >
                {item}
              </SIMenuItem>
            ))}
        </List>
      </SIMenuWrap>
    </SIWrap>
  );
};

export default SelectInput;
